import React, { FC, memo } from 'react';
import { UserProfileFooter } from '../../UserProfileFooter';
import { UserProfileMenuLinks } from '../../UserProfileMenuLinks';
import { SubAccountSelect } from '../../../SubAccountSelect';
import { handleWrapperClick } from '../../../../utils';
import { UserProfileInformation } from '../../UserProfileInformation/UserProfileInformation';

type Props = {
  isClosing: boolean;
  toggleUserMenu: () => void;
}

export const UserMenu:FC<Props> = memo(function UserMenu({ toggleUserMenu, isClosing }) {
  return (
    <div
      className={`UserMenuComponent__Wrapper ${isClosing ? 'Closing' : ''}`}
      onClick={(e: any) => handleWrapperClick(e, 'UserMenuComponent__Wrapper', toggleUserMenu)}
    >
      <div className="UserMenuComponent">
         <UserProfileInformation />
         <UserProfileMenuLinks />
         <SubAccountSelect />
         <UserProfileFooter />
      </div>
    </div>
  );
});
