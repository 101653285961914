export const AUTH_COOKIE_NAME = '4DSID_ATF';

export const REFRESH_INTERVAL = 60000; // 1 minute

// Errors
export const NOT_FOUND_ORDER = 'NOT_FOUND_ORDER';

// Placeholders
export const UNKNOWN = 'Non communiqué';
export const EMPTY_DATE = '00/00/0000';

// Device actions email
export const DEVICE_ACTION_EMAIL_ADDRESS = 'achatsgc@atf.fr';
export const DEVICE_ACTION_RETOUR_EMAIL_SUBJECT_BASE = 'My ATF : Retour matériel';
export const DEVICE_ACTION_CONTACT_EMAIL_SUBJECT_BASE = 'My ATF : Contactez-moi';


// Diagrams
export const DIAGRAM_ITEM_COLORS = [
  '#6ab04c',
  '#badc58',
  '#457308',
  '#8fcb3f',
  '#64a70b',
  '#b0f25a',
  '#f6e58d',
  '#fad390',
  '#079992',
];

export const LEGAL_LINKS_FILES = {
  mentions_legales: 'mentions_legales',
  politique_confidentialite: 'politique_confidentialite'

};