import React, { FC, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronIcon } from '../../../icons';
import { selectUserMenuState, setUserMenuActive, toggleNotifications } from '../../../store/modules/app';
import { setSearchActive } from '../../../store/modules/orders';
import { selectUser } from '../../../store/modules/user/user.selectors';
import { addBodyScroll, hideBodyScroll, getInitials } from '../../../utils';
import { UserMenu } from './UserMenu';

export const UserDropdown: FC = memo(function UserDropdown() {
  const [isClosing, setIsClosing] = useState(false);
  const { firstname = ' ', lastName = ' ' } = useSelector(selectUser);
  const dispatch = useDispatch();
  const isOpen = useSelector(selectUserMenuState);

  const closeMenu = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(setUserMenuActive(false));
      setIsClosing(false);
    }, 400);
  };

  const toggleUserMenu = () => {
    if (!isOpen) {
      dispatch(setSearchActive(false));
      dispatch(toggleNotifications(false));
      hideBodyScroll();

      return dispatch(setUserMenuActive(true));
    }

    addBodyScroll();
    closeMenu();
  };

  return (
    <div className="UserDropdownComponent">
      <button className="UserDropdownComponent__Button" onClick={toggleUserMenu}>
        <span className="UserDropdownComponent__Initials">{getInitials(firstname, lastName)}</span>
        <span className="UserDropdownComponent__Chevron">
          <ChevronIcon />
        </span>
      </button>
      {isOpen && <UserMenu toggleUserMenu={toggleUserMenu} isClosing={isClosing} />}
    </div>
  );
});
