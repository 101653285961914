import React, { FC, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Loader } from '../..';
import { ChevronIcon, SearchIcon } from '../../../icons';
import { PATHS } from '../../../router';
import { searchOrder } from '../../../services';
import { setUserMenuActive } from '../../../store/modules/app';
import { selectOrderSearch, setOrderSearchType, setOrderSearchValue, setSearchActive } from '../../../store/modules/orders';
import { selectUserAccounts } from '../../../store/modules/user';
import { SEARCH_TYPES } from '../../../types';
import { OrderSearchItem } from '../../../types/dto';

const dropdownElements: Record<SEARCH_TYPES, string> = {
  [SEARCH_TYPES.SERIE_NUMBER]: 'N° de série',
  [SEARCH_TYPES.COMMAND_NUMBER]: 'N° de commande',
  [SEARCH_TYPES.TAG]: 'N° de Tag',
};

type Props = {
  className?: string;
}

export const HeaderSearch:FC<Props> = memo(function HeaderSearch({ className = '' }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [foundResults, setFoundResults] = useState<OrderSearchItem[]>([]);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const accounts = useSelector(selectUserAccounts);
  const { type: searchType, value: searchValue, isShown: isSearchShown } = useSelector(selectOrderSearch);
  const isSmallInput = searchType !== SEARCH_TYPES.SERIE_NUMBER;

  const searchByType = async () => {
    setLoading(true);
    dispatch(setUserMenuActive(false));

    if(isOpen) {
      setIsOpen(false);
    }

    const foundResult = await searchOrder({
      category: searchType,
      searchString: searchValue,
      page: 1,
      perPage: 10,
      accounts,
    });

    setLoading(false);

    if(!isSearchShown) {
      dispatch(setSearchActive(true));
    }

    if(!foundResult) {
      return setFoundResults([]);
    }

    const { data: { list } } = foundResult;

    setFoundResults(list);
  };

  useEffect(() => {
    if(searchValue.length < 3) {
     dispatch(setSearchActive(false));

     return;
    }

    searchByType();
  }, [searchValue, searchType]);

  const onChange = (searchString: string) => {
    dispatch(setOrderSearchValue(searchString));
  };

  const onOptionClick = (orderId: number) => {
    let pathToNavigate = `${PATHS.COMMANDS}/${orderId}`;

    if(searchType !== SEARCH_TYPES.COMMAND_NUMBER) {
      pathToNavigate = `${PATHS.COMMANDS}/${orderId}${PATHS.AUDIT}?search=${searchValue}`;
    }

    onChange('');
    push(pathToNavigate);
  };

  const onOpen = () => {
    setIsOpen(!isOpen);
    dispatch(setSearchActive(false));
  };

  return (
    <div className={`HeaderSearchComponent ${className}`}>
      <div className="HeaderSearchComponent__Dropdown__Wrapper">
        <div className="HeaderSearchComponent__Dropdown">
          <button className="Dropdown__Option-Active" onClick={() => onOpen()}>
            {dropdownElements[searchType]}
            <span className={`Option__Icon ${isOpen && 'Open'}`}><ChevronIcon /></span>
          </button>

          {isOpen && <div className="Dropdown__Options">
            {Object.keys(dropdownElements).map((option: string) => {
              const isActive = option === searchType;

              return (
                <button
                  key={option}
                  className={`Dropdown__Option ${ isActive ? 'Active' : '' }`}
                  onClick={() => {
                    dispatch(setOrderSearchType(option as SEARCH_TYPES));
                    setIsOpen(false);
                  }}
                >
                  {dropdownElements[option as SEARCH_TYPES]}
                </button>
              );
            })}
          </div>}
          <span className="HeaderSearchComponent__Icon-Search"> <SearchIcon /> </span>
          <input
            id="headerSearch"
            type="text"
            className={`HeaderSearchComponent__Input ${isSmallInput ? 'small' : ''}`}
            placeholder="Entrez votre recherche"
            value={searchValue}
            onChange={(e: React.FormEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
          />
        </div>
        {isSearchShown && (
          <div className="Results">
            {loading && <Loader className="HeaderSearchComponent__Loader"/>}
            {!loading && !foundResults.length && (
              <span className="HeaderSearchComponent__NoResults">
                Nous n&lsquo;avons pas trouvé de résultats pour cette recherche
              </span>
            )}
            {foundResults.map(({ id }) => (
              <button
                className="Results__Item"
                key={id}
                onClick={() => onOptionClick(id)}
              >
                {id}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});
