import React, { FC, forwardRef, MouseEventHandler, memo } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

type CustomButtonProps = {
  value: Date;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

type Props = {
  value: Date | string;
  onChange: (newVal: Date) => void;
  label?: string;
  className?: string;
  range?: {
    startDate: Date | string;
    endDate: Date | string;
  };
  minDate? : Date | string;
  maxDate? : Date | string;
}

const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
];

export const DateInput:FC<Props> = memo(function DateInput ({ value, onChange, label = '', className = '', range, minDate, maxDate }) {
  // eslint-disable-next-line react/display-name
  const ExampleCustomInput = forwardRef(({ value: newValue, onClick } : CustomButtonProps, ref: any) => (
    <>
      <span className="DateInputComponent__Label">{label}</span>
      <button
        className="DateInputComponent__Button"
        onClick={onClick}
        ref={ref}
      >
        {newValue}
      </button>
    </>
  ));

  const optionalProps = { ...range, minDate, maxDate } as unknown as Partial<Readonly<ReactDatePickerProps>>;

  const objectValue = value ? new Date(value) : new Date();
  const dateValue = typeof value === 'object'
    ? value
    : objectValue;

  const CustomHeader = ({ date, decreaseMonth, increaseMonth }: Record<string, any>) => {
    return (
      <div>
        <button
          className="react-datepicker__navigation react-datepicker__navigation--previous"
          onClick={decreaseMonth}
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous Month</span>
        </button>
        <span className="react-datepicker__current-month">{`${MONTHS[date.getMonth()]} ${date.getFullYear()}`}</span>
        <button
          onClick={increaseMonth}
          className="react-datepicker__navigation react-datepicker__navigation--next"
        >
            <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next" > Next month </span>
        </button>
      </div>
    );
  };

  return (
    <div className={`DateInputComponent ${className}`}>
      <DatePicker
        dateFormat='dd/MM/yyyy'
        selected={dateValue}
        onChange={(date) => onChange(date as Date)}
        customInput={<ExampleCustomInput value={dateValue} onClick={console.log} />}
        className="DateInputComponent__Button"
        renderCustomHeader={CustomHeader}
        {...optionalProps}
      />
    </div>
  );
});
