import React, { FC, useState, memo, useEffect, useCallback } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedAccounts, selectAccounts, selectUserAccounts } from '../../store/modules/user';
import { AccountWithGroup } from '../../utils';
import { setStorageSelectedAccounts } from '../../services';

type Props = {
  accounts?: AccountWithGroup[],
}

const style = {
  multiselectContainer : {
    maxWidth: 'calc(100% - 30px)',
    backgroundColor: '#fff',
    position: 'relative',
  },
  searchWrapper: {
    maxHeight: '100px',
    overflowY: 'auto',
    paddingTop: '12px',
  },
};

const GROUP_CLASS = '_3N0WR2P3JAHZIXKMgKZeiH';
const COMPONENT_CLASS = '.multiselect-container';

export const SubAccountSelect: FC<Props> = memo(function SubAccountSelect(props) {
  const accounts = props.accounts || useSelector(selectAccounts);
  const selectedAccounts = useSelector(selectUserAccounts);
  const dispatch = useDispatch();
  const [selectedAccountsList, setSelectedAccountsList] = useState(
    (accounts?.filter(({ id }) => selectedAccounts.includes(id as unknown as number))),
    );

  const changeSelectedUserAccounts = (newSelectedUserAccounts: AccountWithGroup[]) => {
    const newSelectedAccounts = newSelectedUserAccounts.map(({ id }) => id) as unknown as number[];
  
    setSelectedAccountsList(newSelectedUserAccounts);
    setStorageSelectedAccounts(newSelectedAccounts);
    dispatch(changeSelectedAccounts(newSelectedAccounts));
  };

  const onMultiselectClick = useCallback((e: any) => {
    if(!e.target.classList.contains(GROUP_CLASS)) {
      return;
    }

    const selectedGroupe = e.target.innerText;
    const selectedAccountsGroupe = (accounts as AccountWithGroup[]).reduce((accum: AccountWithGroup[], account) => {
      if(account.groupe !== selectedGroupe) {
        return accum;
      }

      return [...accum, account];
    }, []);

    changeSelectedUserAccounts(selectedAccountsGroupe);
  }, [selectedAccounts, accounts]);

  useEffect(() => {
    const multiselectComponent = document.querySelector(COMPONENT_CLASS) as Element;
    if(!multiselectComponent) {
      return;
    }

    multiselectComponent.addEventListener('click', onMultiselectClick);

    return () => {
      multiselectComponent.removeEventListener('click', onMultiselectClick);
    };
  }, []);

  if ((accounts?.length || 0) <= 1) {
    return <></>;
  }

  return (
    <div className="SubAccountSelectComponent">
      <span className="SubAccountSelectComponent__Label">Comptes</span>
      <Multiselect
        options={accounts || []}
        selectedValues={selectedAccountsList}
        onSelect={changeSelectedUserAccounts}
        onRemove={changeSelectedUserAccounts}
        disablePreSelectedValues = {(selectedAccountsList || []).length <= 1}
        groupBy="groupe"
        displayValue="name"
        closeIcon="cancel"
        emptyRecordMsg="Aucune option disponible"
        style={style}
      />
    </div>
  );
});
