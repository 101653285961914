import { Action } from 'redux';
import { ActionPayload, FilteredOrdersPaginationPayload, GetOrderDevicesPagePayload, GetOrderListPayload, OrdersStore, OrderWithAudit, SEARCH_TYPES, SORT_ORDER } from '../../../types';
import { OrderAuditStatsSuccessAction, OrderStatus, SuccessOrderAuditResponseDto } from '../../../types/dto';

export const GET_ORDERS = 'GET_ORDERS';
export const HANDLE_GET_ORDERS = 'HANDLE_GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';
export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_AUDIT = 'GET_ORDER_AUDIT';
export const GET_ORDER_AUDIT_STATS = 'GET_ORDER_AUDIT_STATS';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_AUDIT_STATS_SUCCESS = 'GET_ORDER_AUDIT_STATS_SUCCESS';
export const GET_ORDER_AUDIT_DEVICES_PAGE = 'GET_ORDER_AUDIT_DEVICES_PAGE';
export const GET_ORDER_AUDIT_DEVICES_PAGE_SUCCESS = 'GET_ORDER_AUDIT_DEVICES_PAGE_SUCCESS';
export const SET_ORDER_LIST_SORTBY = 'SET_ORDER_LIST_SORTBY';
export const SET_ORDER_SEARCH_TYPE = 'SET_ORDER_SEARCH_TYPE';
export const SET_ORDER_SEARCH_VALUE = 'SET_ORDER_SEARCH_VALUE';
export const SET_ORDER_SEARCH_ACTIVE = 'SET_ORDER_SEARCH_ACTIVE';
export const SET_ORDER_LIST_ORDER_SORT = 'SET_ORDER_LIST_ORDER_SORT';
export const SET_ORDER_FILTER_YEAR = 'SET_ORDER_FILTER_YEAR';
export const SET_ORDER_FILTER_STATUS = 'SET_ORDER_FILTER_STATUS';

export const getOrders: (payload: FilteredOrdersPaginationPayload) => ActionPayload<GetOrderListPayload> = (payload) => ({ type: GET_ORDERS, payload });

export const handleGetOrders:(payload: GetOrderListPayload) => ActionPayload<GetOrderListPayload> = (payload) => ({ type: HANDLE_GET_ORDERS, payload });

export const getOrdersSuccess: (payload: Partial<OrdersStore>) => ActionPayload<Partial<OrdersStore>> =
  (payload: Partial<OrdersStore>) => ({ type: GET_ORDERS_SUCCESS, payload });

export const getOrderError: () => Action = () => ({ type: GET_ORDERS_ERROR });

export const getOrder: (params: { id: number, selectedAccounts: number[] }) => ActionPayload<{ id: number, selectedAccounts: number[] }> = ({ id, selectedAccounts }) => ({
  type: GET_ORDER,
  payload: { id, selectedAccounts },
});

export const getOrderAudit: (payload: GetOrderDevicesPagePayload) => ActionPayload<GetOrderDevicesPagePayload> = (payload)=> ({
  type: GET_ORDER_AUDIT,
  payload,
});

export const getOrderAuditStats: (orderId: number) => ActionPayload<number> = (payload) => ({
  type: GET_ORDER_AUDIT_STATS,
  payload,
});

export const getOrderSuccess: (order: OrderWithAudit) => ActionPayload<OrderWithAudit> = (payload) => ({ type: GET_ORDER_SUCCESS, payload });

export const getOrderAuditStatsSuccess: (orderAuditStats: OrderAuditStatsSuccessAction) => ActionPayload<OrderAuditStatsSuccessAction> = (payload) => ({
  type: GET_ORDER_AUDIT_STATS_SUCCESS,
  payload,
});

export const getOrderAuditDevicePage: (pagination: GetOrderDevicesPagePayload) => ActionPayload<GetOrderDevicesPagePayload> = (payload) => ({
  type: GET_ORDER_AUDIT_DEVICES_PAGE,
  payload,
});

export type OrderAuditDevicesPagePayloadSuccess = {
  orderId: number;
  devices: SuccessOrderAuditResponseDto['data'];
}

export const getOrderAuditDevicePageSucces: (pagination: OrderAuditDevicesPagePayloadSuccess) => ActionPayload<OrderAuditDevicesPagePayloadSuccess> = (payload) => ({
  type: GET_ORDER_AUDIT_DEVICES_PAGE_SUCCESS,
  payload,
});

export const setOrderListSort: (payload: string) => ActionPayload<string> = (payload) => ({ type: SET_ORDER_LIST_SORTBY, payload });

export const setOrderSearchType: (payload: SEARCH_TYPES) => ActionPayload<SEARCH_TYPES> = (payload) => ({ type: SET_ORDER_SEARCH_TYPE, payload });

export const setOrderListSortOrder: (payload: SORT_ORDER) => ActionPayload<SORT_ORDER> = (payload) => ({
  type: SET_ORDER_LIST_ORDER_SORT,
  payload,
});

export const setSearchActive = (payload: boolean): ActionPayload<boolean> => ({ type: SET_ORDER_SEARCH_ACTIVE, payload });

export const setOrderSearchValue: (payload: string) => ActionPayload<string> = (payload) => ({
  type: SET_ORDER_SEARCH_VALUE,
  payload,
});

export const setOrderFilterYear = (payload: number[]): ActionPayload<number[]> => ({
  type: SET_ORDER_FILTER_YEAR,
  payload
});

export const setOrderFilterStatus = (payload: OrderStatus[]): ActionPayload<OrderStatus[]> => ({
  type: SET_ORDER_FILTER_STATUS,
  payload
});
