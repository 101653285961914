import React, { FC, useContext, useEffect, useState, memo } from 'react';
import { Input, DateInput, Dropdown, DropdownItem, InputType } from '../..';
import { confirmModalContext, orderWizardContext, STEPS } from '../../../context';

type City = {
  nom: string;
  code: string;
};

export const FirstStep:FC = memo(function FirstStep () {
  const [citiesList, setCitiesList] = useState<DropdownItem[]>([]);
  const { order, setProperty, hasError } = useContext(orderWizardContext);
  const { setShouldOpenModal, shouldOpenModal } = useContext(confirmModalContext);
  const {
    [STEPS.STEP1]: {
      city,
      additionalAddress,
      street,
      deliverAt,
      postCode,
      addressInfo,
    }
  } = order;

  const enableModalConfirmation = () => {
    if(shouldOpenModal) {
      return;
    }

    setShouldOpenModal(true);
  };

  const setCity = (newCity: string) => {
    setProperty(STEPS.STEP1, 'city', newCity);
    enableModalConfirmation();
  };

  const setDate = (newDate: string | Date) => {
    setProperty(STEPS.STEP1, 'deliverAt', `${newDate}`);
  };

  const setPostcode = (newPostCode: string) => {
    if(newPostCode.length > 5) {
      return ;
    }

    setProperty(STEPS.STEP1, 'postCode', newPostCode);
    enableModalConfirmation();
  };

  const setStreet = (newStreet: string) => {
    setProperty(STEPS.STEP1, 'street', newStreet);
    enableModalConfirmation();
  };

  useEffect(() => {
    setDate(`${new Date()}`);
  }, []);

  useEffect(() => {
    if(postCode.length < 3) {
      return ;
    }

    fetch (`https://geo.api.gouv.fr/communes?codePostal=${postCode}&fields=nom&format=json`)
      .then((res) => res.json())
      .then((data) => {
        if(!data.length) {
          return ;
        }

        const citiesListToSave = data.length === 1
        ? []
        : data.map(({ nom }: City) => ({ id: nom, name: nom }));

        setCity(data[0].nom);
        setCitiesList(citiesListToSave);
      });
  }, [postCode]);

  return (
    <div className="FirstStepComponent">
      <h3 className="FirstStepComponent__Title">Indiquez le site de collecte</h3>
      <span className="FirstStepComponent__SubTitle">On se charge du reste</span>
      <div className="FirstStepComponent__Content">
        <div className="FirstStepComponent__Column">
          <Input
            label="Code postal*"
            id="postCode"
            error={hasError('postCode') ? 'Code postal non valable' : ''}
            value={postCode}
            onChange={(newPostcode) => setPostcode(newPostcode as string)}
          />
          {citiesList.length > 0 && (
            <Dropdown
            label="Ville*"
            items={citiesList}
            onChange={(selectedId) => setCity(selectedId as string)}
            value={city}
          />
          )}
          {!citiesList.length && (
            <Input
            label="Ville*"
            id="city"
            error={hasError('city') ? 'Ce champs est obligatoire' : ''}
            value={city}
            onChange={(newValue) => setCity(newValue as string)}
          />
          )}
          <Input
            label="Rue*"
            id="street"
            error={hasError('street') ? 'Ce champs est obligatoire' : ''}
            value={street}
            onChange={(newStreet) => setStreet(newStreet as string)}
          />
          <Input
            label="Complément d’adresse"
            id="addressComplement"
            value={additionalAddress}
            onChange={(secondAddress) => {
              setProperty(STEPS.STEP1, 'additionalAddress', secondAddress as string);
              enableModalConfirmation();
            }}
          />
          <span className="FirstStepComponent__MandatoryText">*champs obligatoires</span>
        </div>
        <div className="FirstStepComponent__Column">
          <Input
            id="locationAdditionlInfo"
            value={addressInfo}
            label="Conditions d’accès au bâtiment"
            onChange={(newAddressInfo) => {
              setProperty(STEPS.STEP1, 'addressInfo', newAddressInfo as string);
              enableModalConfirmation();
            }}
            type={InputType.TEXTAREA}
          />
          <DateInput
            label="Date de collecte souhaitée"
            value={new Date(deliverAt)}
            onChange={(newDate) => {
              setDate(newDate);
              enableModalConfirmation();
            }}
          />
        </div>
      </div>
    </div>
  );
});
