import React, { FC } from 'react';

export const EquipmentsIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 12H2m3.45-6.89L2 12v6c0 .53.21 1.04.586 1.414C2.96 19.79 3.47 20 4 20h16c.53 0 1.04-.21 1.414-.586C21.79 19.04 22 18.53 22 18v-6l-3.45-6.89A1.999 1.999 0 0016.76 4H7.24a1.999 1.999 0 00-1.79 1.11v0zM6 16h.01M10 16h.01"
      ></path>
    </svg>
  );
};
