import React, { FC } from 'react';

export const CertificationIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#012D4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 10A4.668 4.668 0 107.999.665 4.668 4.668 0 008 10z"
      ></path>
      <path
        stroke="#012D4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.473 9.26l-.806 6.073 3.333-2 3.333 2-.806-6.08"
      ></path>
    </svg>
  );
};
