import React, { FC, memo } from 'react';

type Props = {
  setIsShown: (newIsShown: boolean) => void;
}

export const TutorialVideo:FC<Props> = memo(function TutorialVideo({ setIsShown }) {
  return (
    <div className="TutorialVideoComponent__Wrapper">
      <div className="TutorialVideoComponent">
        <button
          className="TutorialVideoComponent__Close"
          onClick={() => setIsShown(false)}
        >
          +
        </button>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/_7ESQjkkDsc?autoplay=1&mute=1&controls=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowFullScreen
        >
        </iframe>
      </div>
    </div>
  );
});
