import React, { FC, useEffect, memo, useContext } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { PATHS } from '../../router';
import { Loader, Header } from '..';
import {
  selectUser,
  userGet,
  selectUserAccounts,
  handleGetAccounts,
  changeSelectedAccounts,
} from '../../store/modules/user';
import {
  clearLoad,
  getNotifications,
  selectLoadingState,
  setUserMenuActive,
  startLoad,
  stopLoad,
} from '../../store/modules/app';
import {
  checkIfActualAccounts,
  getAccounts,
  getStorageSelectedAccounts,
  setStorageSelectedAccounts
} from '../../services';
import { LOAD_ENDPOINTS, RootStore } from '../../types';
import { addBodyScroll, parseAccounts } from '../../utils';
import { useLogout } from '../../hooks';
import { tutorialVideoContext } from '../../context';
import { LegalLinks } from '../LegalLinks/LegalLinks';

type Props = {
  withoutHeader?: boolean;
  mobileNoPadding?: boolean;
  className?: string;
};

const pagesWithoutDesktopHeader = [
  PATHS.LOGIN,
];

let NOTIFICATIONS_INTERVAL: NodeJS.Timer | null = null;

export const PageBase: FC<Props> = memo(function PageBase({
  children,
  withoutHeader = false,
  mobileNoPadding = false,
  className = '',
}) {
  const { location: { pathname } } = useHistory();
  const user = useSelector(selectUser);
  const selectedAccounts = useSelector(selectUserAccounts);
  const accountsLoadingState = useSelector((state) => selectLoadingState(state as RootStore, LOAD_ENDPOINTS.GET_ACCOUNTS));
  const dispatch = useDispatch();
  const logout = useLogout();
  const { setIsShown, isShown } = useContext(tutorialVideoContext);

  const fetchNotifications = () => {
    dispatch(getNotifications());
  };

  useEffect(() => {
    addBodyScroll();

    if (user.accounts.list?.length) {
      return;
    }

    if (user.firstTime && !isShown) {
      setIsShown(true);
    }

    dispatch(startLoad(LOAD_ENDPOINTS.GET_ACCOUNTS));
    getAccounts()
      .then(({ groupes }) => {
        if (!groupes?.length) {
          return logout();
        }

        const parsedAccounts = parseAccounts(groupes);
        const fetchedAccountIds = parsedAccounts.map(({ id }) => id);

        let userSelectedAccounts = getStorageSelectedAccounts();

        if (!checkIfActualAccounts(fetchedAccountIds)) {
          userSelectedAccounts = (parsedAccounts[0]?.id
            ? [parsedAccounts[0]?.id]
            : []
          ) as number[];
        }

        setStorageSelectedAccounts(userSelectedAccounts);
        dispatch(handleGetAccounts(parsedAccounts));
        dispatch(changeSelectedAccounts(userSelectedAccounts));
        dispatch(stopLoad(LOAD_ENDPOINTS.GET_ACCOUNTS));
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (!user.email && selectedAccounts.length) {
      dispatch(userGet(selectedAccounts));

    }

    if(!NOTIFICATIONS_INTERVAL && user.email) {
      NOTIFICATIONS_INTERVAL = setInterval(fetchNotifications, 60000);
      fetchNotifications();
    }

    return () => {
      dispatch(clearLoad());
      dispatch(setUserMenuActive(false));
      clearInterval(NOTIFICATIONS_INTERVAL as NodeJS.Timer);
      NOTIFICATIONS_INTERVAL = null;
    };
  }, [user]);

  let pageClasses = 'Page';
  const isHeaderOnPage = !pagesWithoutDesktopHeader.includes(pathname);

  if (withoutHeader) {
    pageClasses += ' WithoutHeader';
  }

  if (mobileNoPadding) {
    pageClasses += ' MobileNoPadding';
  }

  return (
    <>
      {!withoutHeader && <Header showOnDesktop={isHeaderOnPage} />}
      <div
        className={`${pageClasses} ${className}`}
        style={{backgroundImage: 'url("/media/pages-background.png")'}}
      >
        {accountsLoadingState && <Loader className='MainLoader' />}
        {children}
        <LegalLinks/>
      </div>
    </>
  );
});
