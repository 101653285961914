import React, { FC } from 'react';

export const ChangePasswordIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#0D142D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 11H5a2 2 0 00-2 2v7a2 2 0 002 2h14a2 2 0 002-2v-7a2 2 0 00-2-2zM7 11V7c0-1.326.527-2.598 1.464-3.536a5.004 5.004 0 017.072 0A5.004 5.004 0 0117 7v4"
      ></path>
    </svg>
  );
};
