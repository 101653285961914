import React, { FC, memo } from 'react';
import { LogoIcon } from '../../icons';

export const UnAuthPageBase: FC = memo(function UnAuthPageBase({ children }) {
  return (
    <div className="UnAuthPageBaseComponent">
      <div className="UnAuthPageBaseComponent__Left">
        <div className="UnAuthPageBaseComponent__Logo"><LogoIcon width={110} height={50} /></div>
        {children}
      </div>
      <div className="UnAuthPageBaseComponent__Right">
        <img src="/media/login-back.jpeg" alt="" />
      </div>
    </div>
  );
});
