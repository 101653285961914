import React, { FC } from 'react';

export const CreateOrderImportedFileIcon:FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#0D142D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.666 1.333H4a1.333 1.333 0 00-1.333 1.333v10.667A1.334 1.334 0 004 14.666h8a1.333 1.333 0 001.333-1.333V6L8.666 1.333z"
      ></path>
      <path
        stroke="#0D142D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.666 1.333V6h4.667"
      ></path>
    </svg>
  );
};
