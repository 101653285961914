type Pages = 'LOGIN' |
  'DASHBOARD' |
  'HOME' |
  'COMMANDS' |
  'ERROR' |
  'USER_INFO' |
  'AUDIT' |
  'ORDER_CREATE' |
  'VALIDATE_EMAIL' |
  'FOROGOT_PASSWORD' |
  'CHANGE_PASSWORD' |
  'CSR_PAGE' |
  'CSR_EQUIPMENTS' |
  'CSR_RATE' |
  'FINANCIAL_DASHBOARD' |
  'CHANGE_OLD_PASS' |
  'MAINTANANCE' |
  'CSR_PRINT' |
  'USER_PREFERENCES' |
  'NOTIFICATIONS' |
  'MENTIONS_LEGALES' |
  'POLITIQUE_CONFIDENTIALITE' |
  'COOKIES_DECLARATION';

export const PATHS: Record<Pages, string> = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  COMMANDS: '/orders',
  ERROR: '/error',
  HOME: '/',
  USER_INFO: '/profile',
  AUDIT: '/audit',
  VALIDATE_EMAIL: '/validate',
  ORDER_CREATE: '/order/create',
  FOROGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  FINANCIAL_DASHBOARD: '/financial-tracking',
  CSR_PAGE: '/CSR',
  CSR_EQUIPMENTS: '/equipments',
  CSR_RATE: '/rate',
  CHANGE_OLD_PASS: '/refresh-pass',
  MAINTANANCE: '/maintanance',
  CSR_PRINT: '/CSR-Print',
  USER_PREFERENCES: '/user-preferences',
  NOTIFICATIONS: '/notifications',
  MENTIONS_LEGALES: '/mentions-legales',
  POLITIQUE_CONFIDENTIALITE: '/politique-confidentialite',
  COOKIES_DECLARATION: '/cookies-declaration'
};
