import { LOAD_ENDPOINTS, RootStore } from '../../../types';

export const selectLoading = (state: RootStore) => {
  const endpointStates = Object.values(state.app.loading);

  return endpointStates.length
    ? endpointStates.reduce((accum, loadState) => accum + loadState)
    : 0;
};
export const selectLoadingState = (state: RootStore, loadingEndpoint: LOAD_ENDPOINTS) => state.app.loading[loadingEndpoint];
export const selectError = (state: RootStore) => state.app.error;
export const selectSnackBar = (state: RootStore) => state.app.snackBar;
export const selectUserMenuState = (state: RootStore) => state.app.userMenu;
export const selectNotificationsState = (state: RootStore) => state.app.notifications.isOpen;
export const selectNotificationListState = (state: RootStore) => state.app.notifications.list;
export const selectNotificationUnsreadState = (state: RootStore) => state.app.notifications.unread;
