import React from 'react';

export const CO2Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 16V8a2.005 2.005 0 00-1-1.73l-7-4a1.995 1.995 0 00-2 0l-7 4A1.997 1.997 0 003 8v8a1.994 1.994 0 001 1.729l7 4a1.996 1.996 0 002 .001l7-4a2.005 2.005 0 001-1.731z"
      ></path>
    </svg>
  );
};
