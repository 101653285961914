import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { userLogout } from '../store/modules/user';
import { PATHS } from '../router';

type UseLogout = () => () => void;

export const useLogout:UseLogout = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  return () => {
    dispatch(userLogout());

    return push(PATHS.LOGIN);
  };
};
