import { OrderWithAudit } from '..';
import { ExpiredTokenResponse, FETCH_STATUS, SuccessFetch, SuccessFetchList } from '../FetchRequest.type';

export enum OrderStatus {
  IN_PROGRESS = 'Enlèvement',
  RECEPTION = 'Réception',
  AUDIT = 'audit',
  DOCUMET_READY = 'document_ready',
  FINISHED = 'Terminée',
}

export enum ORDER_INVOICE {
  INVOICE_NOT_EDITED = 'Non édité',
  WAIT_INVOICE = 'En attente facturaction',
  RECIEVED_INVOICE = 'Facture reçue',
}

export enum ORDER_DOCUMENT {
  ENSURE_CERTIFICATE = 'effacementCertificat',
  DESTRUCTION_CERTIFICATE = 'destructionCertificat',
  AUDIT_REPORT_PDF = 'auditRapportPdf',
  AUDIT_REPORT_CSV = 'auditRapportCsv',
}

export interface OrderListItem {
  deliveryDate: string;
  dossier: string;
  id: number;
  status: OrderStatus;
  Invoices?: string;
}

export type OrderListSuccessDTO = SuccessFetchList<OrderListItem>;

export type OrdersDto = OrderListSuccessDTO | ExpiredTokenResponse;

export interface OrderDto {
  id: number;
  status: OrderStatus;
  orderDate: string;
  location: string;
  compagnyContact: string;
  support: string;
  webAuditDate: null | string;
  internalValidDate: null | string;
  actualDeliveryDate: null | string;
  Invoices: ORDER_INVOICE;
  supplier: string;
}

export interface OrderNotFoundDto {
  code: number;
  message: 'Entity Not Found';
  status: FETCH_STATUS.ERROR;
}

export type SuccessOrderDto = SuccessFetch<OrderWithAudit>;

export type OrderResponseDto = SuccessOrderDto | ExpiredTokenResponse | OrderNotFoundDto;

export interface OrderAuditStats {
  endDate: string;
  estimatedDate: string;
  totalItems: number;
}

export type SuccsessOrderAuditStats = SuccessFetch<OrderAuditStats>;

export type OrderAuditListItem = {
  id: number;
  vip: string;
  model: string;
  serial: string;
  state: string;
  document?: string;
  tag: string;
  unitPrice: number;
  passwordBlocked: boolean;
  family: string;
};

export type OrderAuditStatsSuccessAction = {
  orderId: number;
  stats: OrderAuditStats;
};

export type OrderSearchItem = {
  id: number;
  dossier: string;
  status: OrderStatus;
  deliveryDate: string;
};

export type CreatedOrderDto = {
  status: FETCH_STATUS.SUCCESS;
  data: {
    id: number;
    dossier: string;
    orderDate: string;
    location: string;
    support: string;
    webAuditDate: string;
    internalValidDate: string;
    actualDeliveryDate: string;
  };
};

export type OrderDocumentsStatus = Record<ORDER_DOCUMENT, boolean>;

export type OrderDocumentsStatusDto = SuccessFetch<OrderDocumentsStatus> | ExpiredTokenResponse;
export type SuccessOrderAuditResponseDto = SuccessFetchList<OrderAuditListItem>;
export type OrderAuditDto = SuccessOrderAuditResponseDto | ExpiredTokenResponse;
export type OrderAuditStatsDto = SuccsessOrderAuditStats | ExpiredTokenResponse;
export type OrderSearchDto = SuccessFetchList<OrderSearchItem> | null;

// Guards

export const isSuccessOrderList = (orderResponse: OrdersDto): orderResponse is OrderListSuccessDTO => {
  return (orderResponse as OrderListSuccessDTO).status === FETCH_STATUS.SUCCESS;
};

export const isNotFoundResponse = (orderResponse: OrderResponseDto): orderResponse is OrderNotFoundDto => {
  return (
    !orderResponse ||
    ((orderResponse as OrderNotFoundDto).status === FETCH_STATUS.ERROR &&
      (orderResponse as OrderNotFoundDto).message === 'Entity Not Found')
  );
};

export type FilteredOrdersSuccessDTO = SuccessFetchList<OrderListItem>;

export type OrdersErrorsDTO = {
  status: FETCH_STATUS.SUCCESS;
  order: Record<string, boolean>[];
};
