import React, { FC, memo } from 'react';
import { Button } from '../';
import { EditIcon } from '../../icons';
import { ButtonColor } from '../Button';

type Props = {
  onNewOrderClick?: () => void;
}

export const NoCommands: FC<Props> = memo(function NoCommands({ onNewOrderClick }) {
  const title = onNewOrderClick
    ? 'Vous n’avez encore aucune commande'
    : 'Aucune commande';

  const imageSrc = onNewOrderClick
    ? '/media/no-commands.png'
    : '/media/no-commands-filter.png';

  return (
    <div className="NoCommandsComponent">
      <h3 className="NoCommandsComponent__SubTitle">{title}</h3>
      {onNewOrderClick && (
        <Button
          className="NoCommandsComponent__NewOrder"
          icon={<EditIcon/>}
          color={ButtonColor.SECUNDARY}
          label="Nouvelle commande"
          onClick={onNewOrderClick}
        />
      )}
      <img src={imageSrc} className={`${onNewOrderClick ? '' : 'WithFilter'}`} alt="no commands"/>
    </div>
  );
});
