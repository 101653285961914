import React, { FC } from 'react';

export const MoreDotsIcon:FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
    >
      <path
        fill="#555864"
        stroke="#555864"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.43 10.833a.839.839 0 00.844-.833.84.84 0 00-.844-.834.84.84 0 00-.844.834c0 .46.378.833.844.833zm5.907 0a.838.838 0 00.844-.833.839.839 0 00-.844-.834.839.839 0 00-.843.834c0 .46.377.833.843.833zm-11.814 0A.839.839 0 005.367 10a.84.84 0 00-.844-.834.84.84 0 00-.844.834c0 .46.378.833.844.833z"
      ></path>
    </svg>
  );
};
