import React, { createContext, FC, useState } from 'react';
import { createOrder } from '../services';
import { OrderCreateContact, OrderDevices } from '../types';
import { CreatedOrderDto } from '../types/dto';
import { isEmail } from '../utils';

export enum STEPS {
  STEP1 = 'step1',
  STEP2 = 'step2',
  STEP3 = 'step3',
  STEP4 = 'step4',
  STEP5 = 'step5',
}

export enum ADDED_DEVICES_TYPE {
  MANUAL = 'MANUAL',
  IMPORTED = 'IMPORTED',
}

export enum DEVICE_TYPE {
  CENTRAL_UNIT = 'Unité centrale',
  MOBILITY = 'Mobilité',
  PRINTER='Imprimante',
  PC_PORTABLE = 'PC portable',
  SERVER = 'Serveur',
  SCREEN = 'Ecran',
  BAIE = 'Baie',
  OTHER_SMALL = 'Divers petit format',
  OTHER_BIG = 'Divers grand format',
}

export enum CONTACT_FUNCTION {
  ADMINISTRATOR = 'Administrateur',
  COMERCIAL_ASSISTANT = 'Assistante Commerciale',
  PROJECT_CHEF = 'Chef de projet',
  COMMERCIAL = 'Commercial',
  COMPTA_FURNISOR = 'Compta Fournisseur',
  COMPATIBILITE = 'Comptabilité',
  CONTROLOR_GESTION = 'Contrôleur de Gestion',
  DIRECTOR = 'Directeur',
  DIRECTOR_ACHATS = 'Directeur Achats',
  DIRECTOR_ADJOINT = 'Directeur adjoint',
  FINAL_ADMINISTRATIVE_DIRECTOR ='Directeur Administratif et Fin',
  COMMERCIAL_DIRECTOR = 'Directeur Commercial',
  FINANCE_DIRECTOR = 'Directeur Financier',
  TEHNICAL_DIRECTOR = 'Directeur Technique',
  GERANT = 'Gérant',
  RESPONSABLE_ACHATS = 'Responsable Achats',
  RESPONSABLE_BROKE = 'Responsable Broke',
  RESPONSABLE_FURNISOR = 'Responsable Fournisseurs',
  RESPONSABLE_SAV = 'Responsable SAV',
  SALARIE = 'Salarié',
  SERVICE_ACHATS = 'Service Achats',
  TRANSPORT = 'Transport'
}

export type ManualDeviceItem = {
  type: DEVICE_TYPE;
  description: string;
  amount: number;
}

export type CreateOrderState = {
  [STEPS.STEP1]: {
    postCode: string;
    city: string;
    street: string;
    additionalAddress: string;
    addressInfo: string;
    deliverAt: string;
    streetNumber: number;
  };
  [STEPS.STEP2]: {
    contacts: {
      gender: string;
      name: string;
      surname: string;
      phone: string;
      email: string;
      comment: string;
      contactFunction: string;
    }[];
  };
  [STEPS.STEP3]: {
    listAddedType: ADDED_DEVICES_TYPE | null;
    [ADDED_DEVICES_TYPE.MANUAL]?: ManualDeviceItem[];
    [ADDED_DEVICES_TYPE.IMPORTED]?: Record<string, string>;
  };
  [STEPS.STEP4]: {
    notPackaged: boolean,
    withPalet: boolean,
    paletNumber: number,
    hasTag: boolean,
    desiredErase: string,
    otherErase: string,
    comment: string,
  };
  [STEPS.STEP5]: Record<string, string>;
}

type CreateOrderPayload = CreateOrderState;

export type OrderField = keyof CreateOrderState[STEPS];

type OrderWizardProviderValue = {
  order: CreateOrderPayload;
  setOrder: (order: CreateOrderPayload) => void;
  setProperty: (step: STEPS, key: string, value: string) => void;
  errors: string[];
  setErrors: (field:string[] | ((prevErrs: string[]) => string[])) => void;
  hasError: (field: string) => boolean;
  validateStep: (step: STEPS) => boolean;
  submitOrder: (account: number) => Promise<CreatedOrderDto>;
  clearOrder: () => void;
  activeStep: STEPS;
  setActiveStep: (step: STEPS) => void;
  selectedOrderAcount: number;
  setSelectedOrderAcount: (accountId: number) => void
}

export const initialContact = {
  gender: '',
  name: '',
  surname: '',
  phone: '',
  email: '',
  comment: '',
  contactFunction: '',
};

export const initialDevice = { type: DEVICE_TYPE.CENTRAL_UNIT, amount: 1, description: '' };

export const computeDeviceFieldError = (index: number, field:string ): string => `devices__${index}__${field}`;
export const computeContactFieldError = (index: number, field:string ): string => `contacts__${index}__${field}`;

const orderDefaultState: CreateOrderPayload = {
  [STEPS.STEP1]: {
    postCode: '',
    city: '',
    street: '',
    additionalAddress: '',
    addressInfo: '',
    deliverAt: (new Date()).toString(),
    streetNumber: 0,
  },
  [STEPS.STEP2]: {
    contacts: [initialContact],
  },
  [STEPS.STEP3]: {
    listAddedType: null,
    [ADDED_DEVICES_TYPE.MANUAL]: [initialDevice]
  },
  [STEPS.STEP4]: {
    notPackaged: false,
    withPalet: false,
    paletNumber: 1,
    hasTag: false,
    desiredErase: '',
    otherErase: '',
    comment: '',
  },
  [STEPS.STEP5]: {},
};

export const orderWizardContext = createContext<OrderWizardProviderValue>({
  order: orderDefaultState,
  setOrder : (order) => console.log(),
  setProperty: (step, key, value) => console.log(),
  errors: [],
  setErrors: (newErrors: string[] | ((prevErrs: string[]) => string[])) => console.log(),
  hasError: (field) => true,
  validateStep: () => true,
  submitOrder: () => Promise.resolve({} as unknown as CreatedOrderDto),
  clearOrder: () => console.log('clear'),
  activeStep: STEPS.STEP1,
  setActiveStep: (step: STEPS) => console.log(),
  selectedOrderAcount: 0,
  setSelectedOrderAcount: (accountId: number) => console.log(),
});

export const OrderWizardProvider:FC = ({ children }) => {
  const { Provider } = orderWizardContext;
  const [activeStep, setActiveStep] = useState(STEPS.STEP1);
  const [selectedOrderAcount, setSelectedOrderAcount] = useState(0);
  const [order, setOrder] = useState<CreateOrderPayload>(orderDefaultState);
  const [errors, setErrors] = useState<string[]>([]);

  const setProperty = (step: STEPS, key: string, value: string): void => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      [step]: {
        ...prevOrder[step],
        [key]: value,
      }
    }));

    if (hasError(key)) {
      setErrors(errors.filter((errorField) => errorField !== key));
    }
  };

  const hasError = (key: string) => errors.includes(key);

  const validateFirstStep = () => {
    const mandatoryFields = ['postCode', 'city', 'street'];
    const invalidFields = Object.keys(order[STEPS.STEP1]).filter((key) => {
      const fieldValue = order[STEPS.STEP1][key as OrderField] as string;
      const mandatoryAndEmpty = mandatoryFields.includes(key) && !fieldValue;

      if (key === 'postCode') {
        return mandatoryAndEmpty || fieldValue.length !== 5;
      }

      return mandatoryAndEmpty;
    });

    setErrors(invalidFields);

    return !invalidFields.length;
  };

  const validateSecondStep = () => {
    const mandatoryContactFields = ['name', 'surname', 'phone', 'email'];
    let errorsToSet: string[] = [];
    const specialValidationFields = ['phone', 'email'];

    const invalidcontacts = order[STEPS.STEP2].contacts.reduce((accum, contact, index) => {
      const invalidcontactfields = Object.keys(contact).filter((key) => {
        const mandatoryAndFilled = mandatoryContactFields.includes(key as never) && !contact[key as OrderField];

        if (!specialValidationFields.includes(key)) {
          return mandatoryAndFilled;
        }

        if (key === 'phone') {
          return mandatoryAndFilled || contact[key].length < 14;
        }

        return mandatoryAndFilled || !isEmail(contact[key as OrderField]);
      });

      if (!invalidcontactfields.length) {
        return accum;
      }

      const parsedErrorFields = invalidcontactfields.map((invalidField) => computeContactFieldError(index, invalidField));
      errorsToSet = [...errorsToSet, ...parsedErrorFields];

      return ({ ...accum, [index]: invalidcontactfields });
    }, {});

    setErrors(errorsToSet);

    return !Object.keys(invalidcontacts).length;
  };

  const validateThirdStep = () => {
    const mandatoryContactFields = ['type', 'description', 'amount'];
    let errorsToSet: string[] = [];
    const { [STEPS.STEP3] : { listAddedType, [ADDED_DEVICES_TYPE.IMPORTED] : importedFiles }} = order;

    if (!listAddedType) {
      return false;
    }

    if (listAddedType === ADDED_DEVICES_TYPE.IMPORTED) {
      const existsSavedFile = Object.keys(importedFiles || {}).length;

      if (!existsSavedFile) {
        errorsToSet.push(ADDED_DEVICES_TYPE.IMPORTED);
      }
    }

    if (listAddedType === ADDED_DEVICES_TYPE.MANUAL) {
      (order[STEPS.STEP3].MANUAL || []).reduce((accum, contact, index) => {
        const invalidcontactfields = Object.keys(contact).filter((key) => {
          return mandatoryContactFields.includes(key as never) && !contact[key as OrderField];
        });

        if (!invalidcontactfields.length) {
          return accum;
        }

        const parsedErrorFields = invalidcontactfields.map((invalidField) => computeDeviceFieldError(index, invalidField));
        errorsToSet = [...errorsToSet, ...parsedErrorFields];

        return ({ ...accum, [index]: invalidcontactfields });
      }, {});
    }

    setErrors(errorsToSet);

    return !errorsToSet.length;
  };

  const sanitizeOrderData = (userAccount: number) => {
    const {
      [STEPS.STEP1] : { postCode, street, city, additionalAddress, addressInfo, deliverAt },
      [STEPS.STEP2]: { contacts },
      [STEPS.STEP3]: { listAddedType, MANUAL },
      [STEPS.STEP4]: { comment, desiredErase, hasTag, notPackaged, otherErase, paletNumber, withPalet },
    } = order;

    const contact = (contacts.map(({ email, name, surname, phone, gender, contactFunction, comment: contactComment }, index) => ({
      email,
      phone,
      genre: gender,
      fonction: contactFunction,
      commentaire: contactComment,
      nom: name,
      prenom: surname,
      priority : index === 0 ? 'principal' : 'other',
    }))) as OrderCreateContact[];

    const commande = (listAddedType !== ADDED_DEVICES_TYPE.MANUAL
    ? []
    : MANUAL?.map(({ type, amount, description }) => ({
      description,
      famille: type,
      quantité: amount,
    }))) as OrderDevices[];

    return {
      contact,
      commande,
      country: 'France',
      Code_Fournisseur: userAccount,
      code_postal: postCode,
      ville: city,
      rue: street,
      complement_adresse: additionalAddress,
      condition_acces_au_batiment: addressInfo,
      date_de_collecte_souhaite: deliverAt,
      le_materiel_est_palettise: withPalet,
      le_materiel_est_pas_conditionne: notPackaged,
      nombre_de_palettes: paletNumber,
      type_effacement_souhaite: desiredErase,
      je_souhaite_un_releve_code_parc: hasTag,
      prestation_commentaire: comment,
      autre_preciser: otherErase,
    };
  };

  const submitOrder = (userAccount: number): Promise<CreatedOrderDto> => {
    const parsedOrder = sanitizeOrderData(userAccount);

    return createOrder({ parsedOrder });
  };

  const validateStep = (stepToValidate: STEPS) => {
    const stepsValidationMap = {
      [STEPS.STEP1]: validateFirstStep,
      [STEPS.STEP2]: validateSecondStep,
      [STEPS.STEP3]: validateThirdStep,
      [STEPS.STEP4]: () => true,
      [STEPS.STEP5]: () => true,
    };

    return stepsValidationMap[stepToValidate]();
  };

  const clearOrder = () => setOrder(orderDefaultState);

  return (
    <Provider
      value={{
        order,
        setOrder,
        setProperty,
        errors,
        setErrors,
        hasError,
        validateStep,
        submitOrder,
        clearOrder,
        activeStep,
        setActiveStep,
        selectedOrderAcount,
        setSelectedOrderAcount,
      }}
    >
      {children}
    </Provider>
  );
};
