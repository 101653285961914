import React, { FC } from 'react';

type Props = {
  color?: string;
}

export const OrderInProgressStatusIcon:FC<Props> = ({ color = '#fff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.666 2h-10v8.667h10V2zm0 3.333h2.667l2 2v3.334h-4.667V5.334z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.667 14a1.666 1.666 0 100-3.333 1.666 1.666 0 000 3.333zm8.666 0a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334z"
      ></path>
    </svg>
  );
};
