import React, { FC } from 'react';

export const OrderDocumentReadyStatusIcon:FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.333 1.333H4a1.334 1.334 0 00-1.333 1.334v10.666A1.334 1.334 0 004 14.666h8a1.333 1.333 0 001.333-1.332v-8l-4-4z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.334 1.333v4h4m-2.667 3.333H5.334m5.333 2.668H5.334M6.667 6H5.333"
      ></path>
    </svg>
  );
};
