import { USER_ROLES, USER_TYPE } from '../../../types/dto';
import { ObjectLiteral } from '../../../types/ObjectLiteral.type';
import { ActionPayload, UserStore } from '../../../types';
import {
  CHANGE_SELECTED_ACCOUNTS,
  FIRST_TUTORIAL_PLAYED,
  HANDLE_GET_ACCOUNTS,
  HANDLE_GET_USER,
  HANDLE_USER_LOGOUT,
  USER_LOG_IN_ERROR,
  USER_LOG_IN_SUCCESS,
} from './user.actions';
import { AccountWithGroup } from '../../../utils';

const initialState: UserStore = {
  firstname: '',
  lastName: '',
  email: '',
  logedIn: false,
  id: -1,
  company: '',
  job: '',
  type: USER_TYPE.SUPPLIER,
  support: null,
  firstTime: false,
  notifpreferences: false,
  role: USER_ROLES.NONE,
  donation: false,
  accounts: {
    list: null,
    selected: [],
  },
};

export const userReducer = (
  storeState: UserStore | undefined,
  action: ActionPayload<Partial<UserStore>>
): UserStore => {
  const state = storeState || initialState;
  const { type, payload } = action;

  const actionHandler: ObjectLiteral<() => UserStore> = {
    [USER_LOG_IN_SUCCESS]: () => ({ ...state, logedIn: true, firstTime: payload.firstTime as boolean }),
    [USER_LOG_IN_ERROR]: () => ({ ...state, error: 'LOGIN_ERROR' }),
    [HANDLE_USER_LOGOUT]: () => initialState,
    [HANDLE_GET_USER]: () => ({ ...state, ...payload }),
    [HANDLE_GET_ACCOUNTS]: () => ({
      ...state,
      accounts: {
        ...state.accounts,
        list: payload as unknown as AccountWithGroup[],
      },
    }),
    [CHANGE_SELECTED_ACCOUNTS]: () => ({
      ...state,
      accounts: {
        ...state.accounts,
        selected: payload as number[],
      },
    }),
    [FIRST_TUTORIAL_PLAYED]: () => ({ ...state, firstTime: false }),
  };

  if (!actionHandler[type]) {
    return state;
  }

  return actionHandler[type]();
};
