import { combineReducers } from 'redux';
import { RootStore } from '../types';
import { appReduder } from './modules/app';
import { orderReducer } from './modules/orders';
import { statisticsReducer } from './modules/statistics';
import { userReducer } from './modules/user';

const appStoreReducer = combineReducers({
  app: appReduder,
  user: userReducer,
  orders: orderReducer,
  statistics: statisticsReducer,
});

export default (state: RootStore | undefined, action: any) => {
  if (action.type === 'HANDLE_USER_LOGOUT') {
    state = undefined;
  }

  return appStoreReducer(state, action);
};
