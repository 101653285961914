import React, { FC } from 'react';

export const MoreOptionsIcon:FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#41434B"
        stroke="#41434B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 10.833a.834.834 0 100-1.668.834.834 0 000 1.668zM10 5a.834.834 0 100-1.668A.834.834 0 0010 5zm0 11.667a.834.834 0 100-1.668.834.834 0 000 1.668z"
      ></path>
    </svg>
  );
};
