const ACCOUNTS_STORAGE_KEY = 'ACCOUNTS';

export const getStorageSelectedAccounts = (): number[] => {
  const storedAccounts = localStorage.getItem(ACCOUNTS_STORAGE_KEY);

  return storedAccounts ? JSON.parse(storedAccounts) : [];
};

export const setStorageSelectedAccounts = (accounts: number[]): void => {
  const stordedAccounts = getStorageSelectedAccounts();

  if (accounts.toString() === stordedAccounts.toString()) {
    return;
  }

  localStorage.setItem(ACCOUNTS_STORAGE_KEY, JSON.stringify(accounts));
};

//INFO: check if all accounts from localstorage are still assigned to user
export const checkIfActualAccounts = (accounts: number[]): boolean => {
  const storedAccounts = getStorageSelectedAccounts();

  if (!storedAccounts.length) {
    return false;
  }

  return !storedAccounts.find((account) => !accounts.includes(account));
};
