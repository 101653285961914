export enum EMAIL_BODY_TYPE {
  RETOUR = 'RETOUR',
  CONTACT = 'CONTACT',
}

interface EmailProps {
  type: EMAIL_BODY_TYPE;
  client: string;
  deviceSerialNumber: number;
  orderId: number;
}

export const composeEmailBody = ({ type, client, deviceSerialNumber,orderId } : EmailProps): string => {
  if (type === EMAIL_BODY_TYPE.RETOUR) {
    return `
    Madame, Monsieur,%0d%0d
Votre client ${ client } souhaite le retour du matériel suivant : ${deviceSerialNumber} sur la commande fournisseur n° ${orderId}.%0d%0d
Veuillez informer votre client que vous avez bien pris en charge sa demande.%0d%0d
Cordialement%0d%0d
L’équipe My ATF`;
  }

  return `
  Madame, Monsieur,%0d%0d
Votre client ${ client } souhaite que vous le rappeliez au plus vite à%0d%0d
propos du matériel suivant : ${deviceSerialNumber} sur la commande fournisseur n° ${orderId}.%0d%0d
Cordialement%0d%0d
L’équipe My ATF`;
};
