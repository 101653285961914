import React, { FC } from 'react';

export const AttentionConfirmModalIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke="#BE2E3F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.333"
        d="M20 36.667c9.204 0 16.666-7.462 16.666-16.667 0-9.205-7.462-16.666-16.666-16.666-9.205 0-16.667 7.461-16.667 16.666S10.795 36.667 20 36.667zm.017-23.334V20m0 6.666H20"
      ></path>
    </svg>
  );
};
