import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../router';

export const LegalLinks: FC = () => {
  const { push } = useHistory();
  const goTo = (path: string) => () => push(path);

  return (
    <div className='Legal_Links'>
      <a onClick={goTo(PATHS.MENTIONS_LEGALES)}>Mentions légales</a>
      <a onClick={goTo(PATHS.POLITIQUE_CONFIDENTIALITE)}>Politique de confidentialité</a>
      <a onClick={goTo(PATHS.COOKIES_DECLARATION)}>Déclaration relative aux cookies</a>
    </div>
  );
};