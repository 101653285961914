import React, { FC, useMemo, useState, memo } from 'react';
import { EyeIcon, EyeOffIcon } from '../../icons';

export enum InputType {
  PASSWORD = 'password',
  TEXT = 'text',
  NUMBER = 'number',
  TEXTAREA = 'textarea',
}

type Props = {
  value: string | number;
  onChange: (value: string | number) => void;
  id: string;
  error?: string;
  type?: InputType;
  isDark?: boolean;
  label?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  shouldShowTooltip?: boolean;
};

export const Input: FC<Props> = memo(function Input({
  value,
  id,
  error,
  onChange,
  label = '',
  className = '',
  isDark = false,
  type = InputType.TEXT,
  disabled = false,
  placeholder = '',
  shouldShowTooltip = false,
}) {
  const [localType, setlocalType] = useState(type);
  const isPassword = useMemo(() => type == InputType.PASSWORD, []);

  const themeClass = isDark ? 'Dark' : '';
  const errorClass = error ? 'Error' : '';

  const togglePassword = (): void => {
    if (localType === InputType.PASSWORD) {
      return setlocalType(InputType.TEXT);
    }
    return setlocalType(InputType.PASSWORD);
  };

  const renderInput = () => {
    if (type === InputType.TEXTAREA) {
      return (
        <textarea
          name={id}
          id={id}
          cols={45}
          rows={5}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(event.currentTarget.value)}
        ></textarea>
      );
    }

    return (
      <input
        id={id}
        type={localType}
        value={value}
        autoComplete="off"
        disabled={disabled}
        placeholder={placeholder}
        onChange={(event: React.FormEvent<HTMLInputElement>) => onChange(event.currentTarget.value)}
      />
    );
  };

  return (
    <div className={`InputComponent__Wrapper ${className}`}>
      <div className={`InputComponent ${themeClass} ${errorClass}`}>
        {shouldShowTooltip && (
          <span className="InputComponent__Tooltip">
            Le mot de passe doit contenir au moins 12 caractères: <br />
            - 1 chiffre;
            <br />
            - 1 caractère majuscule;
            <br />
            - 1 caractère minuscule;
            <br />- 1 caractère spécial(!, @, #, ^, {'&'});
            <br />
          </span>
        )}
        <label htmlFor={id}>{label}</label>
        {renderInput()}
        {isPassword && (
          <button className="InputComponent__Eye" onClick={() => togglePassword()}>
            {localType === InputType.TEXT ? <EyeOffIcon /> : <EyeIcon />}
          </button>
        )}
      </div>
      {error && <div className="Error-Text">{error}</div>}
    </div>
  );
});
