import React, { FC } from 'react';

export const WaterIcon:FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 2.69l5.66 5.66a7.987 7.987 0 012.19 4.096 7.994 7.994 0 01-7.845 9.563A8 8 0 016.349 8.35L12 2.69z"
      ></path>
    </svg>
  );
};
