import React, { FC } from 'react';

type Props = {
  width?: number;
  height?: number;
}

export const LogoIcon:FC<Props> = ({ width=90, height=50 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Calque 1"
      viewBox="0 0 261.73 78.35"
      width={`${width}`}
      height={`${height}`}
    >
      <path
        fill="#78b537"
        d="M107.85 41.8c15.63 0 27.07 12.55 28.21 26.29a25.23 25.23 0 01-7.18 49.42H63c-14.25 0-23.83-9.35-23.83-21 0-9.76 6.88-19.36 16.83-21 0-16.88 15.4-23.89 26.61-18.28a28 28 0 0125.24-15.43z"
        transform="translate(-39.15 -39.17)"
      ></path>
      <path
        fill="#fff"
        d="M106 98.88l-.27-.19c-2.24-1.62-2-10.57-1.86-15.92 0-1 .05-2 .05-2.69 0-3.82-.81-6.59-2.45-8.23a5.73 5.73 0 00-4.24-1.65c-4.46 0-7.86 2.86-10.14 8.52a10 10 0 00-2.19-6.88 4.69 4.69 0 00-3.54-1.64c-4.36 0-7.83 2.86-10.36 8.53.22-2.56.59-6.84-1.94-8.07-2.81-1.4-6.1.86-7.38 1.9l-.19.16.2.15c1.57 1.22 1.72 4.12 1.81 6 0 .38 0 .73.06 1 .23 3.18.08 17.84-.87 20l-.1.24h.26c2 .2 5.85.58 7.63-1.06a3.15 3.15 0 001-2.47c-.12-4.51 0-15.46 4.1-19.62a3.41 3.41 0 012.29-1.19 1.21 1.21 0 01.89.38c.9.92 1.31 3.6 1.25 8.21 0 1.45-.09 3.11-.18 4.71a41.88 41.88 0 000 7.54 4.12 4.12 0 002.01 3.39c1.57.8 4 .51 6.39-.76l.14-.07v-.15C87.88 97.16 87.22 93 88 86c.65-6.64 4.16-10.12 5.87-10.12a1.57 1.57 0 011.19.46c1 1 1.14 3.73 1.07 8.12 0 .93 0 1.94-.09 3a67.72 67.72 0 00.07 9.3 4.09 4.09 0 002 3.28 4.81 4.81 0 002.26.51 13.55 13.55 0 005.34-1.55z"
        transform="translate(-39.15 -39.17)"
      ></path>
      <path
        fill="#fff"
        d="M132.29 71c-1.29-1.08-3.4-1-5.79.24a.71.71 0 00-.39.46 1 1 0 00.16.72 8.37 8.37 0 01.5 4.53 55.79 55.79 0 01-4 14.05c-.62-1.65-2.89-7.58-4.7-12.46-1.68-4.28-2.14-7-1.72-7.27a.55.55 0 00.18-.58.58.58 0 00-.62-.38c-4 .13-6.47 1-7.45 2.66-.75 1.23-.64 2.87.32 4.88 4.93 10.42 9.19 19.42 8.78 20.93-.57 2.14-2.56 4.91-5.2 7.23s-7.15 2.73-10 1.78l-.26-.1c-.17-.07-.34-.15-.48 0s-.13.34-.11.53c.38 3.93 2.39 5.93 6 5.93s6.79-1.14 11.3-6.67c5.62-6.81 11.57-19.69 14.07-28.22 1.53-5.15.53-7.33-.59-8.26z"
        transform="translate(-39.15 -39.17)"
      ></path>
      <path
        fill="#24336b"
        d="M157.34 117.46a26.12 26.12 0 0126.1-26.1h13.05a13.25 13.25 0 00-13.05-13h-13.05v-13h13.05a26.29 26.29 0 0126.1 26.1v26.1h-13v-13h-13.1a13 13 0 00-13.05 13zM230.13 109.81a25.1 25.1 0 01-7.55-18.45V52.22h13v13h13.05v13h-13v13a12.88 12.88 0 0013.05 13v13a25.26 25.26 0 01-18.55-7.65M261.73 117.46v-52.2a25.14 25.14 0 017.65-18.45 24.9 24.9 0 0118.45-7.64h13.05v13h-13.05a13 13 0 00-13.05 13h13.05v13h-13.05v39.15z"
        transform="translate(-39.15 -39.17)"
      ></path>
    </svg>
  );
};
