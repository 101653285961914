import React, { createContext, FC, useState } from 'react';
import { TutorialVideo } from '../components';
import { hideBodyScroll, addBodyScroll } from '../utils';

type TutorialVideoContextType = {
  isShown: boolean;
  setIsShown: (newIsShown: boolean) => void;
}

export const tutorialVideoContext = createContext<TutorialVideoContextType>({
  isShown: false,
  setIsShown: (newState) => console.log,
});

export const TutorialVideoProvider:FC = ({ children }) => {
  const [isVideoShown, setIsVideoShown] = useState(false);
  const { Provider } = tutorialVideoContext;

  const setIsShown = (newShown: boolean) => {
    setIsVideoShown(newShown);

    newShown
    ? hideBodyScroll()
    : addBodyScroll();
  };

  const providerValue = {
    setIsShown,
    isShown: isVideoShown,
  };

  return (
    <Provider value={providerValue}>
      {isVideoShown && <TutorialVideo setIsShown={setIsShown}/>}
      {children}
    </Provider>
  );
};
