import React, { FC } from 'react';

type Props = { color?: string };

export const EyeIcon: FC<Props> = ({ color = '#002855' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0)"
      >
        <path
          stroke={color}
          d="M.667 8S3.334 2.667 8 2.667C12.667 2.667 15.334 8 15.334 8S12.667 13.333 8 13.333C3.334 13.333.667 8 .667 8z"
        ></path>
        <path stroke={color} d="M8 10a2 2 0 10.001-3.999A2 2 0 008 10z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
