import React, { FC, memo } from 'react';
import { STEPS } from '../../../context';

export type HeaderStep = {
  title: string,
  description: string,
}

export type Props = {
  steps: Record<STEPS, HeaderStep>;
  activeStep: STEPS;
}

export const OrderWizardHeader:FC<Props> = memo(function OrderWizardHeader ({ steps, activeStep }) {

  return (
    <ul className="OrderWizardHeaderComponent__Steps">
      {Object.keys(steps).map((stepKey) => (
        <li
          key={stepKey}
          className={`OrderWizardHeaderComponent__Step ${stepKey === activeStep ? 'Active' : ''}`}
        >
          <span className="Step__Title">{steps[stepKey as STEPS].title}</span>
          <span className="Step__Description">{steps[stepKey as STEPS].description}</span>
        </li>
      ))}
    </ul>
  );
});
