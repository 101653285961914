import React from 'react';

export const DonationIcon = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 16.565 14.727">
    <path
      id="ico-donate"
      d="M-13.654-18.888H-3.532a.461.461,0,0,0,.46-.46V-25.79a.461.461,0,0,0-.46-.46H-13.654a.461.461,0,0,0-.46.46v6.442A.461.461,0,0,0-13.654-18.888Zm4.141-6.442h1.84v2.191l-.92-.46-.92.46Zm-3.681,0h2.761v3.681l1.84-.92,1.84.92V-25.33h2.761v5.521h-9.2ZM-.82-16.657a1.515,1.515,0,0,0-1.012-.385,1.59,1.59,0,0,0-.995.351L-4.6-15.273a.307.307,0,0,1-.193.066h-1.2a1.6,1.6,0,0,0,.138-.929,1.657,1.657,0,0,0-1.662-1.372h-4.15a2.751,2.751,0,0,0-1.656.552l-1.1.828h-2.223a.231.231,0,0,0-.23.23v.46a.231.231,0,0,0,.23.23h2.531l1.349-1.012a1.846,1.846,0,0,1,1.1-.368h4.219a.689.689,0,0,1,.69.69.689.689,0,0,1-.69.69H-9.973a.461.461,0,0,0-.46.46.461.461,0,0,0,.46.46h5.182a1.24,1.24,0,0,0,.768-.267l1.771-1.415a.676.676,0,0,1,.42-.15.589.589,0,0,1,.4.15.611.611,0,0,1-.026.937l-2.9,2.321a1.222,1.222,0,0,1-.768.267H-16.645a.231.231,0,0,0-.23.23v.46a.231.231,0,0,0,.23.23H-5.128A2.138,2.138,0,0,0-3.788-12l2.9-2.321a1.539,1.539,0,0,0,.575-1.15A1.545,1.545,0,0,0-.82-16.657Z"
      transform="translate(16.875 26.25)"
      fill="#fff"
    />
  </svg>
);
