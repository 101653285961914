import React, { FC, memo } from 'react';
import { Provider } from 'react-redux';
import { store } from '../../store';

export const StoreProvider: FC = memo(function StoreProvider({ children }) {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
});

export default StoreProvider;
