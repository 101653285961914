import React, { FC } from 'react';

export const PhoneIcon:FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#011328"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.333 14.1v2.5c.001.232-.046.462-.14.674a1.639 1.639 0 01-.4.56 1.65 1.65 0 01-1.276.432 16.478 16.478 0 01-7.192-2.558 16.261 16.261 0 01-5-5 16.484 16.484 0 01-2.558-7.225 1.663 1.663 0 011.658-1.816h2.5A1.666 1.666 0 017.592 3.1c.105.8.301 1.586.583 2.342A1.671 1.671 0 017.8 7.2L6.742 8.258a13.319 13.319 0 005 5L12.8 12.2a1.662 1.662 0 011.758-.375c.757.282 1.542.478 2.342.583a1.665 1.665 0 011.433 1.692z"
      ></path>
    </svg>
  );
};
