import React, { FC, memo } from 'react';

type Props = {
  close: () => void;
  className?: string;
  closeOnBackgroundClick? : boolean;
}

export const Modal: FC<Props> = memo(function Modal({ close, children, className = '', closeOnBackgroundClick = true }) {
  const handleWrapperClick = (e: any) => {
    if(!closeOnBackgroundClick) {
      return ;
    }

    const clickedElementClassName = e.target.getAttribute('class');

    if(clickedElementClassName?.includes('ModalComponent__Wrapper')) {
      close();
    }
  };

  return (
    <div className={`ModalComponent__Wrapper ${className}`} onClick={handleWrapperClick}>
      <div className="ModalComponent">
        <div className="ModalComponent__Content">
          {children}
        </div>
      </div>
    </div>
  );
});
