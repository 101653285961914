import React, { FC } from 'react';

type Props = { color?: string };

export const EyeOffIcon:FC<Props> = ({ color = '#002855' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0)">
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M9.413 9.413a2 2 0 11-2.827-2.826m5.374 5.373A6.708 6.708 0 018 13.333C3.333 13.333.667 8 .667 8A12.295 12.295 0 014.04 4.04l7.92 7.92zM6.6 2.827a6.056 6.056 0 011.4-.16C12.666 2.667 15.333 8 15.333 8c-.405.757-.887 1.47-1.44 2.127L6.6 2.827zM.667.667l14.666 14.666"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
