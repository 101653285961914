import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/modules/user';
import { capitalize, getInitials } from '../../../utils';
import { UserRoleLabel } from './UserRoleLabel/UserRoleLabel';

const formatUserName = (firstName: string, lastName: string): string => {
    const loverCaseF = firstName.toLocaleLowerCase();
    const loverCaseL = lastName.toLocaleLowerCase();

    return `${capitalize(loverCaseF)} ${capitalize(loverCaseL)}`;
};

export const UserProfileInformation: React.FC = () => {
    const { email, firstname, lastName, role } = useSelector(selectUser);
    const [emailName, domain] = email.split('@');

    return (
        <div className='UserProfileInformation'>
            <div className="UserDropdownComponent__Initials UserProfileInformation__Initials">
                {getInitials(firstname, lastName)}
            </div>
            <div className="UserProfileInformation__UserInformation">
                <div className="UserProfileInformation__UserName" data-testid='user-name'>{formatUserName(firstname, lastName)}</div>
                <div className="UserProfileInformation__UserEmail" data-testid='user-email'>
                    <span>
                        {emailName}
                    </span>
                    <span>
                        @{domain}
                    </span>
                </div>
                <div className="UserProfileInformation__UserRole">
                    <UserRoleLabel userRole={role}></UserRoleLabel>
                </div>
            </div>
        </div>
    );
};