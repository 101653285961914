import React, { FC, memo } from 'react';

type Props = {
  style?: Record<string, string | number>;
  className?: string;
};

export const Container: FC<Props> = memo(function Container ({ children, className='', style = {} }) {
  return (
    <div className="Container__Wrapper">
      <div className={`Container ${className}`} style={style}>
        {children}
      </div>
    </div>
  );
});

export default Container;
