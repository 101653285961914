import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { getNotificationsList, getStorageSelectedAccounts } from '../../../services';
import { FETCH_STATUS, NotificationListSuccessDto } from '../../../types';
import { workerLogout } from '../user';
import { GET_NOTIFICATIONS, setNotifications } from './app.actions';

export function* watchGetNotifications(): Iterator<ForkEffect> {
  try {
    yield takeEvery(GET_NOTIFICATIONS, workerGetNotifications);
  } catch (e) {
    console.error(e);
  }
}

export function* workerGetNotifications() {
  try {
    const response = (yield call(getNotificationsList, { accounts: getStorageSelectedAccounts(), page: 1, perPage: 20 })) as unknown as NotificationListSuccessDto;

    if (response?.status !== FETCH_STATUS.SUCCESS) {
      return workerLogout();
    }

    const { data, unread } = response;

    yield put(setNotifications({ data, unread }));
  } catch(e) {
    console.error(e);
  }
}
