import React, { FC, useEffect, useMemo } from 'react';
import { StoreProvider } from './components';
import { OrderWizardProvider, TutorialVideoProvider } from './context';
import { AppRouter, PATHS } from './router';
import { reportError } from './services';
import { envConfig } from './config';

let appErrorObject: Record<string, string> = {
  message: '',
  stack: '',
  lastClickedElement: '',
  url: '',
};

const onStorage = () => {
  if (window.location.pathname.includes(PATHS.LOGIN)) {
    return;
  }

  window.location.reload();
};

const onAppError = ({ message, error: { stack } }: ErrorEvent) => {
  appErrorObject = {
    message,
    stack,
    lastClickedElement: '',
    url: window.location.href,
  };
};

const onAppClick = (event: any) => {
  appErrorObject.lastClickedElement = event.target.outerHTML;

  if(appErrorObject.message) {
    reportError(appErrorObject);
  }
};

const App: FC = () => {
  const safariBrowser = useMemo(() => !!(window as any).safari , []);
  if(safariBrowser) {
    document.body.classList.add('Safari');
  }

  useEffect(() => {
    if(!envConfig.isDev) {
      return;
    }

    window.addEventListener('storage', onStorage);
    window.addEventListener('error', onAppError);
    window.addEventListener('click', onAppClick);

    return () => {
      window.removeEventListener('storage', onStorage);
      window.removeEventListener('error', onAppError);
      window.removeEventListener('click', onAppClick);
    };
  }, []);


  return (
    <StoreProvider>
      <TutorialVideoProvider>
        <OrderWizardProvider>
          <AppRouter />
        </OrderWizardProvider>
      </TutorialVideoProvider>
    </StoreProvider>
  );
};

export default App;
