import { DEVICE_TYPE } from '../../context';
import { OrderStatus } from '../dto';

export enum SEARCH_TYPES {
  SERIE_NUMBER = 'Serial',
  COMMAND_NUMBER = 'Order',
  TAG = 'Tag',
}

export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ORDER_DOCUMENT_TYPE {
  GUIDE = 'condition',
  TEMPLATE = 'upload',
  FINANCIAL = 'financial',
}

export enum GDPR_TYPE {
  MENTIONS = 'mentions_legales.html',
  POLITICAL = 'politique_confidentialite.html',
}

export interface PaginationPayload {
  page: number;
  perPage: number;
  sortBy: string;
  asc?: string;
  selectedAccounts: number[];
}

export type GetOrderListPayload = PaginationPayload;

export type GetOrderDevicesPagePayload = PaginationPayload & {
  orderId: number;
};

export type DownloadDocumentPayload = {
  orderId: number;
  documentType: string;
};

export type OrderCreateContact = {
  email: string;
  phone: string;
  genre: string;
  fonction: string;
  commentaire: string;
  nom: string;
  prenom: string;
  priority: string;
};

export type OrderDevices = {
  description: string;
  famille: DEVICE_TYPE;
  quantité: number;
};

export type OrderCreatePayload = {
  contact: OrderCreateContact[];
  commande: OrderDevices[];
  country: string;
  Code_Fournisseur: number;
  code_postal: string;
  ville: string;
  rue: string;
  complement_adresse: string;
  condition_acces_au_batiment: string;
  date_de_collecte_souhaite: string;
  le_materiel_est_palettise: boolean;
  le_materiel_est_pas_conditionne: boolean;
  nombre_de_palettes: number;
  type_effacement_souhaite: string;
  je_souhaite_un_releve_code_parc: boolean;
  prestation_commentaire: string;
  autre_preciser: string;
};

export type OrderCreateFilePayload = {
  id: number;
  document64: string;
  userAccount: number[];
  extention: string;
};

export type SearchAuditDevicePayload = Omit<GetOrderDevicesPagePayload, 'sortBy' | 'asc'> & {
  searchString: string;
};

export type SearchOrderPayload = {
  category: SEARCH_TYPES;
  searchString: string;
  page: number;
  perPage: number;
  accounts: number[];
};

export type FilteredOrdersPaginationPayload = PaginationPayload & {
  byYears: number[];
  byStatuses: OrderStatus[];
};

export type OrdersErrorsPayload = {
  orders: number[];
  accounts: number[];
};
