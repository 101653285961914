const YEARS_LENGTH = 4;

export const generateYearList = () => {
  const currentYear = (new Date()).getFullYear();

  return Array.from({ length: YEARS_LENGTH }).map((item, index) => {
    const yearItem = currentYear - (YEARS_LENGTH - (index + 1));

    return { id: yearItem, name: `Année ${yearItem}` };
  }).reverse();
};
