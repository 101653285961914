import React, { FC, memo } from 'react';
import { EmailIcon, PhoneIcon } from '../../icons';

type Props = {
  phone: string;
  name: string;
  email: string;
}

export const ContactInfo: FC<Props> = memo(function ContactInfo ({ name, phone, email }) {
  return (
    <div className="ContactInfoComponent">
      <div className="ContactInfoComponent__ContactName">
        <span className="ContactInfoComponent__Question">Une question, une remarque ?</span>
        <span className="ContactInfoComponent__Name">
          Votre contact est à votre écoute
        </span>
      </div>
      <div className="ContactInfoComponent__Image">
        <img src="/media/avatar-placeholder.png" alt="contact_photo"/>
      </div>
      <div className="ContactInfoComponent__Data">
        <div className="ContactInfoComponent__Field">
          <span className="ContactInfoComponent__Text">{name}</span>
        </div>
        <div className="ContactInfoComponent__Field">
          <div className="ContactInfoComponent__Icon"><PhoneIcon /></div>
          <a className="ContactInfoComponent__Text" href={`tel:${phone}`}>{phone}</a>
        </div>
        <div className="ContactInfoComponent__Field" style={{marginTop: '15px'}}>
          <div className="ContactInfoComponent__Icon"><EmailIcon /></div>
          <a className="ContactInfoComponent__Text" href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    </div>
  );
});
