import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { NOT_FOUND_ORDER } from '../../../constants';
import { getFilteredOrders, getOrderAuditDevices, getOrderAuditStats, getOrderById, IdWithAccounts } from '../../../services';
import {
  ActionPayload,
  FilteredOrdersPaginationPayload,
  GetOrderDevicesPagePayload,
  isExpiredTokenResponse,
  isUnexpectedTokenResponse,
  LOAD_ENDPOINTS
} from '../../../types';
import {
  isNotFoundResponse,
  isSuccessOrderList,
  OrderAuditDto,
  OrderAuditStatsDto,
  OrderResponseDto,
  OrdersDto
} from '../../../types/dto';
import { setError, startLoad, stopLoad } from '../app';
import { workerLogout } from '../user';
import {
  getOrderAuditDevicePageSucces,
  getOrderAuditStatsSuccess,
  getOrdersSuccess,
  getOrderSuccess,
  GET_ORDER,
  GET_ORDERS,
  GET_ORDER_AUDIT,
  GET_ORDER_AUDIT_DEVICES_PAGE,
  GET_ORDER_AUDIT_STATS
} from './orders.actions';

export function* watchGetOrders(): Iterator<ForkEffect> {
  try {
    yield takeEvery(GET_ORDERS, workerGetOrders);
  } catch (e) {
    console.error(e);
  }
}

export function* watchGetOrder(): Iterator<ForkEffect> {
  try {
    yield takeEvery(GET_ORDER, workerGetOrder);
  } catch (e) {
    console.error(e);
  }
}

export function* watchGetAuditOrder(): Iterator<ForkEffect> {
  try {
    yield takeEvery(GET_ORDER_AUDIT, workerGetOrderAudit);
  } catch (e) {
    console.error(e);
  }
}

export function* watchGetAuditStatsOrder(): Iterator<ForkEffect> {
  try {
    yield takeEvery(GET_ORDER_AUDIT_STATS, workerGetOrderAuditStats);
  } catch (e) {
    console.error(e);
  }
}

export function* watchGetOrderAuditDevices(): Iterator<ForkEffect> {
  try {
    yield takeEvery(GET_ORDER_AUDIT_DEVICES_PAGE, workerGetOrderAuditDevicesPage);
  } catch (e) {
    console.error(e);
  }
}

export function* workerGetOrders(getOrderListAction: ActionPayload<FilteredOrdersPaginationPayload>): Iterator<any> {
  try {
    yield put(startLoad(LOAD_ENDPOINTS.GET_ORDERS));

    const ordersResponse = (yield call(getFilteredOrders, getOrderListAction.payload)) as unknown as OrdersDto;

    if(isSuccessOrderList(ordersResponse)) {
      yield put(getOrdersSuccess(ordersResponse.data));
    } else {
      if (isExpiredTokenResponse(ordersResponse) || isUnexpectedTokenResponse(ordersResponse)) {
        yield workerLogout();
      }
    }

    yield put(stopLoad(LOAD_ENDPOINTS.GET_ORDERS));
  } catch(e) {
    console.error(e);
  }
}

export function* workerGetOrder(getOrderAction: ActionPayload<{ id: number, selectedAccounts: number[] }>): Iterator<any> {
  try {
    yield put(startLoad(LOAD_ENDPOINTS.GET_ORDER_BY_ID));
    const ordersResponse = (yield call(getOrderById, getOrderAction.payload)) as unknown as OrderResponseDto;

    if(isNotFoundResponse(ordersResponse)) {
      yield put (setError(NOT_FOUND_ORDER));
      yield put(stopLoad(LOAD_ENDPOINTS.GET_ORDER_BY_ID));

      return ;
    }

    if(isExpiredTokenResponse(ordersResponse)) {
      yield workerLogout();
    } else {
      yield put(getOrderSuccess(ordersResponse.data));
    }

    yield put(stopLoad(LOAD_ENDPOINTS.GET_ORDER_BY_ID));
  } catch(e) {
    console.error(e);
  }
}

export function* workerGetOrderAuditStats(getOrderAuditStatsAction: ActionPayload<IdWithAccounts>): Iterator<any> {
  yield put(startLoad(LOAD_ENDPOINTS.GET_ORDER_AUDIT_STATS));

  const { payload } = getOrderAuditStatsAction;
  const orderStats = (yield call(getOrderAuditStats, payload)) as unknown as OrderAuditStatsDto;

    if(isExpiredTokenResponse(orderStats)) {
      yield put(stopLoad(LOAD_ENDPOINTS.GET_ORDER_AUDIT_STATS));
      return yield workerLogout();
    }

    const { data: stats } = orderStats;

    yield put(getOrderAuditStatsSuccess({ orderId: payload.id, stats }));
    yield put(stopLoad(LOAD_ENDPOINTS.GET_ORDER_AUDIT_STATS));
}

export function* workerGetOrderAuditDevicesPage(getOrderDevicesAction: ActionPayload<GetOrderDevicesPagePayload>): Iterator<any> {
  try {
    yield put(startLoad(LOAD_ENDPOINTS.GET_ORDER_AUDIT_DEVICES));
    const { orderId } = getOrderDevicesAction.payload;
    const orderAudit = (yield call(getOrderAuditDevices, getOrderDevicesAction.payload)) as unknown as OrderAuditDto;

    if(isExpiredTokenResponse(orderAudit)) {
      yield put(stopLoad(LOAD_ENDPOINTS.GET_ORDER_AUDIT_DEVICES));

      return yield workerLogout();
    }

    const { data: devices } = orderAudit;

    yield put(getOrderAuditDevicePageSucces({ orderId, devices}));
    yield put(stopLoad(LOAD_ENDPOINTS.GET_ORDER_AUDIT_DEVICES));
  } catch(e) {
    console.error(e);
  }
}

export function* workerGetOrderAudit(getOrderAuditAction: ActionPayload<GetOrderDevicesPagePayload>): Iterator<any> {
  try {
    const { payload: { orderId, selectedAccounts } } = getOrderAuditAction;

    yield put(startLoad(LOAD_ENDPOINTS.GET_ORDER_BY_ID));
    yield put(startLoad(LOAD_ENDPOINTS.GET_ORDER_AUDIT_STATS));
    yield put(startLoad(LOAD_ENDPOINTS.GET_ORDER_AUDIT_DEVICES));
    yield workerGetOrder({ type: GET_ORDER, payload: { id: orderId, selectedAccounts } });
    yield workerGetOrderAuditStats({ type: GET_ORDER_AUDIT_STATS, payload: { id: orderId, selectedAccounts } });
    yield workerGetOrderAuditDevicesPage(getOrderAuditAction);
  } catch(e) {
    console.error(e);
  }
}
