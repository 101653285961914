import React, { FC } from 'react';

export const BellIcon:FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#011328"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 8a6.003 6.003 0 00-1.757-4.243 6.005 6.005 0 00-8.486 0A6.005 6.005 0 006 8c0 7-3 9-3 9h18s-3-2-3-9zm-4.27 13a2.002 2.002 0 01-2.728.73 2.002 2.002 0 01-.732-.73"
      ></path>
    </svg>
  );
};
