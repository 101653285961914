import React, { FC, useContext, memo } from 'react';
import { Dropdown, Input } from '../..';
import { computeContactFieldError, CONTACT_FUNCTION, initialContact, orderWizardContext, STEPS } from '../../../context';

const contactFunctions = [
  { name: CONTACT_FUNCTION.ADMINISTRATOR, id: CONTACT_FUNCTION.ADMINISTRATOR },
  { name: CONTACT_FUNCTION.COMERCIAL_ASSISTANT, id: CONTACT_FUNCTION.COMERCIAL_ASSISTANT },
  { name: CONTACT_FUNCTION.PROJECT_CHEF, id: CONTACT_FUNCTION.PROJECT_CHEF },
  { name: CONTACT_FUNCTION.COMMERCIAL, id: CONTACT_FUNCTION.COMMERCIAL },
  { name: CONTACT_FUNCTION.COMPTA_FURNISOR, id: CONTACT_FUNCTION.COMPTA_FURNISOR },
  { name: CONTACT_FUNCTION.COMPATIBILITE, id: CONTACT_FUNCTION.COMPATIBILITE },
  { name: CONTACT_FUNCTION.CONTROLOR_GESTION, id: CONTACT_FUNCTION.CONTROLOR_GESTION },
  { name: CONTACT_FUNCTION.DIRECTOR, id: CONTACT_FUNCTION.DIRECTOR },
  { name: CONTACT_FUNCTION.DIRECTOR_ACHATS, id: CONTACT_FUNCTION.DIRECTOR_ACHATS },
  { name: CONTACT_FUNCTION.DIRECTOR_ADJOINT, id: CONTACT_FUNCTION.DIRECTOR_ADJOINT },
  { name: CONTACT_FUNCTION.FINAL_ADMINISTRATIVE_DIRECTOR, id: CONTACT_FUNCTION.FINAL_ADMINISTRATIVE_DIRECTOR },
  { name: CONTACT_FUNCTION.COMMERCIAL_DIRECTOR, id: CONTACT_FUNCTION.COMMERCIAL_DIRECTOR },
  { name: CONTACT_FUNCTION.FINANCE_DIRECTOR, id: CONTACT_FUNCTION.FINANCE_DIRECTOR },
  { name: CONTACT_FUNCTION.TEHNICAL_DIRECTOR, id: CONTACT_FUNCTION.TEHNICAL_DIRECTOR },
  { name: CONTACT_FUNCTION.GERANT, id: CONTACT_FUNCTION.GERANT },
  { name: CONTACT_FUNCTION.RESPONSABLE_ACHATS, id: CONTACT_FUNCTION.RESPONSABLE_ACHATS },
  { name: CONTACT_FUNCTION.RESPONSABLE_BROKE, id: CONTACT_FUNCTION.RESPONSABLE_BROKE },
  { name: CONTACT_FUNCTION.RESPONSABLE_FURNISOR, id: CONTACT_FUNCTION.RESPONSABLE_FURNISOR },
  { name: CONTACT_FUNCTION.RESPONSABLE_SAV, id: CONTACT_FUNCTION.RESPONSABLE_SAV },
  { name: CONTACT_FUNCTION.SALARIE, id: CONTACT_FUNCTION.SALARIE },
  { name: CONTACT_FUNCTION.SERVICE_ACHATS, id: CONTACT_FUNCTION.SERVICE_ACHATS },
  { name: CONTACT_FUNCTION.TRANSPORT, id: CONTACT_FUNCTION.TRANSPORT },
];

export const SecondStep:FC = memo(function SecondStep () {
  const { setProperty, hasError, setErrors, validateStep, order : { [STEPS.STEP2]: { contacts } } } = useContext(orderWizardContext);

  const changeContact = (contactIndex: number, field: string, value: string) => {
    const changedContact = contacts.map((contact, index) => {
      if(index !== contactIndex) {
        return contact;
      }

      return { ...contact, [field]: value };
    });

    setProperty(STEPS.STEP2, 'contacts', changedContact as unknown as string);
    setErrors((errors) => errors.filter((errField) => errField !== computeContactFieldError(contactIndex, field)));
  };

  const addContact = () => {
    if(!validateStep(STEPS.STEP2)) {
      return;
    }

    const newContactsList = [...contacts, initialContact];

    return setProperty(STEPS.STEP2, 'contacts', newContactsList as unknown as string);
  };

  const deleteSecondContact = () => {
    return setProperty(STEPS.STEP2, 'contacts', [contacts[0]] as unknown as string);
  };

  const renderContacts = () => {
    return contacts.map(({
      comment,
      email,
      name,
      surname,
      phone,
      gender,
      contactFunction,
    },
    index
    ) => {
      
      const handleErrorField = (field: string, errorText= 'Ce champs est obligatoire' ) => {
        if(!hasError(computeContactFieldError(index, field))) {
          return '';
        }

        return errorText;
      };

      return (
        <div id={`contact__${index}`} key={index}>
          <div className="SecondStepComponent__Content">
            <div className="SecondStepComponent__Column">
              <Dropdown
                label="Civilité"
                value={gender}
                items={[{ id: 'M', name: 'M' }, { id: 'Mme', name: 'Mme' }]}
                onChange={(newGender) => changeContact(index, 'gender', newGender as string)}
              />
              <Input
                id={`name__${index}`}
                label="Nom*"
                value={name}
                error={handleErrorField('name')}
                onChange={(newName) => changeContact(index, 'name', newName as string)}
              />
              <Input
                id={`surname__${index}`}
                label="Prénom*"
                value={surname}
                error={handleErrorField('surname')}
                onChange={(newSurname) => changeContact(index, 'surname', newSurname as string)}
              />
            </div>
            <div className="SecondStepComponent__Column">
              <Input
                id={`phone__${index}`}
                label="Téléphone fixe ou mobile*"
                value={phone}
                error={handleErrorField('phone', 'Numéro de téléphone non valable')}
                onChange={(newPhone) => {
                  const phoneWithoutSpaces = (`${newPhone}`).split(' ').join('');
                  const onlyNumbers = /^\d+$/.test(phoneWithoutSpaces);
  
                  if(
                    (phoneWithoutSpaces).length >= 11
                    || (phoneWithoutSpaces && !onlyNumbers)
                  ) {
                    return;
                  }
  
                  const formattedPhone = (`${phoneWithoutSpaces}`.match(/.{1,2}/g) || []).join(' ');
  
                  return changeContact(index, 'phone', formattedPhone);
                }}
              />
              <Input
                id={`email__${index}`}
                label="Email*"
                value={email}
                error={handleErrorField('email', 'Email non valable')}
                onChange={(newEmail) => {
                  if((newEmail as string).includes(' ')) {
                    return;
                  }
  
                  changeContact(index, 'email', newEmail as string);
                }}
              />
              <Dropdown
                label="Fonction"
                value={contactFunction}
                items={contactFunctions}
                onChange={(newFunction) => changeContact(index, 'contactFunction', newFunction as string)}
              />
            </div>
          </div>
  
          <Input
            id={`comment__${index}`}
            label="Commentaire"
            className="CommentInput"
            value={comment}
            onChange={(newComment) => changeContact(index, 'comment', newComment as string)}
          />
        </div>
      );
    });
  };

  return (
    <div className="SecondStepComponent">
      <h3 className="SecondStepComponent__Title">Coordonnées du contact sur site </h3>
      <span className="SecondStepComponent__SubTitle">On se charge du reste</span>
      {renderContacts()}
      {contacts.length < 2 && (
        <button className="SecondStepComponent__AddButton" onClick={() => addContact()}>Ajouter un second contact</button>
      )}
      {contacts.length == 2 && (
        <button className="SecondStepComponent__DeleteButton" onClick={() => deleteSecondContact()}>Supprimer le second contact</button>
      )}
    </div>
  );
});
