import React from 'react';

export const CsvFileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        stroke="#011328"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.334 2.667H8a2.665 2.665 0 00-2.667 2.666v21.334A2.666 2.666 0 008 29.333h16a2.667 2.667 0 002.666-2.667V12l-9.332-9.334z"
      ></path>
      <path
        stroke="#011328"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.334 2.667V12h9.333"
      ></path>
      <rect
        width="21.6"
        height="11.2"
        x="11.2"
        y="14.4"
        fill="#EBF0FC"
        rx="3"
      ></rect>
      <path
        fill="#012D4E"
        d="M17.938 22.804c-.45 0-.84-.11-1.168-.328a2.163 2.163 0 01-.756-.932c-.179-.402-.268-.875-.268-1.416 0-.57.095-1.05.284-1.436.19-.39.45-.683.78-.88.333-.2.71-.3 1.128-.3.563 0 1.01.14 1.344.42.333.277.555.639.664 1.084l-.812.156a1.514 1.514 0 00-.456-.708 1.103 1.103 0 00-.74-.268c-.25 0-.48.072-.688.216-.208.144-.375.36-.5.648-.125.286-.188.642-.188 1.068 0 .395.052.741.156 1.04.104.296.257.527.46.692.203.166.456.248.76.248.37 0 .663-.099.876-.296.213-.2.32-.46.32-.78h.82c0 .373-.084.692-.252.956a1.612 1.612 0 01-.7.604c-.301.142-.656.212-1.064.212zm4.857 0c-.347 0-.666-.061-.956-.184a1.669 1.669 0 01-.704-.556c-.176-.245-.268-.552-.276-.92h.744c0 .222.053.403.16.544.11.142.253.247.432.316.178.067.376.1.592.1.219 0 .412-.033.58-.1a.93.93 0 00.388-.268.585.585 0 00.14-.384c0-.195-.06-.35-.18-.468-.12-.12-.303-.221-.548-.304l-.948-.352c-.408-.152-.719-.327-.932-.524-.213-.2-.32-.477-.32-.832 0-.402.157-.73.472-.98.314-.253.757-.38 1.328-.38.541 0 .961.125 1.26.376.301.25.473.592.516 1.024h-.748a.801.801 0 00-.308-.52c-.165-.128-.405-.192-.72-.192-.304 0-.541.067-.712.2a.601.601 0 00-.256.496c0 .165.053.297.16.396.11.099.279.188.508.268l1.08.396c.37.13.66.307.868.528.208.221.312.503.312.844 0 .288-.075.543-.224.764a1.5 1.5 0 01-.656.524c-.288.125-.639.188-1.052.188zm4.741-.924l1.42-4.276h.804L27.972 22.7h-.796l-1.768-5.096h.808l1.408 4.276h-.088z"
      ></path>
    </svg>
  );
};
