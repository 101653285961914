import React, { FC, memo } from 'react';
import { Button } from '..';
import { AttentionConfirmModalIcon } from '../../icons';
import { ButtonColor } from '../Button';
import { Modal } from '../Modal';

type Props = {
  title: string | JSX.Element;
  text: string | JSX.Element;
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  subTitle?: string;
}

export const ConfirmModal: FC<Props> = memo(function ConfirmModal ({
  title,
  text,
  onCancel,
  onConfirm,
  confirmLabel,
  cancelLabel
}) {
  return (
    <Modal close={onCancel}>
      <>
        <AttentionConfirmModalIcon />
        <div className="ConfirmModalComponent__Content">
          <h3 className="ConfirmModalComponent__Title">{title}</h3>
          <span className="ConfirmModalComponent__SubTitle"></span>
          <span className="ConfirmModalComponent__Text">{text}</span>
        </div>
        <div className="ConfirmModalComponent__Buttons">
          <Button
            color={ButtonColor.IMPORTANT}
            onClick={onConfirm}
            label={confirmLabel}
          />
          <Button
            color={ButtonColor.SECUNDARY}
            onClick={onCancel}
            label={cancelLabel}
          />
        </div>
      </>
    </Modal>
  );
});
