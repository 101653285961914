import React, { FC } from 'react';

export const AnullerChevronIcon:FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke="#002855"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.334 12.791l-4-4 4-4"
      ></path>
    </svg>
  );
};
