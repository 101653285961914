import React, { FC, ReactNode, memo } from 'react';

export enum ButtonColor {
  TEXT = 'Text',
  PRIMARY = 'Primary',
  SECUNDARY = 'Secundary',
  IMPORTANT = 'Important',
}

type Props = {
  label: string;
  onClick: () => void;
  className?: string;
  color?: ButtonColor;
  disabled?: boolean;
  icon?: ReactNode;
  isLoading?: boolean;
};

export const Button: FC<Props> = memo(function MyComponent ({
  label,
  onClick,
  className = '',
  color = ButtonColor.PRIMARY,
  disabled,
  icon,
  isLoading,
}) {
  return (
    <button
      disabled={disabled}
      className={`ButtonComponent ${color} ${className}`}
      onClick={onClick}
    >
      {icon && <span className="ButtonComponent__Icon">{icon}</span>}
      <span className="ButtonComponent__Label">{label}</span>
      {isLoading && <span className="ButtonComponent__Loader"></span>}
    </button>
  );
});
