import React, { ChangeEvent, FC, useContext, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { Button, CheckBox, Dropdown, Input, InputType, Loader } from '../..';
import {
  ADDED_DEVICES_TYPE,
  DEVICE_TYPE,
  STEPS,
  initialDevice,
  ManualDeviceItem,
  orderWizardContext,
  computeDeviceFieldError,
} from '../../../context';
import { CreateOrderImportedFileIcon, DownloadTemplateIcon, UploadDevicesFileIcon } from '../../../icons';
import { fetchDevicesTemplate } from '../../../services';
import { selectUserAccounts } from '../../../store/modules/user';
import { ORDER_DOCUMENT_TYPE } from '../../../types';
import { downloadFile } from '../../../utils';

const deviceTypes = [
  { id: DEVICE_TYPE.CENTRAL_UNIT, name: DEVICE_TYPE.CENTRAL_UNIT },
  { id: DEVICE_TYPE.MOBILITY, name: DEVICE_TYPE.MOBILITY },
  { id: DEVICE_TYPE.PRINTER, name: DEVICE_TYPE.PRINTER },
  { id: DEVICE_TYPE.PC_PORTABLE, name: DEVICE_TYPE.PC_PORTABLE },
  { id: DEVICE_TYPE.SERVER, name: DEVICE_TYPE.SERVER },
  { id: DEVICE_TYPE.SCREEN, name: DEVICE_TYPE.SCREEN },
  { id: DEVICE_TYPE.BAIE, name: DEVICE_TYPE.BAIE },
  { id: DEVICE_TYPE.OTHER_SMALL, name: DEVICE_TYPE.OTHER_SMALL },
  { id: DEVICE_TYPE.OTHER_BIG, name: DEVICE_TYPE.OTHER_BIG },

];

type DeviceRow = ManualDeviceItem & {
  rowIndex: number;
  onRowChange: (rowIndex: number, key: string, value: string ) => void;
  errors: string[];
  hasError: (field: string) => boolean,
  deleteDevice: null | ((index: number) => void),
}

const ManualDeviceRow: FC<DeviceRow> = memo(function ManualDeviceRow ({
  type,
  rowIndex,
  description,
  amount,
  onRowChange,
  hasError,
  deleteDevice
}) {
  return (
    <div className="ThirdStepComponent__DeviceRow" id={`Device_${rowIndex}`}>
      <Dropdown
        items={deviceTypes}
        value={type}
        className='Type Input'
        onChange={(newType) => onRowChange(rowIndex, 'type', newType as string)}
      />
      <Input
        id={`description__${rowIndex}`}
        value={description}
        placeholder="Description"
        error={hasError(computeDeviceFieldError(rowIndex, 'description')) ? 'Description manquant' : ''}
        className='Description Input'
        onChange={(newDescription) => onRowChange(rowIndex, 'description', newDescription as string)}
      />
      <Input
        id={`amount__${rowIndex}`}
        className='Amount Input'
        error={hasError(computeDeviceFieldError(rowIndex, 'amount')) ? 'Quantité manquant' : ''}
        type={InputType.NUMBER}
        value={amount}
        onChange={(newAmount) => {
          if (newAmount < 1) {
            return;
          }

          return onRowChange(rowIndex, 'amount', +newAmount as unknown as string);
        }}
      />
      {deleteDevice && <button className="ThirdStepComponent__DeleteButton" onClick={() => deleteDevice(rowIndex)}></button>}
    </div>
  );
});

export const ThirdStep:FC = memo(function ThirdStep () {
  const [loading, setLoading] = useState(false);

  const {
    setProperty,
    hasError,
    errors,
    setErrors,
    validateStep,
    order : { [STEPS.STEP3]: { listAddedType, [ADDED_DEVICES_TYPE.MANUAL]: devicesList, [ADDED_DEVICES_TYPE.IMPORTED]: deviceFilesMap } },
  } = useContext(orderWizardContext);
  const selectedAccounts = useSelector(selectUserAccounts);

  const onRowChange = (deviceIndex: number, field: string, value: string) => {
    const changedDevice = (devicesList || []).map((device, index) => {

      if (index !== deviceIndex) {
        return device;
      }

      return { ...device, [field]: value };
    });

    setErrors((activeErrors) => activeErrors.filter((errField) => errField !== computeDeviceFieldError(deviceIndex, field)));
    setProperty(STEPS.STEP3, ADDED_DEVICES_TYPE.MANUAL, changedDevice as unknown as string);
  };

  const addDevice = () => {
    if(!validateStep(STEPS.STEP3)) {
      return;
    }

    const newDevicesList = [...devicesList as ManualDeviceItem[], initialDevice];

    setProperty(STEPS.STEP3, ADDED_DEVICES_TYPE.MANUAL, newDevicesList as unknown as string);
  };

  const deleteDevice = (rowIndex: number) => {
    const updatedDevicesList = (devicesList || []).filter((_, index) => index !== rowIndex);

    setProperty(STEPS.STEP3, ADDED_DEVICES_TYPE.MANUAL, updatedDevicesList as unknown as string);
  };

  const downloadTemplate = (type: ORDER_DOCUMENT_TYPE = ORDER_DOCUMENT_TYPE.TEMPLATE) => {
    setLoading(true);

    return fetchDevicesTemplate({ selectedAccounts, type })
    .then(({ data, metadata }) => {
      downloadFile(data, metadata.name);
      setLoading(false);
    });
  };

  const importFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.length) {
      return;
    }

    setLoading(true);

    const {
      target: { files },
    } = e;
    const file = files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setProperty(
        STEPS.STEP3,
        ADDED_DEVICES_TYPE.IMPORTED,
        { [files[0].name]: (reader.result as Buffer).toString('utf8').split('base64,')[1] } as unknown as string,
      );
      setLoading(false);
    };
  };

  const renderItemsInsertMode = () => {
    if (!listAddedType) {
      return;
    }

    if (listAddedType === ADDED_DEVICES_TYPE.MANUAL) {
      return (
        <>
          <h3 className="ThirdStepComponent__Title">Entrer manuellement les données</h3>
          <span className="ThirdStepComponent__SubTitle">Nous indiquer les modèles ou le type d’équipements <br />(unité centrale, écran, portable ….)</span>
          <div className="ThirdStepComponent__DeviceRow Header">
            <span className="Input Type">Famille</span>
            <span className="Input Description">Description</span>
            <span className="Input Amount">Quantité</span>
          </div>
          {
            devicesList?.map(( item, rowIndex) => (
              <ManualDeviceRow
                key={rowIndex} {...item}
                rowIndex={rowIndex}
                errors={errors}
                hasError={hasError}
                onRowChange={onRowChange}
                deleteDevice={devicesList?.length > 1 ? deleteDevice : null}
              />
            ))
          }
          <button className="ThirdStepComponent__AddButton" onClick={() => addDevice()}>Ajouter une ligne</button>
        </>
      );
    }

    return (
      <div>
        {loading && <Loader />}
        {hasError(ADDED_DEVICES_TYPE.IMPORTED) && (
          <span className="ThirdStepComponent__Error">
            Merci d&apos;importer un fichier ou choisir de rentrer manuellement votre commande.
          </span>
        )}
        <h3 className="ThirdStepComponent__Title">Importer un document</h3>
        <div className="ThirdStepComponent__FilesButtons" >
          <label className="FileButton" htmlFor="importedFile">
            <div className="FileButton__Icon"><UploadDevicesFileIcon /></div>
            <div className="FileButton__Text">
              <span className="FileButton__Title">Importer ma liste</span>
              <span className="FileButton__Subtitle">Excel, sheet, PDF</span>
            </div>
            <input
              hidden
              type="file"
              id="importedFile"
              accept=".xlsx, .xls, .csv, .pdf"
              onChange={importFile}
            />
          </label>

          <button className="FileButton" id="downloadTemplate" onClick={() => downloadTemplate()}>
            <div className="FileButton__Icon"><DownloadTemplateIcon /></div>
            <div className="FileButton__Text">
              <span className="FileButton__Title">Télécharger le template</span>
              <span className="FileButton__Subtitle">Prêt à remplir !</span>
            </div>
          </button>
        </div>
        <div className="ThirdStepComponent__FileList">
          {deviceFilesMap && Object.keys(deviceFilesMap)
            .map((fileName) => (
              <div key={fileName} className="File">
                <span className="File__Left">
                  <CreateOrderImportedFileIcon />
                  <span className="File__Title">{fileName}</span>
                </span>
                <button
                  className="File__Delete"
                  onClick={() => setProperty(STEPS.STEP3, ADDED_DEVICES_TYPE.IMPORTED, undefined as unknown as string)}
                >
                  +
                </button>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="ThirdStepComponent">
      <h3 className="ThirdStepComponent__Title">Faites-nous parvenir votre liste de matériel</h3>
      <span className="ThirdStepComponent__SubTitle">On se charge du reste</span>

      <div className="ThirdStepComponent__Tutorial" style={{backgroundImage: 'url("/media/createOrder-step3-demo.png")'}}>
        <div>
          <h4 className="ThirdStepComponent__Tutorial__Title">Comment ça marche ?</h4>
          <span className="ThirdStepComponent__Tutorial__SubTitle">On vous explique le fonctionnement</span>
        </div>
          <Button
            label="Voir le tutoriel"
            onClick={() => downloadTemplate(ORDER_DOCUMENT_TYPE.GUIDE)} />
      </div>
      <div className="ThirdStepComponent__Checkboxes">
        <CheckBox
          label="J’entre manuellement ma commande"
          id="MANUAL"
          className="ThirdStepComponent__Checkboxes__Item"
          checked={listAddedType === ADDED_DEVICES_TYPE.MANUAL}
          onChange={() => setProperty(STEPS.STEP3, 'listAddedType', ADDED_DEVICES_TYPE.MANUAL)}
        />
        <CheckBox
          label="J’importe un document"
          id="IMPORTED"
          className="ThirdStepComponent__Checkboxes__Item"
          checked={listAddedType === ADDED_DEVICES_TYPE.IMPORTED}
          onChange={() => setProperty(STEPS.STEP3, 'listAddedType', ADDED_DEVICES_TYPE.IMPORTED)}
        />
      </div>
      {renderItemsInsertMode()}
    </div>
  );
});
