import React, { FC } from 'react';

export const UploadDevicesFileIcon:FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#011328"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 15v4c0 .53-.21 1.04-.586 1.414A1.995 1.995 0 0119 21H5c-.53 0-1.04-.21-1.414-.586A1.995 1.995 0 013 19v-4m14-7l-5-5-5 5m5-5v12"
      ></path>
    </svg>
  );
};
