import React, { FC, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { InfoWarnIcon } from '../../../icons';
import { PATHS } from '../../../router';
import { Button } from '../../Button';

export const FifthStep:FC = memo(function FifthStep () {
  const { push } = useHistory();

  return (
    <div className="FifthStepComponent">
      <h3 className="FifthStepComponent__Title">Confirmation</h3>
      <span className="FifthStepComponent__SubTitle">Votre demande est envoyée 🎉</span>
      <div className="FifthStepComponent__Content">
        <h6 className="FifthStepComponent__Content__Title">Merci</h6>
        <span className="FifthStepComponent__Content__SubTitle">
          Nous accusons réception de votre demande, notre service logistique vous contactera pour organiser l’intervention.
        </span>
        <div className="FifthStepComponent__Content__Info">
          <InfoWarnIcon/>
          N’oubliez pas de nous fournir vos mots passe ou de les supprimer avant l’enlèvement
        </div>
        <Button label="Retourner sur la liste des commandes" onClick={() => push(PATHS.COMMANDS)} />
        <img className="FifthStepComponent__Image" src="/media/saved-Order.png" alt="" />
      </div>
    </div>
  );
});
