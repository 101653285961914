import React, { FC, memo } from 'react';
import { useHistory } from 'react-router';
import {
  OrderInProgressStatusIcon,
  OrderAuditStatusIcon,
  OrderReceptionStatusIcon,
  OrderFinishedStatusIcon,
  OrderDocumentReadyStatusIcon
} from '../../icons';
import { PATHS } from '../../router';
import { ObjectLiteral } from '../../types';
import { OrderStatus } from '../../types/dto';

type Props = {
  id: number;
  deliveryDate: string;
  status: OrderStatus;
  className?: string;
}

const orderListStatusIcon: ObjectLiteral<JSX.Element> = {
  [OrderStatus.IN_PROGRESS]: <OrderInProgressStatusIcon color="#011328" />,
  [OrderStatus.AUDIT]: <OrderAuditStatusIcon color="#011328" />,
  [OrderStatus.RECEPTION]: <OrderReceptionStatusIcon color="#011328" />,
  [OrderStatus.DOCUMET_READY]: <OrderDocumentReadyStatusIcon />,
  [OrderStatus.FINISHED]: <OrderFinishedStatusIcon color="#011328" />,
};

const orderListDateText = {
  [OrderStatus.IN_PROGRESS]: 'Prise en charge le ',
  [OrderStatus.AUDIT]: 'Estimés pour le ',
  [OrderStatus.RECEPTION]: 'Prise en charge le ',
  [OrderStatus.DOCUMET_READY]: 'Prise en charge le ',
  [OrderStatus.FINISHED]: 'Prise en charge le ',
};

export const SmallOrderListItem:FC<Props> = memo(function SmallOrderListItem({
  id,
  deliveryDate,
  status,
  className = '',
}) {
  const { push } = useHistory();

  return (
    <button
      key={id}
      className={`SmallOrderListItemComponent ${className}`}
      onClick={() => {
        push(`${PATHS.COMMANDS}/${id}`);
      }}
    >
      <div className="SmallOrderListItemComponent__Left">
        <span className="SmallOrderListItemComponent__Id"> Commande: {id}</span>
        <span className="SmallOrderListItemComponent__Date">{orderListDateText[status]} {deliveryDate}</span>
      </div>
      <div className="SmallOrderListItemComponent__Right">{orderListStatusIcon[status]}{status}</div>
    </button>
  );
});
