import { FETCH_STATUS } from '../FetchRequest.type';

export type SuccessLoginDto = {
  result: {
    success: boolean;
    firstTime: boolean;
  };
};

export type ErrorLoginDto = {
  status: FETCH_STATUS.ERROR;
  code: number;
  message: string;
};

export type LoginDTO = ErrorLoginDto | SuccessLoginDto ;

export interface LogoutErrorDTO {
  status: FETCH_STATUS.ERROR;
  code: 10009;
  message: 'Expired';
}

export interface LogoutSuccessDTO {
  status: FETCH_STATUS.SUCCESS;
}

export type LogoutDTO = LogoutErrorDTO | LogoutSuccessDTO;

// Guards

export const isLoginSuccess = (loginResult: LoginDTO): loginResult is SuccessLoginDto => {
  return (loginResult as SuccessLoginDto).result?.success;
};

export const isLogoutSuccess = (logoutResult: LogoutDTO): logoutResult is LogoutSuccessDTO => {
  return logoutResult.status === FETCH_STATUS.SUCCESS;
};
