import React, { FC } from 'react';

type Props = {
  size?: number;
  color?: string;
  strokeWidth?: number;
}

export const LoadingFileIcon:FC<Props> = ({ size = 32, color = '#d8dbe7', strokeWidth = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M17.334 2.667H8a2.665 2.665 0 00-2.667 2.666v21.334A2.666 2.666 0 008 29.333h16a2.667 2.667 0 002.666-2.667V12l-9.332-9.334z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M17.334 2.667V12h9.333"
      ></path>
    </svg>
  );
};
