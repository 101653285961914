export const isValidPassword = (pass: string): boolean => {
  if (!pass) {
    return false;
  }

  return (pass.length >= 4) && (pass.length < 40);
};

export const isValidPassWordPattern = (pass: string): boolean => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[!@#\$%\^&\*])(?=.{12,40})/;

  return (pass.length >= 12) && (pass.length < 40) && re.test(String(pass));
};
