import React, { FC, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { SmallOrderListItem } from '..';
import { PATHS } from '../../router';
import { OrderListItem } from '../../types/dto';

type Props = {
  currentOrderId: number;
  orders: OrderListItem[];
  errorOrders?: Record<number, number>;
}

export const LastOrdersList:FC<Props> = memo(function LastOrdersList({ orders, currentOrderId, errorOrders }) {
  const { push } = useHistory();

  const handleOrderCLasses = (id: number): string => {
    let classes = '';

    if (id === currentOrderId) {
      classes += ' Active';
    }

    if(errorOrders && errorOrders[id]) {
      classes += ' Red';
    }

    return classes;
  };

  return (
    <>
      <div className="LastOrdersComponent__Title">
        Mes commandes
      </div>
      <div className="LastOrdersComponent__List">
        {orders.map(({ id, deliveryDate, status }) => (
          <SmallOrderListItem
            key={id}
            id = {id}
            deliveryDate = {deliveryDate}
            status = {status}
            className={handleOrderCLasses(id)}
          />
        ))}
      </div>
      <div className="LastOrdersComponent__Bottom">
        <button onClick={() => push(PATHS.COMMANDS)}>Voir tout</button>
      </div>
    </>
  );
});
