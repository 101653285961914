import React, { FC, useContext, useState, memo } from 'react';
import { Button, ButtonColor, SubAccountSelect } from '../..';
import { confirmModalContext } from '../../../context';
import { CloseMobileMenuIcon, EditIcon } from '../../../icons';
import { Burger } from '../../../icons/Burger';
import { PATHS } from '../../../router';
import { addBodyScroll, hideBodyScroll } from '../../../utils';
import { HeaderSearch } from '../HeaderSearch';
import { HeaderLinks } from '../MenuLinks';
import { UserProfileFooter } from '../UserProfileFooter';
import { UserProfileInformation } from '../UserProfileInformation';
import { UserProfileMenuLinks } from '../UserProfileMenuLinks';

export const MobileMenu: FC = memo(function MobileMenu() {
  const { navigateOnPath } = useContext(confirmModalContext);
  const [isOpened, setIsOpened] = useState(false);

  const toggleMenu = () => {
    if (!isOpened) {
      hideBodyScroll();
      return setIsOpened(true);
    } else {
      addBodyScroll();
    }

    document.querySelector('.HeaderComponent__Mobile')?.classList.add('Closing');

    setTimeout(() => {
      setIsOpened(false);
      document.querySelector('.HeaderComponent__Mobile.Closing')?.classList.remove('Closing');
    }, 300);
  };

  return (
    <>
      <button className="HeaderComponent__Burger" onClick={toggleMenu}>
        {isOpened ? <CloseMobileMenuIcon /> : <Burger />}
      </button>
      {isOpened && (
        <div className="HeaderComponent__Mobile">
          <HeaderSearch />
          <HeaderLinks />
          <Button
            className="HeaderComponent__CreateComand"
            label="Nouvelle commande"
            color={ButtonColor.SECUNDARY}
            icon={<EditIcon />}
            onClick={() => navigateOnPath(PATHS.ORDER_CREATE)}
          />
          <div className="HeaderComponent__Mobile__Container">
            <UserProfileInformation />
          </div>
          <SubAccountSelect />
          <UserProfileMenuLinks />
          <UserProfileFooter />
        </div>
      )}
    </>
  );
});
