export type GroupeStructure = Record<string, Record<string, number>[]>;

export type AccountWithGroup = {
  name: string;
  groupe: string;
  id: number;
}

export const parseAccounts = (groupes: GroupeStructure[]): AccountWithGroup[] => {
  return groupes.reduce((accum, groupeObject) => {
    const groupe = Object.keys(groupeObject)[0];
    const companiesWithGroup = ((groupeObject as unknown as GroupeStructure)[groupe] as Record<string, number>[])
      .map((company) => {
        const name = Object.keys(company)[0];

        return ({
          name,
          groupe,
          id: company[name],
        });
      });

    return [...accum, ...companiesWithGroup];
  }, [] as AccountWithGroup[]);
};
