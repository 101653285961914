import React from 'react';

export const DownloadFileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke="#555864"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 7.5v2a.997.997 0 01-1 1h-7a.997.997 0 01-1-1v-2m2-2.5L6 7.5 8.5 5M6 7.5v-6"
      ></path>
    </svg>
  );
};
