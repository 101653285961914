import React from 'react';

export const PdfFileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        stroke="#011328"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.334 2.667H8a2.665 2.665 0 00-2.667 2.666v21.334A2.666 2.666 0 008 29.333h16a2.667 2.667 0 002.666-2.667V12l-9.332-9.334z"
      ></path>
      <path
        stroke="#011328"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.334 2.667V12h9.333"
      ></path>
      <rect
        width="21.6"
        height="11.2"
        x="11.2"
        y="14.4"
        fill="#EBF0FC"
        rx="3"
      ></rect>
      <path
        fill="#011328"
        d="M16.11 22.7v-5.096h1.708c.568 0 1.005.142 1.312.424.307.283.46.674.46 1.172 0 .494-.153.881-.46 1.164-.307.28-.744.42-1.312.42h-.94V22.7h-.768zm.768-2.58h.94c.325 0 .572-.084.74-.252.168-.168.252-.39.252-.668 0-.28-.084-.506-.252-.68-.168-.173-.415-.26-.74-.26h-.94v1.86zm3.776-2.516h1.56c.517 0 .966.105 1.348.316.383.208.682.503.895.884.214.382.32.83.32 1.348 0 .515-.106.963-.32 1.344a2.233 2.233 0 01-.896.888c-.38.21-.83.316-1.348.316h-1.56v-5.096zm.76.684v3.74h.8c.362 0 .673-.081.931-.244.262-.162.463-.385.605-.668.14-.282.212-.604.212-.964s-.071-.68-.212-.96a1.606 1.606 0 00-.605-.664c-.258-.16-.569-.24-.931-.24h-.8zm4.614 4.412v-5.096h3.149v.656h-2.389v1.544h2.209v.652h-2.209V22.7h-.76z"
      ></path>
    </svg>
  );
};
