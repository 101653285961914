import { Action } from 'redux';
import { ActionPayload, UserLogin } from '../../../types';
import { UserData, } from '../../../types/dto';
import { AccountWithGroup } from '../../../utils';

export const USER_LOG_IN = 'USER_LOG_IN';
export const USER_LOGOUT = 'USER_LOG_OUT';
export const GET_USER = 'GET_USER';
export const USER_LOG_IN_SUCCESS = 'USER_LOG_IN_SUCCESS';
export const USER_LOG_IN_ERROR = 'USER_LOG_IN_ERROR';
export const HANDLE_USER_LOGOUT = 'HANDLE_USER_LOGOUT';
export const HANDLE_GET_USER = 'HANDLE_GET_USER';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const HANDLE_GET_ACCOUNTS = 'HANDLE_GET_ACCOUNTS';
export const CHANGE_SELECTED_ACCOUNTS = 'CHANGE_SELECTED_ACCOUNTS';
export const FIRST_TUTORIAL_PLAYED = 'FIRST_TUTORIAL_PLAYED';

export const userLogin = (payload: UserLogin): ActionPayload<UserLogin> => ({
  type: USER_LOG_IN,
  payload,
});

export const userSuccessLogin = (payload: boolean): ActionPayload<boolean> => ({
  type: USER_LOG_IN_SUCCESS,
  payload,
});

export const userLogout = (): Action => ({ type: USER_LOGOUT });

export const handleUserLogout = (): Action => ({ type: HANDLE_USER_LOGOUT });

export const userLoginError = (): Action => ({ type: USER_LOG_IN_ERROR });

export const userGet = (payload: number[]): ActionPayload<number[]> => ({ type: GET_USER, payload });

export const handleUserGet = (payload: UserData): ActionPayload<UserData> => ({
  type: HANDLE_GET_USER,
  payload,
});

export const getAccounts = (): Action => ({ type: GET_ACCOUNTS });

export const handleGetAccounts = (payload: AccountWithGroup[]): ActionPayload<AccountWithGroup[]> => ({
  type: HANDLE_GET_ACCOUNTS,
  payload,
});

export const changeSelectedAccounts = (payload: number[]): ActionPayload<number[]> => ({
  type: CHANGE_SELECTED_ACCOUNTS,
  payload,
});

export const firstTutorialPlayed = (): Action => ({
  type: FIRST_TUTORIAL_PLAYED,
});
