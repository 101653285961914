import React, { FC } from 'react';

export const InfoTooltipIcon:FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke="#555864"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 11A5 5 0 105.999.999 5 5 0 006 11zm.005-7v2m0 2H6"
      ></path>
    </svg>
  );
};
