import React from 'react';

export const NotificationItemIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#0D142D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.667 12.667A1.335 1.335 0 0113.333 14H2.667a1.333 1.333 0 01-1.333-1.333V3.333A1.332 1.332 0 012.667 2H6l1.333 2h6a1.334 1.334 0 011.333 1.333v7.334z"
      ></path>
    </svg>
  );
};
