import React, { createContext, FC, useState } from 'react';
import { useHistory } from 'react-router';
import { ConfirmModal } from '../components';
import { PATHS } from '../router';

type ModalConfig = {
  title: string | JSX.Element;
  text: string | JSX.Element;
  onConfirm: () => void;
  onCancel : () => void;
  confirmLabel: string;
  cancelLabel: string;
}

type ConfirmModalProviderValue = {
  modalConfig: ModalConfig;
  setModalConfig: (newActions: Partial<ModalConfig>) => void;
  setIsModalOpen: (newModalState: boolean) => void;
  resetAll: () => void;
  navigateOnPath: (link: string) => void;
  shouldOpenModal: boolean;
  setShouldOpenModal: (newShouldOpen: boolean) => void;
}

const initialContextValue: ConfirmModalProviderValue = {
  modalConfig: {
    title: 'Modification en cours',
    text: <>Si vous quittez la page vous perdrez.<br/>Voulez-vous quitter la page ?</>,
    confirmLabel: 'Quitter la page',
    cancelLabel: '',
    onConfirm: () => console.log('onConfirm'),
    onCancel: () => console.log('onCancel'),
  },
  navigateOnPath: (link) => console.log(link),
  resetAll: () => console.log('resetAll'),
  setModalConfig: () => console.log('setModalActions'),
  setIsModalOpen: () => console.log('setIsModalOpen'),
  shouldOpenModal: false,
  setShouldOpenModal: () => console.log('setShouldOpenModal'),
};

export const shouldConfirmModalPage = [
  PATHS.USER_INFO,
  PATHS.ORDER_CREATE,
  PATHS.CHANGE_PASSWORD,
];

export const confirmModalContext = createContext<ConfirmModalProviderValue>(initialContextValue);

export const ConfirmModalProvider:FC = ({ children }) => {
  const { Provider } = confirmModalContext;
  const { push, location: { pathname } } = useHistory();
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [modalConfig, setConfig] = useState<ModalConfig>({
    ...initialContextValue.modalConfig,
    onCancel: () => setIsModalOpen(false),
  });
  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  const setModalConfig = (newActionValues: Partial<ModalConfig>) => setConfig({
    ...modalConfig,
    ...newActionValues,
  });

  const navigateOnPath = (link: string) => {
    if(!shouldConfirmModalPage.includes(pathname) || !shouldOpenModal) {
      return push(link);
    }

    setModalConfig({
      onConfirm: () => {
        setIsModalOpen(false);
        setShouldOpenModal(false);
        push(link);
       },
    });

    setIsModalOpen(true);
  };

  const resetAll = () => {
    setConfig({
      ...initialContextValue.modalConfig,
      onCancel: () => setIsModalOpen(false),
    });
    setShouldOpenModal(false);
  };

  return (
    <Provider value={{
      modalConfig,
      setModalConfig,
      setIsModalOpen,
      navigateOnPath,
      resetAll,
      shouldOpenModal,
      setShouldOpenModal,
    }}>
      {children}
      {isModalOpen && <ConfirmModal {...modalConfig} />}
    </Provider>
  );
};
