import React, { FC, memo } from 'react';

type Props = {
  checked: boolean;
  onChange: (value: boolean) => void;
  id: string;
  label: string;
  error?: string;
  className?: string;
  disabled?: boolean
};

export const CheckBox: FC<Props> = memo(function CheckBox({
  checked,
  id,
  label,
  error,
  onChange,
  className = '',
  disabled = false,
}) {
  const errorClass = error ? 'Error' : '';

  return (
    <div className={`CheckboxComponent__Wrapper ${className}`}>
        <label htmlFor={id} className={`CheckboxComponent ${errorClass}`}>
          <input
            id={id}
            checked={checked}
            type="checkbox"
            autoComplete="off"
            disabled={disabled}
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              onChange(event.currentTarget.checked)
            }
          />
          <span className='CheckboxComponent__Icon'></span>
          <span className='CheckboxComponent__Label'>{label}</span>
        </label>
    </div>
  );
});
