import React, { FC, useMemo, memo } from 'react';
import { CO2Icon, EquipmentsIcon, ETPIcon, RateIcon, WaterIcon } from '../../icons';
import { CSR_DETAILS_TYPE } from '../../types';
import { parseNumber } from '../../utils';
import { Button, ButtonColor } from '../Button';

const MILION = 1_000_000;
const PISCINE_VOLUME = 2_500_000;

type Props = {
  type: CSR_DETAILS_TYPE,
  numbers: number [];
  onButtonClick?: () => void;
  className?: string;
}

export const CSRInfoBlock: FC<Props> = memo(function CSRInfoBlock ({ type, numbers, onButtonClick, className = '', }) {
  const iconByBockType = useMemo(() => ({
    [CSR_DETAILS_TYPE.WEIGTH]: <EquipmentsIcon />,
    [CSR_DETAILS_TYPE.REUSE]: <RateIcon />,
    [CSR_DETAILS_TYPE.WATER]: <WaterIcon />,
    [CSR_DETAILS_TYPE.CO2]: <CO2Icon />,
    [CSR_DETAILS_TYPE.ETP]: <ETPIcon />,
  }), []);

  const numberByBlockType = useMemo(() => ({
    [CSR_DETAILS_TYPE.WEIGTH]: () => parseNumber(numbers[0]),
    [CSR_DETAILS_TYPE.REUSE]: () => `${parseNumber(numbers[0] * 100)}%`,
    [CSR_DETAILS_TYPE.WATER]: () => {
      if (numbers[0] < 1000) {
        return parseNumber(numbers[0]);
      }

      const inTonnes = numbers[0] > MILION
        ? parseNumber(numbers[0] / MILION)
        : parseNumber(numbers[0] / 1000);
      const additionalWord = numbers[0] > MILION ? 'millions' : 'milliers';

      return `${inTonnes} ${additionalWord}`;
    },
    [CSR_DETAILS_TYPE.CO2]: () => (`${parseNumber(numbers[0] / 1000)} tonnes`),
    [CSR_DETAILS_TYPE.ETP]: () => `${parseNumber(numbers[0])} ETP`,
  }), [numbers]);

  const textByBlockType = useMemo(() => ({
    [CSR_DETAILS_TYPE.WEIGTH]: () => {
      const endPhrase = numbers[1]
       ? `${parseNumber(numbers[1])} Kg`
       : 'correspondant à un poids de 0 Kg';

      return `équipements traités pour ${endPhrase}`;
    },
    [CSR_DETAILS_TYPE.REUSE]: () => 'de taux de réemploi de vos équipements',
    [CSR_DETAILS_TYPE.WATER]: () => {
      const amount = numbers[0] / PISCINE_VOLUME < 1
        ? '<1 piscine olympique'
        : `${parseNumber(numbers[0] / PISCINE_VOLUME)} piscines olympiques`;

      return `de litres d’eau économisés, soit l’équivalent de ${amount} `;
    },
    [CSR_DETAILS_TYPE.CO2]: () => `de CO2 économisé, soit l’équivalent de ${numbers[1].toString()} tours du monde en avion`,
    [CSR_DETAILS_TYPE.ETP]: () => '(équivalent temps plein)',
  }), [numbers]);

  return (
    <div className={`CSRInfoBlockComponent ${className}`}>
      <div className="CSRInfoBlockComponent__Icon">{iconByBockType[type]}</div>
      <div className="CSRInfoBlockComponent__Info">
        <span className="CSRInfoBlockComponent__Number">{numberByBlockType[type]()}</span>
        <span className="CSRInfoBlockComponent__Text">
          {textByBlockType[type]()}
        </span>
      </div>
      {onButtonClick && (
        <Button
          className="CSRInfoBlockComponent__Button"
          label="Voir plus"
          color={ButtonColor.SECUNDARY}
          onClick={onButtonClick}
        />
      )}
    </div>
  );
});
