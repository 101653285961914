import React, { FC } from 'react';

export const SearchIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke="#858892"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.333 13.167a5.334 5.334 0 100-10.668 5.334 5.334 0 000 10.668zM14 14.5l-2.9-2.9"
        opacity="0.55"
      ></path>
    </svg>
  );
};
