const NO_SCROLL_CLASS = 'no-scroll';

export const hideBodyScroll = (): void => {
  const body = document.querySelector('body') as HTMLBodyElement;

  if (
    body.classList.contains(NO_SCROLL_CLASS)
    || window.innerHeight >= document.body.offsetHeight
  ) {
    return;
  }

  body.classList.add(NO_SCROLL_CLASS);
};

export const addBodyScroll = (): void => {
  const body = document.querySelector('body') as HTMLBodyElement;

  if (!body.classList.contains(NO_SCROLL_CLASS)) {
    return;
  }

  body.classList.remove(NO_SCROLL_CLASS);
};

export const handleWrapperClick = (e: any, className: string, callback: () => void) => {
  const clickedElementCssClass = e.target.getAttribute('class');

  if (clickedElementCssClass?.includes(className)) {
    callback();
  }
};
