export const formatDate = (dateToParse: Date | string): string => {
  const paddWithZero = (datePart: number) => datePart < 9 ? `0${datePart}` : `${datePart}`;
  const date = new Date(dateToParse);

  const day = paddWithZero(date.getDate());
  const month = paddWithZero(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
