export enum SNACKBAR_TYPE {
  ALERT = 'ALERT',
  SUCCESS = 'SUCCESS',
}

export interface SnackbarState {
  type: SNACKBAR_TYPE;
  text: string;
  isShown: boolean;
}
