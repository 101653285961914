export enum CSR_DETAILS_TYPE {
  WEIGTH = 'weigth',
  REUSE = 'reuse',
  WATER = 'water',
  CO2 = 'CO2',
  ETP = 'ETP',
}

export type CSRDataPayload = {
  accounts: number[],
  from: string,
  to: string,
}

export type CSRDetailsPayload = CSRDataPayload & { type: CSR_DETAILS_TYPE };

export type FinancialStatisticsPayload = {
  accounts: number[],
  year: string,
}
