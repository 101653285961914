import React, { useMemo } from 'react';
import { CopyTextIcon, SearchHeaderOptionIcon } from '../../icons';
import { ObjectLiteral, SORT_ORDER } from '../../types';
import { Loader } from '../Loader';
import { Pagiation } from '../Pagination';

export type ColumnsConfig<T> = {
  parse: (item: T, index: number, rows: T[]) => any;
  className: string;
  width: string;
  header: string | JSX.Element;
  textBreak: boolean;
}

type Props<T> = {
  items: T[];
  pageCount: number;
  currentPage: number;
  currentSortBy: string;
  onPageChange: (newPage: number) => void;
  onSortByChange?: (newSortBy: string) => void
  onRowClick?: (item: T) => void,
  className?: string;
  isLoading?: boolean;
  columns?: ObjectLiteral<Partial<ColumnsConfig<T>>>;
  sortOrder?: SORT_ORDER;
  onClipboardCopy?: () => void;
  rowClassName?: string | ((item: T, index: number, rows: T[]) => string);
  headerSlot?: React.ReactNode;
}

type HeaderProps<T> = {
  sortColumn: string;
  onSortChange?: (column: string) => void;
  sortOrder?: SORT_ORDER;
  columns: ObjectLiteral<Partial<ColumnsConfig<T>>>;
};


const HeaderList = <T extends { id: number | string }>({
  onSortChange,
  sortColumn,
  columns,
  sortOrder,
}: HeaderProps<T>) => {
  const headerList = Object.keys(columns);

  return (
    <ul className="ListComponent__Header__List">
      {headerList.map((headerItem: string) => (
        <li
          key={headerItem}
          style={{
            width: columns[headerItem].width || `calc(100% / ${headerList.length})`,
          }}
        >
          <button
            className={`${headerItem === sortColumn ? 'Active' : ''}`}
            onClick={onSortChange ? () => onSortChange(headerItem) : console.log}
          >
            <span>
              {columns[headerItem].header || headerItem}
            </span>
            {onSortChange && sortOrder && (
              <>
                <span className="ListComponent__Header__Icon">
                  <SearchHeaderOptionIcon fill={headerItem === sortColumn ? '#64a70b' : '#41434B'} />
                </span>
                <span className={`Chevron ${sortOrder === SORT_ORDER.ASC ? 'Asc' : ''}`}></span>
              </>
            )}
          </button>
        </li>
      ))}
    </ul>
  );
};

export const List = <T extends { id: number | string }>({
  items,
  pageCount,
  currentPage,
  currentSortBy,
  onPageChange,
  onSortByChange,
  onClipboardCopy,
  sortOrder,
  headerSlot,
  rowClassName = '',
  onRowClick = () => undefined,
  className = '',
  isLoading = false,
  columns = {},
}: Props<T>): JSX.Element => {
  const columnsList = useMemo(() => Object.keys(columns), [items, columns]);

  const handleColumnValue = (column: string, row: T, index: number, rows: T[]) => {
    const dataToShow = row[column as keyof T] || null;
    const { parse } = columns[column];

    if (parse) {
      return parse(row, index, rows);
    }

    if (dataToShow instanceof Date) {
      return dataToShow.toDateString();
    }

    if (typeof dataToShow === 'boolean') {
      return `${dataToShow}`;
    }

    return dataToShow;
  };

  const copyTextToClickboard = (stringToCopy: string) => {
    navigator.clipboard.writeText(stringToCopy);
  };

  return (
    <div className={`ListComponent__WithPagination ${className}`}>
      {isLoading && <Loader />}
      <div className="ListComponent__Wrapper">
        <div className="ListComponent">
          {headerSlot}
          <div className="ListComponent__Header">
            <HeaderList
              sortColumn={currentSortBy}
              onSortChange={onSortByChange}
              columns={columns}
              sortOrder={sortOrder}
            />
          </div>
          <div className="ListComponent__Rows">
            {items.map((row, i) => {
              const rowClass = typeof rowClassName === 'string'
                ? rowClassName
                : rowClassName(row, i, items);
              return (
                <div
                  className={`ListComponent__Row ${rowClass}`}
                  key={row?.id ?? i}
                  onClick={() => onRowClick(row)}
                >
                  {columnsList.map((column) => {
                    const { width, className: columnClassName = '', textBreak = false, } = columns[column];
                    const valueToshow: string = handleColumnValue(column, row, i, items);
                    const shouldShowCopyButton = valueToshow && valueToshow !== 'NoTag' && textBreak;

                    return (
                      <span
                        key={column}
                        className={`ListComponent__Column ${columnClassName} ${textBreak ? 'TextWrap' : ''}`}
                        style={{
                          width: width || `calc(100% / ${columnsList.length})`,
                        }}
                      >
                        {valueToshow}
                        {shouldShowCopyButton && onClipboardCopy && (
                          <span
                            className="ListComponent__Column__Copy"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              copyTextToClickboard(handleColumnValue(column, row, i, items));
                              onClipboardCopy();
                            }}
                          >
                            <CopyTextIcon />
                          </span>
                        )}
                      </span>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {(pageCount > 1) &&
        <Pagiation
          pageCount={pageCount}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      }
    </div>
  );
};
