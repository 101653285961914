 import React, { FC, memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from '../../..';
import { NotificationItemIcon } from '../../../../icons';
import { PATHS } from '../../../../router';
import { NotificationDto } from '../../../../types';

export type NotificationProps = NotificationDto & {
  onclick: (notification: NotificationDto) => void;
  onDelete: (notification: NotificationDto) => void;
};

const HOUR = 3_600_000;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const YEAR = DAY * 365;

const calculatePassedTime = (date: string | Date): string => {
  const now = new Date().getTime();
  const dateTime = new Date(date).getTime();
  const difference = now - dateTime;
  const yearDifference = parseInt(`${difference / YEAR}`);

  if (yearDifference >= 1) {
    return `${yearDifference}a`;
  }

  const weekDifference = parseInt(`${difference / WEEK}`);

  if (weekDifference >= 1) {
    return `${weekDifference}s`;
  }

  const dayDifference = parseInt(`${difference / DAY}`);

  if (dayDifference >= 1) {
    return `${dayDifference}j`;
  }

  return `${parseInt((difference / HOUR).toString())}h`;
};

export const Notification: FC<NotificationProps> = memo(function Notification ({
  id,
  text,
  read,
  time,
  title,
  order,
  onclick,
  onDelete,
}) {
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);

  const onClick = (e: React.MouseEvent, link: string) => {
    e.preventDefault();
    onclick({ id, text, read, time, title, order });
    push(link);
  };

  const onDeleteClick = (e: any) => {
    e.stopPropagation();
    setLoading(true);
    onDelete({ id, text, read, time, title, order });
  };

  return (
    <div
      className={`NotificationComponent ${read ? 'Read' : ''}`}
      onClick={(e) => onClick(e, `${PATHS.COMMANDS}/${order}`)}
    >
      {loading && <Loader/>}
      <div className='NotificationComponent__Top'>
        <div className='NotificationComponent__Top__Icon'><NotificationItemIcon/></div>
        <div className='NotificationComponent__Top__Title'>{title}</div>
        <button
          className='NotificationComponent__Top__Close'
          onClick={(e) => onDeleteClick(e)}
        >
          +
        </button>
      </div>
      <div className='NotificationComponent__Bottom'>
        <div className='NotificationComponent__Bottom__Text'>
          {text}
        </div>
        <div className='NotificationComponent__Bottom__Time'>{calculatePassedTime(time)}</div>
      </div>
    </div>
  );
});
