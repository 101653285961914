import React, { FC, useContext, memo } from 'react';
import { EditIcon, LogoIcon, TutorialIcon } from '../../icons';
import { Button, ButtonColor } from '../Button';
import { HeaderSearch } from './HeaderSearch';
import { UserDropdown } from './UserDropdown/UserDropdown';
import { HeaderLinks } from './MenuLinks';
import { MobileMenu } from './MobileMenu';
import { PATHS } from '../../router';
import { confirmModalContext, orderWizardContext, STEPS, tutorialVideoContext } from '../../context';
import { Notifications } from './Notifications';
import { useUserRole } from '../../hooks';
import { USER_ROLES } from '../../types';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/modules/user';
import { envConfig } from '../../config';
import { DonationIcon } from '../../icons/Donation';

type Props = {
  showOnDesktop?: boolean;
};

export const Header: FC<Props> = memo(function Header({ showOnDesktop = true }) {
  const { navigateOnPath } = useContext(confirmModalContext);
  const { clearOrder, setActiveStep, setSelectedOrderAcount } = useContext(orderWizardContext);
  const { setIsShown } = useContext(tutorialVideoContext);
  const { isUserInRole } = useUserRole();
  const { donation } = useSelector(selectUser);

  const onOrderCreateClick = () => {
    clearOrder();
    setSelectedOrderAcount(0);
    setActiveStep(STEPS.STEP1);
    navigateOnPath(PATHS.ORDER_CREATE);
  };

  const goToDonation = () => {
    window.open(envConfig.donationURl, 'open');
  };

  return (
    <div className={`HeaderComponent ${!showOnDesktop ? 'NoDesktop' : ''}`}>
      <div className="HeaderComponent__LeftSide">
        <span className="HeaderComponent__Logo" onClick={() => navigateOnPath(PATHS.DASHBOARD)}>
          <LogoIcon />
        </span>
        <HeaderLinks />
      </div>
      <div className="HeaderComponent__RightSide">
        {!isUserInRole(USER_ROLES.COMPTABILITE) && <HeaderSearch />}
        {donation && (
          <Button
            className="HeaderComponent__Donation"
            label="Donation"
            color={ButtonColor.PRIMARY}
            onClick={goToDonation}
            icon={<DonationIcon />}
          />
        )}
        <Button
          className="HeaderComponent__Tutorial"
          label="Tuto myatf"
          color={ButtonColor.SECUNDARY}
          icon={<TutorialIcon />}
          onClick={() => setIsShown(true)}
        />
        {!isUserInRole(USER_ROLES.COMPTABILITE) && (
          <Button
            className="HeaderComponent__CreateComand"
            label="Nouvelle commande"
            color={ButtonColor.SECUNDARY}
            icon={<EditIcon />}
            onClick={onOrderCreateClick}
          />
        )}
        <div className="HeaderComponent__RightSide__LastGroup">
          <Notifications />
          <UserDropdown />
          <MobileMenu />
        </div>
      </div>
    </div>
  );
});
