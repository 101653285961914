import React, { FC } from 'react';

export const EmailIcon: FC= () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#011328"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.333 3.333h13.334c.916 0 1.666.75 1.666 1.667v10c0 .917-.75 1.667-1.666 1.667H3.333c-.916 0-1.666-.75-1.666-1.667V5c0-.917.75-1.667 1.666-1.667z"
      ></path>
      <path
        stroke="#011328"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.333 5L10 10.833 1.667 5"
      ></path>
    </svg>
  );
};
