import React, { FC, useMemo, memo } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { capitalize } from '../../utils';

export enum DIAGRAM_TYPES {
  PIE = 'PIE',
  BAR = 'BAR',
}

type Item = {
  backgroundColor: string,
  label: string,
  data: number,
  borderColor?: string,
}

type PieDiagramData = {
  labels: string[],
  datasets: [
    {
      data: number[],
      backgroundColor: string[],
      borderColor: string[],
      borderWidth: number,
    },
  ],
}

type Props = {
  items: Item[];
  title: string;
  type?: DIAGRAM_TYPES;
  height?: number;
}

const initialData: PieDiagramData = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 2,
    },
  ],
};

const barOptions = {
  maintainAspectRatio: false ,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const pieOptions = {
  ...barOptions,
  scales: {
    xAxes: [{
      gridLines: {
        color: 'rgba(0, 0, 0, 0)',
      }
    }],
  },
} as any;

export const Diagram: FC<Props> = memo(function Chart({ title, items, type = DIAGRAM_TYPES.PIE, height = 350 }) {
  const parsedItems = useMemo(() => (
    items.reduce((accum, { label, backgroundColor, borderColor, data }) => ({
        labels: [...accum.labels, capitalize(label)],
        datasets: [{
          data: [...accum.datasets[0].data, data],
          backgroundColor: [...accum.datasets[0].backgroundColor, backgroundColor],
          borderColor: [...accum.datasets[0].borderColor, borderColor || '#f3f5fa'],
          borderWidth: 2,
        }],
    } as unknown as PieDiagramData), initialData)
  ), [items]);

  const diagramToRender = useMemo(() => {
    if(type === DIAGRAM_TYPES.PIE) {
      return <Pie data={parsedItems} options={barOptions} height={height}/>;
    }

    return <Bar data={parsedItems} options={pieOptions} height={height} />;
  }, [type]);

  return (
    <div className="PieDiagramComponent">
      <span className="PieDiagramComponent__Title">{title}</span>
      <div className="PieDiagramComponent__Diagram">
        {diagramToRender}
      </div>

      {type === DIAGRAM_TYPES.PIE && (
        <div className="PieDiagramComponent__Legend">
          {items.map(({ label, backgroundColor }) => (
            <div key={label} className="PieDiagramComponent__Legend__Item">
              <span className="Color" style={{ backgroundColor }}></span>
              <span className='Label'>{label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
 );
});
