import React, { FC, memo } from 'react';
import { UNKNOWN } from '../../constants';
import { OrderAuditStatusIcon, OrderDocumentReadyStatusIcon, OrderFinishedStatusIcon, OrderInProgressStatusIcon, OrderReceptionStatusIcon } from '../../icons';
import { ObjectLiteral } from '../../types';
import { OrderStatus } from '../../types/dto';

const orderListStatusLabels: ObjectLiteral<string> = {
  [OrderStatus.IN_PROGRESS]: 'Enlèvement',
  [OrderStatus.RECEPTION]: 'Réception',
  [OrderStatus.AUDIT]: 'Audit',
  [OrderStatus.DOCUMET_READY]: 'Documents disponibles',
  [OrderStatus.FINISHED]: 'Merci !',
};

const orderListStatusIcon: ObjectLiteral<JSX.Element> = {
  [OrderStatus.IN_PROGRESS]: <OrderInProgressStatusIcon />,
  [OrderStatus.AUDIT]: <OrderAuditStatusIcon />,
  [OrderStatus.RECEPTION]: <OrderReceptionStatusIcon />,
  [OrderStatus.DOCUMET_READY]: <OrderDocumentReadyStatusIcon />,
  [OrderStatus.FINISHED]: <OrderFinishedStatusIcon />,
};

type Props = {
  currentStatus?: OrderStatus;
  statusDates?: Record<OrderStatus, string | null>;
  className?: string;
}

export const OrderStatusTrack: FC<Props> = memo(function OrderStatusTrack({
  currentStatus = OrderStatus.AUDIT,
  statusDates,
  className = '',
}) {
   const getIndicatorXPosition = (): string => {
    const statusHandler = {
      [OrderStatus.IN_PROGRESS]: '0%',
      [OrderStatus.RECEPTION]: '25%',
      [OrderStatus.AUDIT]: '50%',
      [OrderStatus.DOCUMET_READY]: '75%',
      [OrderStatus.FINISHED]: '100%',
    };

    if (!orderListStatusIcon[currentStatus]) {
      return '0%';
    }

    return statusHandler[currentStatus];
  };

  const setStatusDate = (date: string | null, status: OrderStatus) => {
    if(!date) {
      return '';
    }

    if (date === '00/00/0000') {
      return <span className="OrderStatusTrackComponent__DateInfo">{UNKNOWN}</span>;
    }

    let dateToShow = '';

    const handleStatus: ObjectLiteral<string> = {
      [OrderStatus.FINISHED]: `Cloturée le ${date}`,
    };

    if(handleStatus[status]) {
      dateToShow = handleStatus[status];
    } else {
      dateToShow = date;
    }

    return <span className="OrderStatusTrackComponent__DateInfo">{dateToShow}</span>;
  };

  return (
    <div className={`OrderStatusTrackComponent__Wrapper ${className}`}>
      <div className="OrderStatusTrackComponent">
        <ul className="OrderStatusTrackComponent__List">
          {Object.keys(orderListStatusLabels).map((status: string) => {
            let statusClass = 'OrderStatusTrackComponent__Status';

            if (status === currentStatus) {
              statusClass += ' Active';
            }

            if (statusDates) {
              statusClass += ' WithDates';
            }

            return (
              <li key={status} className={statusClass}>
                <span className="OrderStatusTrackComponent__Icon">
                  {orderListStatusIcon[status]}
                </span>
                <span className="OrderStatusTrackComponent__Label">
                  {orderListStatusLabels[status]}
                </span>
                {!!statusDates && (setStatusDate(statusDates[status as OrderStatus], status as OrderStatus))}
                {!statusDates && <span className="OrderStatusTrackComponent__Pointer"></span>}
              </li>
            );
          })}
        </ul>
        <span className="OrderStatusTrackComponent__Line" style={{ width: getIndicatorXPosition() }}></span>
      </div>
    </div>
  );
});
