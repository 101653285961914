import { ActionPayload, DateRange, ObjectLiteral, StatisticsStore } from '../../../types';
import { CSR_CHANGE_DATE_RANGE } from './statistics.actions';

const initialState: StatisticsStore = {
  CSR: {
    dateRange: null,
  }
};

export const statisticsReducer = (
  storeState: StatisticsStore | undefined,
  action: ActionPayload<Partial<StatisticsStore>>
): StatisticsStore => {
  const state = storeState || initialState;
  const { type, payload } = action;

  const actionHandler: ObjectLiteral<() => StatisticsStore> = {
    [CSR_CHANGE_DATE_RANGE]: () => ({
      ...state,
      CSR: {
        ...state.CSR,
        dateRange : payload as DateRange,
      }
    }),
  };

  if(!actionHandler[type]) {
    return state;
  }

  return actionHandler[type]();
};
