import React, { FC, useState, memo } from 'react';
import { DropdownChevronIcon } from '../../icons';

export type DropdownItem = {
  id: string | number;
  name: string;
};

type Props = {
  items: DropdownItem[];
  value: string | number;
  label?: string;
  onChange: (itemId: string | number) => void;
  className?: string;
  placeholder?: string
}

export const Dropdown:FC<Props> = memo(function Dropdown({
  items,
  value,
  onChange,
  label = '',
  placeholder = '',
  className = '',
}) {
  const [isOpen, setIsOpen] = useState(false);

  let selectedValue = '';

  if(value || items.length) {
    selectedValue = items.find((item) => {
      const itemId = item?.id ?? 'NOT_FOUND';
  
      return itemId === value;
     })?.name || '';
  }

  return (
    <div className={`DropdownComponent ${className}`}>
      {label && <span className='DropdownComponent__Label'>{label}</span>}
      <button
        className={`DropdownComponent__Toggler ${isOpen ? 'Opened' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {
          selectedValue
            ? selectedValue
            : <span className='DropdownComponent__Placeholder'>{placeholder}</span>
        }
        <DropdownChevronIcon/>
      </button>
      {isOpen && (
        <div className='DropdownComponent__Options'>
          {items.map(({ id, name }) => (
            <button
              key={id}
              onClick={() => {
                onChange(id);
                setIsOpen(false);
              }}
              className='DropdownComponent__Option'
            >
              {name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
});
