import React, { FC, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackBar } from '../../store/modules/app';
import { SnackbarState, SNACKBAR_TYPE } from '../../types';
import { SnackbarUi } from './SnackbarUi';

export const SnackBar: FC<SnackbarState> = memo(function SnackBar({ isShown, text, type }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if(!isShown) {
      return;
    }

    setTimeout(() => {
      if(isShown) {
        dispatch(setSnackBar({
          isShown: false,
          type: SNACKBAR_TYPE.SUCCESS,
          text:'',
        }));
      }
    }, 4500);
  }, [isShown]);

  return (
    <>
      {isShown && <SnackbarUi text={text} type={type} />}
    </>
  );
});
