import React, { FC, memo } from 'react';
import { Button } from '../Button';
import { ChevronRightIcon, MoreDotsIcon } from '../../icons';

type Props = {
  pageCount: number;
  currentPage: number;
  onPageChange: (newPage: number) => void;
}

const MORE_PAGES = '...';

export const Pagiation:FC<Props> = memo(function Pagination({ pageCount, currentPage, onPageChange }) {
  const maxPageCountWithoutDots = 7;

  const onPageChangeClick = (pageToRender: number) => {
    onPageChange(pageToRender);
  };

  const returnButton = (pageToRender: number, index?: number) => (
    <Button
      key={`${new Date().getTime()}${pageToRender}${index}`}
      label={`${pageToRender}`}
      className={`${currentPage === pageToRender ? 'Active' : ''}`}
      onClick={() => onPageChangeClick(pageToRender)}
    />
  );

  const renderButtons = (): JSX.Element[] => {
    if (pageCount < maxPageCountWithoutDots) {
      return Array.from({ length: pageCount }).map((_, index) => returnButton(index + 1, index));
    }

    const renderPageButtons = (pageToRender: number | string, index: number) => {
      if (MORE_PAGES === pageToRender) {
        return <span key={`${new Date().getTime()}${index}${MORE_PAGES}`}><MoreDotsIcon /></span>;
      }

      return returnButton(pageToRender as number, index);
    };

    if (currentPage <= 3) {
      return [ 1, 2, 3, 4, MORE_PAGES, pageCount ].map(renderPageButtons);
    }

    if (currentPage < pageCount - 2) {
      return [
        1,
        MORE_PAGES,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        MORE_PAGES,
        pageCount,
      ].map(renderPageButtons);
    }

    const remainingPages = Array.from({ length: (pageCount - currentPage) + 1 })
      .map((_, index) => (pageCount - (index + 1))).reverse();

    return [1, MORE_PAGES, ...remainingPages, pageCount].map(renderPageButtons);
  };

  return (
    <div className="PaginationComponent">
      <button
        className="PaginationComponent__GoLeft"
        disabled={currentPage === 1}
        onClick={() => onPageChangeClick(currentPage - 1)}
      >
        <ChevronRightIcon/>
      </button>
      {renderButtons()}
      <button
        className="PaginationComponent__GoRight"
        disabled={currentPage === pageCount}
        onClick={() => onPageChangeClick(currentPage + 1)}
      >
        <ChevronRightIcon/>
      </button>
    </div>
  );
});
