import { all, fork } from 'redux-saga/effects';
import { watchGetNotifications } from './modules/app';
import { watchGetAuditOrder, watchGetOrder, watchGetOrderAuditDevices, watchGetOrders } from './modules/orders';
import { watchGetAccounts, watchGetUser, watchLogin, watchLogout } from './modules/user';

export default function* allSagas(): Iterator<Record<string, any>> {
  yield all([
    fork(watchLogin),
    fork(watchLogout),
    fork(watchGetUser),
    fork(watchGetOrders),
    fork(watchGetOrder),
    fork(watchGetAuditOrder),
    fork(watchGetOrderAuditDevices),
    fork(watchGetAccounts),
    fork(watchGetNotifications),
  ]);
}
