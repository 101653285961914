import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import allSagas from './allSagas';
import rootReducer from './reducer';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (window && (window as Record<string, any>).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

export const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(allSagas);
