import React, { FC } from 'react';

type Props = {
  fill? : string
}

export const SearchHeaderOptionIcon: FC<Props> = ({ fill = '#41434B' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="16"
      fill="none"
      viewBox="0 0 6 16"
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 5L3 3 1 5m0 6l2 2 2-2"
      ></path>
    </svg>
  );
};
