import React, { FC, memo } from 'react';
import { GoBackChevronIcon } from '../../icons';

type Props = {
  onClick: () => void;
  className?: string;
  label?: string;
}

export const GoBack: FC<Props> = memo(function GoBack({ onClick, label = 'RETOUR', className = '' }) {
  return (
    <button className={`GoBackComponent ${className}`} onClick={onClick}>
      <span className="GoBackComponent__Icon">
        <GoBackChevronIcon/>
      </span>
      <span className="GoBackComponent__Label">{label}</span>
    </button>
  );
});
