import React, { FC } from 'react';

type Props = {
  color?: string;
}

export const OrderAuditStatusIcon: FC<Props> = ({ color = '#fff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.667 14V9.334m0-2.667V2M8 14V8m0-2.667V2m5.334 12v-3.334m0-2.666V2M.667 9.334h4M6 5.333h4m1.334 5.333h4"
      ></path>
    </svg>
  );
};
