import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useUserRole } from '../hooks/useUserRole';
import { USER_ROLES } from '../types';

type UserRoleGuardType = {
    /**
     * Accepted or NotAccepted Role or Roles of user who can pass this routs
     * @example <UserRoleGuardRoute role={{accepted: 'Admin'}}> or
     * <UserRoleGuardRoute role={{notAccepted: 'Admin'}}>
     */
    roles: {
        accepted?: USER_ROLES | Array<USER_ROLES>,
        notAccepted?: USER_ROLES | Array<USER_ROLES>
    },
    /**
     * Component that will be rendered
     */
    component: () => JSX.Element,
    /**
     * Path for redirection if guard was not passed
     */
    redirectTo: string,
    /**
     * The exact param disables the partial matching for a route
     */
    exact: boolean,
    /**
     * Path for URL
     */
    path: string
}


export const UserRoleGuardRoute = ({ roles, path, component, redirectTo, exact }: UserRoleGuardType): JSX.Element => {
    const { isUserInRole } = useUserRole();

    const checkGuardRules = () => {
        const RedirectionComponent = () => <Redirect exact to={redirectTo} />;

        if (roles.accepted && roles.notAccepted) {
            throw Error('Please specify OR Accepted roles OR not Accepted roles');
        }

        if (roles.accepted) {
            if (isUserInRole(roles.accepted)) {
                return component;
            } else {
                return RedirectionComponent;
            }
        }

        if (roles.notAccepted) {
            if (isUserInRole(roles.notAccepted)) {
                return RedirectionComponent;
            } else {
                return component;
            }
        }

        return RedirectionComponent;
    };

    return <Route exact={exact} path={path} component={checkGuardRules()} />;
};