import React, { FC, lazy, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { Loader, SnackBar } from '../components';
import { ConfirmModalProvider } from '../context';
import { selectSnackBar } from '../store/modules/app';
import { RootStore, USER_ROLES } from '../types';
import { PATHS } from './constants';
import { UserRoleGuardRoute } from './route-guard';

export const AppRouter: FC = () => {
  const snackBarState = useSelector((store) => selectSnackBar(store as RootStore));

  const Dashboard = useMemo(() => lazy(() => import('../pages/Dashboard/Dasboard')), []);
  const ErrorPage = useMemo(() => lazy(() => import('../pages/Error/Error')), []);
  const UserInfoPage = useMemo(() => lazy(() => import('../pages/UserInfo/UserInfo')), []);
  const LoginPage = useMemo(() => lazy(() => import('../pages/Login/Login')), []);
  const OrdersListPage = useMemo(() => lazy(() => import('../pages/OrdersList/OrdersList')), []);
  const OrderDetailsPage = useMemo(() => lazy(() => import('../pages/OrderDetails/OrderDetails')), []);
  const OrderAuditPage = useMemo(() => lazy(() => import('../pages/OrderAudit/OrderAudit')), []);
  const OrderCreatePage = useMemo(() => lazy(() => import('../pages/OrderCreate/OrderCreate')), []);
  const EmailValidationPage = useMemo(() => lazy(() => import('../pages/EmailValidation/EmailValidation')), []);
  const ForgotPasswordPage = useMemo(() => lazy(() => import('../pages/ForgotPassword/ForgotPassword')), []);
  const ChangePasswordPage = useMemo(() => lazy(() => import('../pages/ChangePassword/ChangePassword')), []);
  const CSRPage = useMemo(() => lazy(() => import('../pages/CSR/CSR')), []);
  const FinancialDashboard = useMemo(() => lazy(() => import('../pages/FinancialDashboard/FinancialDashboard')), []);
  const CSREquipmentsPage = useMemo(() => lazy(() => import('../pages/CSREquipments/CSREquipments')), []);
  const CSRRatePage = useMemo(() => lazy(() => import('../pages/CSRRate/CSRRate')), []);
  const MaintanacePage = useMemo(() => lazy(() => import('../pages/Maintanance/Maintanance')), []);
  const CSRPrintPage = useMemo(() => lazy(() => import('../pages/CSRPrint/CSRPrint')), []);
  const UserPreferences = useMemo(() => lazy(() => import('../pages/UserPreferences/UserPreferences')), []);
  const NotificationsPage = useMemo(() => lazy(() => import('../pages/Notifications/Notifications')), []);
  const CookiesDeclarationPage = useMemo(() => lazy(() => import('../pages/CookiesDeclaration/CookiesDeclaration')), []);
  const PolitiqueConfidentialitePage = useMemo(() => lazy(() => import('../pages/PolitiqueConfidentialite/PolitiqueConfidentialite')), []);
  const MentionsLegalesPage = useMemo(() => lazy(() => import('../pages/MentionsLegales/MentionsLegales')), []);

  return (
    <BrowserRouter>
      <ConfirmModalProvider>
        <Switch>
          <Route exact path={PATHS.MAINTANANCE} component={() => <Suspense fallback={<Loader />}><MaintanacePage /></Suspense>} />
          <Route exact path={PATHS.DASHBOARD} component={() => <Suspense fallback={<Loader />}><Dashboard /></Suspense>} />
          <UserRoleGuardRoute
            exact
            path={PATHS.CSR_PAGE}
            redirectTo={PATHS.DASHBOARD}
            roles={{ accepted: [USER_ROLES.OPERATIONNEL, USER_ROLES.ADMIN] }}
            component={() => <Suspense fallback={<Loader />}><CSRPage /></Suspense>}
          />
          <UserRoleGuardRoute
            exact
            path={PATHS.COMMANDS}
            roles={{ notAccepted: USER_ROLES.COMPTABILITE }}
            redirectTo={PATHS.DASHBOARD}
            component={() => <Suspense fallback={<Loader />}><OrdersListPage /></Suspense>}
          />
          <Route exact path={PATHS.HOME} component={() => <Suspense fallback={<Loader />}><Dashboard /></Suspense>} />
          <Route exact path={PATHS.LOGIN} component={() => <Suspense fallback={<Loader />}><LoginPage /></Suspense>} />
          <Route exact path={PATHS.USER_INFO} component={() => <Suspense fallback={<Loader />}><UserInfoPage /></Suspense>} />
          <UserRoleGuardRoute
            exact
            path={`${PATHS.COMMANDS}/:id`}
            redirectTo={PATHS.DASHBOARD}
            roles={{ notAccepted: USER_ROLES.COMPTABILITE }}
            component={() => <Suspense fallback=''><OrderDetailsPage /></Suspense>}
          />
          <UserRoleGuardRoute
            exact path={`${PATHS.COMMANDS}/:id${PATHS.AUDIT}`}
            redirectTo={PATHS.DASHBOARD}
            roles={{ notAccepted: USER_ROLES.COMPTABILITE }}
            component={() => <Suspense fallback=''><OrderAuditPage /></Suspense>}
          />
          <Route exact path={PATHS.VALIDATE_EMAIL} component={() => <Suspense fallback={<Loader />}><EmailValidationPage /></Suspense>} />
          <UserRoleGuardRoute
            exact
            path={PATHS.ORDER_CREATE}
            roles={{ notAccepted: USER_ROLES.COMPTABILITE }}
            redirectTo={PATHS.DASHBOARD}
            component={() => <Suspense fallback=''><OrderCreatePage /></Suspense>}
          />
          <Route exact path={PATHS.FOROGOT_PASSWORD} component={() => <Suspense fallback={<Loader />}><ForgotPasswordPage /></Suspense>} />
          <Route exact path={PATHS.CHANGE_PASSWORD} component={() => <Suspense fallback={<Loader />}><ChangePasswordPage /></Suspense>} />
          <UserRoleGuardRoute
            exact
            path={PATHS.FINANCIAL_DASHBOARD}
            redirectTo={PATHS.DASHBOARD}
            roles={{ accepted: [USER_ROLES.COMPTABILITE, USER_ROLES.ADMIN] }}
            component={() => <Suspense fallback={<Loader />}><FinancialDashboard /></Suspense>}
          />
          <UserRoleGuardRoute
            exact
            path={`${PATHS.CSR_PAGE}${PATHS.CSR_EQUIPMENTS}`}
            redirectTo={PATHS.DASHBOARD}
            roles={{ accepted: [USER_ROLES.OPERATIONNEL, USER_ROLES.ADMIN] }}
            component={() => <Suspense fallback={<Loader />}><CSREquipmentsPage /></Suspense>}
          />
          <UserRoleGuardRoute
            exact
            path={`${PATHS.CSR_PAGE}${PATHS.CSR_RATE}`}
            redirectTo={PATHS.DASHBOARD}
            roles={{ accepted: [USER_ROLES.OPERATIONNEL, USER_ROLES.ADMIN] }}
            component={() => <Suspense fallback={<Loader />}><CSRRatePage /></Suspense>}
          />
          <Route exact path={PATHS.CHANGE_OLD_PASS} component={() => <Suspense fallback={<Loader />}><ForgotPasswordPage oldAccountChangePass /></Suspense>} />
          <Route exact path={PATHS.CSR_PRINT} component={() => <Suspense fallback={<Loader />}><CSRPrintPage /></Suspense>} />
          <Route exact path={PATHS.USER_PREFERENCES} component={() => <Suspense fallback={<Loader />}><UserPreferences /></Suspense>} />
          <Route exact path={PATHS.NOTIFICATIONS} component={() => <Suspense fallback={<Loader />}><NotificationsPage /></Suspense>} />
          <Route exact path={PATHS.COOKIES_DECLARATION} component={() => <Suspense fallback={<Loader />}><CookiesDeclarationPage /></Suspense>} />
          <Route exact path={PATHS.POLITIQUE_CONFIDENTIALITE} component={() => <Suspense fallback={<Loader />}><PolitiqueConfidentialitePage /></Suspense>} />
          <Route exact path={PATHS.MENTIONS_LEGALES} component={() => <Suspense fallback={<Loader />}><MentionsLegalesPage /></Suspense>} />
          <Route exact path={PATHS.ERROR} component={() => <Suspense fallback={<Loader />}><ErrorPage /></Suspense>} />
          <Route path="*" component={() => <Suspense fallback={<Loader />}><ErrorPage /></Suspense>} />
          <Route><Redirect to={PATHS.HOME} /></Route>
        </Switch>
        <SnackBar {...snackBarState} />
      </ConfirmModalProvider>
    </BrowserRouter>
  );
};

