import { Action } from 'redux';
import { ActionPayload, NotificationDto, SnackbarState } from '../../../types';

type setNotificationsPayload = { data: NotificationDto[], unread: number };

export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const START_LOAD = 'START_LOAD';
export const STOP_LOAD = 'STOP_LOAD';
export const CLEAR_LOAD = 'CLEAR_LOAD';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_USER_MENU_ACTIVE = 'SET_USER_MENU_ACTIVE';
export const TOGGLE_NOTIFICATIONS = 'TOGGLE_NOTIFICATIONS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export const setError = (payload: string): ActionPayload<string> => ({
  type: SET_ERROR,
  payload,
});

export const clearError = (): Action => ({ type: CLEAR_ERROR });

export const startLoad = (payload: string): ActionPayload<string> => ({ type: START_LOAD, payload });

export const stopLoad = (payload: string): ActionPayload<string> => ({ type: STOP_LOAD, payload });

export const clearLoad = (): Action => ({ type: CLEAR_LOAD });

export const setSnackBar = (payload: Partial<SnackbarState>): ActionPayload<Partial<SnackbarState>> => ({ type: SET_SNACKBAR, payload });

export const setUserMenuActive = (payload: boolean): ActionPayload<boolean> => ({ type: SET_USER_MENU_ACTIVE, payload });

export const toggleNotifications = (payload: boolean): ActionPayload<boolean> => ({
  type: TOGGLE_NOTIFICATIONS,
  payload,
});

export const getNotifications = (): Action => ({ type: GET_NOTIFICATIONS });

export const setNotifications = (payload: setNotificationsPayload): ActionPayload<setNotificationsPayload> => ({
  type: SET_NOTIFICATIONS,
  payload,
});
