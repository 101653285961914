import { useSelector } from 'react-redux';
import { selectUserRole } from '../store/modules/user';
import { USER_ROLES } from '../types';

type UserRoleHook = {
    userRole: USER_ROLES,
    isUserInRole(role: USER_ROLES | Array<USER_ROLES>): boolean
}

export const useUserRole = (): UserRoleHook => {
    const userRole = useSelector(selectUserRole);

    function isUserInRole(role: USER_ROLES | Array<USER_ROLES>) {
        if(Array.isArray(role)) {
            return role.includes(userRole);
        }
        return userRole === role;
    }


    return ({
        userRole,
        isUserInRole
    });
};