import { ActionPayload, ObjectLiteral, OrdersStore, SEARCH_TYPES, SORT_ORDER } from '../../../types';
import { OrderAuditStatsSuccessAction, OrderDto, OrderListSuccessDTO, OrderStatus } from '../../../types/dto';
import {
  GET_ORDERS_SUCCESS,
  GET_ORDER_AUDIT_DEVICES_PAGE_SUCCESS,
  GET_ORDER_AUDIT_STATS_SUCCESS,
  GET_ORDER_SUCCESS,
  OrderAuditDevicesPagePayloadSuccess,
  SET_ORDER_FILTER_STATUS,
  SET_ORDER_FILTER_YEAR,
  SET_ORDER_LIST_ORDER_SORT,
  SET_ORDER_LIST_SORTBY,
  SET_ORDER_SEARCH_ACTIVE,
  SET_ORDER_SEARCH_TYPE,
  SET_ORDER_SEARCH_VALUE
} from './orders.actions';

const initialState: OrdersStore = {
  list: [],
  perPage: 9,
  page: 1,
  lastPage: 1,
  count: 0,
  byId: {},
  sortBy: 'deliveryDate',
  sortOrder: SORT_ORDER.DESC,
  byYears: [],
  byStatuses: [],
  search: {
    isShown: false,
    type: SEARCH_TYPES.COMMAND_NUMBER,
    value: '',
  }
};

export const orderReducer = (
  storeState: OrdersStore | undefined,
  action: ActionPayload<Partial<OrdersStore>>
): OrdersStore => {
  const state = storeState || initialState;
  const { type, payload } = action;

  const actionHandler: ObjectLiteral<() => OrdersStore> = {
    [GET_ORDERS_SUCCESS]: () => ({ ...state, ...payload as Pick<OrderListSuccessDTO, 'data'> }),
    [GET_ORDER_SUCCESS]: () => ({
      ...state,
      byId: { ...state.byId, [(payload as OrderDto).id]: payload },
    }),
    [GET_ORDER_AUDIT_STATS_SUCCESS]: () => {
      const { orderId, stats } = payload as OrderAuditStatsSuccessAction;

      return ({
        ...state,
        byId: {
          ...state.byId,
          [orderId]: { ...state.byId[orderId],
            audit: {
              ...state.byId[orderId].audit,
              stats,
            }
          }
        },
      });
    },
    [GET_ORDER_AUDIT_DEVICES_PAGE_SUCCESS]: () => {
      const { orderId, devices } = payload as OrderAuditDevicesPagePayloadSuccess;

      return ({
        ...state,
        byId: {
          ...state.byId,
          [orderId]: { ...state.byId[orderId],
            audit: {
              ...state.byId[orderId].audit,
              devices,
            }
          }
        },
      });
    },
    [SET_ORDER_LIST_SORTBY]: () => ({ ...state, sortBy: payload as unknown as string }),
    [SET_ORDER_SEARCH_TYPE]: () => ({ ...state, search: { ...state.search, type: payload as unknown as SEARCH_TYPES } }),
    [SET_ORDER_SEARCH_VALUE]: () => ({ ...state, search: { ...state.search, value: payload as unknown as string }, }),
    [SET_ORDER_SEARCH_ACTIVE]: () => ({ ...state, search: { ...state.search, isShown: payload as unknown as boolean } }),
    [SET_ORDER_LIST_ORDER_SORT]: () => ({ ...state, sortOrder: payload as unknown as SORT_ORDER}),
    [SET_ORDER_FILTER_YEAR]: () => ({ ...state, byYears: payload as number[] }),
    [SET_ORDER_FILTER_STATUS]: () => ({ ...state, byStatuses: payload as unknown as OrderStatus[] })
  };

  if(!actionHandler[type]) {
    return state;
  }

  return actionHandler[type]();
};
