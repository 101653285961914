export const downloadFile = (base64: string, fileName: string) => {
  const a = document.createElement('a');

  a.href = `data:application/octet-stream;base64,${base64}`;
  a.download = fileName;
  a.style.display = 'none';

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
