import React, { FC, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from '.';
import { useLogout } from '../../../hooks';
import { BellIcon } from '../../../icons';
import { deleteNotifications, readNotifications } from '../../../services';
import {
  getNotifications,
  selectNotificationListState,
  selectNotificationsState,
  selectNotificationUnsreadState,
  setUserMenuActive,
  toggleNotifications
} from '../../../store/modules/app';
import { setSearchActive } from '../../../store/modules/orders';
import { FETCH_STATUS, NotificationDto } from '../../../types';
import { addBodyScroll, handleWrapperClick, hideBodyScroll } from '../../../utils';

export type Props = {
  notifications?: NotificationDto[];
}

export const Notifications:FC<Props> = memo(function Notifications({ notifications }) {
  const [isClosing, setIsClosing] = useState(false);
  const isOpen = useSelector(selectNotificationsState);
  const notificationList = notifications || useSelector(selectNotificationListState);
  const notificationUnread = useSelector(selectNotificationUnsreadState);
  const dispatch = useDispatch();
  const logOut = useLogout();

  const closeMenu = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(setSearchActive(false));
      setIsClosing(false);
      dispatch(toggleNotifications(false));
    }, 400);
  };

  const toggleUserMenu = () => {
    if (!isOpen) {
      dispatch(setSearchActive(false));
      dispatch(setUserMenuActive(false));
      hideBodyScroll();

      return dispatch(toggleNotifications(true));
    }

    addBodyScroll();
    closeMenu();
  };

  const onNotificationClick = (notification: NotificationDto) => {
    const { read, id } = notification;

    dispatch(toggleNotifications(false));
    addBodyScroll();

    if (read) {
      return;
    }

    readNotifications({ notifications: [id] })
      .then((res) => {
        if (res.result.status !== FETCH_STATUS.SUCCESS) {
          return logOut();
        }

        dispatch(getNotifications());
      });
  };

  const onNotificationDelete = (notification: NotificationDto) => {
    deleteNotifications({ notifications: [notification.id] }).then((response) => {
      if (response.result.status !== FETCH_STATUS.SUCCESS) {
        return;
      }

      dispatch(getNotifications());
    });
  };

  return (
    <div className="NotificationsComponent__Wrapper">
      <button
        className="NotificationsComponent__Button"
        onClick={toggleUserMenu}
      >
        <BellIcon />
        {!!notificationUnread && (
          <span className="Indicator">{notificationUnread}</span>
        )}
      </button>
      {isOpen && (
        <div
          className={`NotificationsComponent__FadeWrapper ${isClosing ? 'Closing' : ''}`}
          onClick={(e: any) => handleWrapperClick(e, 'NotificationsComponent__FadeWrapper', toggleUserMenu)}
        >
          <div className="NotificationsComponent__List">
            <div className="NotificationsComponent__List__Title">Notifications</div>
            {!!notificationList.length && notificationList.map((notification) => (
              <Notification
                key={notification.id}
                {...notification}
                onclick={onNotificationClick}
                onDelete={onNotificationDelete}
              />)
            )}
          </div>
        </div>
      )}
    </div>
  );
});
