export const isValidEmail = (email: string): boolean => {
  if(!email) {
    return false;
  }

  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*.(com|fr|net)$/;

  return re.test(String(email).toLowerCase());
};

export const isEmail = (email: string): boolean => {
  if(!email) {
    return false;
  }

  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return re.test(String(email).toLowerCase());
};

export default isValidEmail;
