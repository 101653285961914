import React, { FC } from 'react';

export const InfoWarnIcon:FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <g clipPath="url(#a)">
        <path
          fill="#002855"
          d="M8 10a.667.667 0 100 1.334A.667.667 0 008 10zm7.113.98L9.746 1.647a2 2 0 00-3.493 0L.92 10.98A2 2 0 002.626 14h10.747a2 2 0 001.74-3.02zm-1.153 1.333a.666.666 0 01-.587.34H2.626a.667.667 0 01-.586-.34.666.666 0 010-.666l5.333-9.333a.667.667 0 011.187 0l5.367 9.333a.668.668 0 01.033.68v-.014zM8 4.667a.667.667 0 00-.667.667V8a.667.667 0 001.333 0V5.334A.667.667 0 008 4.667z"
        ></path>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
