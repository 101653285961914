import React, { FC } from 'react';

type Props = {
  color?: string;
}

export const DownloadInfoIcon: FC<Props> = ({ color = '#64a70b'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14 10v2.667A1.334 1.334 0 0112.667 14H3.333A1.332 1.332 0 012 12.667V10m2.666-3.333L8 10l3.333-3.333M8 10V2"
      ></path>
    </svg>
  );
};
