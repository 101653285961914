export const parseNumber = (itemToParse: number ): number => {
  if (!parseFloat(`${itemToParse}`)) {
    return 0;
  }

  if (parseInt(`${itemToParse}`) === itemToParse) {
    return itemToParse;
  }

  return parseFloat(itemToParse.toFixed(2));
};
