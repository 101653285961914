import React, { FC } from 'react';

type Props = {
  color?: string;
}

export const OrderReceptionStatusIcon:FC<Props> = ({ color = '#fff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2 6l6-4.667L14 6v7.334a1.332 1.332 0 01-1.333 1.332H3.333A1.332 1.332 0 012 13.334V6z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 14.667V8h4v6.667"
      ></path>
    </svg>
  );
};
