import React from 'react';

export const ZipFileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        stroke="#012D4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M29.333 25.333A2.665 2.665 0 0126.666 28H5.334a2.666 2.666 0 01-2.666-2.667V6.667A2.665 2.665 0 015.334 4H12l2.666 4h12a2.666 2.666 0 012.667 2.667v14.666z"
      ></path>
      <rect
        width="21.6"
        height="11.2"
        x="11.2"
        y="14.4"
        fill="#EBF0FC"
        rx="3"
      ></rect>
      <path
        fill="#012D4E"
        d="M16.934 22.7v-.668l3.052-3.996v.28l-3.052-.016v-.696h3.792v.668l-2.92 3.828-.04-.112 2.96.016v.696h-3.792zm5.055 0v-5.096h.76V22.7h-.76zm2.3 0v-5.096h1.709c.568 0 1.005.142 1.312.424.306.283.46.674.46 1.172 0 .494-.154.881-.46 1.164-.307.28-.744.42-1.312.42h-.94V22.7h-.768zm.769-2.58h.94c.325 0 .572-.084.74-.252.168-.168.252-.39.252-.668 0-.28-.084-.506-.252-.68-.168-.173-.415-.26-.74-.26h-.94v1.86z"
      ></path>
    </svg>
  );
};
