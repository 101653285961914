import { Options } from 'export-to-csv';

export const exportToCsvConfig = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true, 
  showTitle: true,
  title: '',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: false,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

export const createExportToCsvConfig = (partialOptions: Options): Options => ({
  ...exportToCsvConfig,
  ...partialOptions,
});
