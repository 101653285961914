import { FETCH_STATUS } from '../FetchRequest.type';

export enum USER_TYPE {
  SUPPLIER = 'supplier',
}

export enum USER_ROLES {
  ADMIN = 'Admin',
  OPERATIONNEL = 'Opérationnel',
  COMPTABILITE = 'Comptabilité',
  LOGISTIQUE = 'Logistique',
  NONE = ''
}

export interface UserData {
  id: number;
  firstname: string;
  lastName: string;
  email: string;
  company: string;
  job: string;
  type: USER_TYPE;
  notifpreferences: boolean;
  support: UserContacts | Record<string, never> | null;
  role: USER_ROLES
}

export interface UserErrorDTO {
    status: FETCH_STATUS.ERROR;
    code: number;
    message: string;
}

 export interface UserSuccessDTO {
    status: FETCH_STATUS.SUCCESS;
    data: UserData;
}

export type UserDto = UserSuccessDTO | UserErrorDTO;

export interface UserContacts {
  phone: string;
  name: string;
  email: string;
}

export interface UserContactsSuccessDTO {
  status: FETCH_STATUS.SUCCESS;
  data: UserContacts;
}

export type UserContactsDTO = UserContactsSuccessDTO | UserErrorDTO;

// Guards

export const isUserSuccess = (userResult: UserDto): userResult is UserSuccessDTO => {
  return !!(userResult as UserSuccessDTO).data;
};
