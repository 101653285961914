import React, { FC } from 'react';

type Props = {
  size?: number;
  color?: string;
}

export const SettiongsIcon:FC<Props> = ({ size = 24, color = '#0D142D'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 15a3 3 0 100-6 3 3 0 000 6z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19.4 15a1.648 1.648 0 00.33 1.82l.06.06a2.005 2.005 0 01.434 2.181 1.989 1.989 0 01-1.084 1.083 2.005 2.005 0 01-1.531 0 1.989 1.989 0 01-.649-.434l-.06-.06a1.656 1.656 0 00-1.82-.33 1.654 1.654 0 00-1 1.51V21a2 2 0 01-4 0v-.09A1.654 1.654 0 009 19.4a1.648 1.648 0 00-1.82.33l-.06.06a2.005 2.005 0 01-2.181.434 1.989 1.989 0 01-1.083-1.084 2.005 2.005 0 010-1.531c.1-.243.248-.463.434-.649l.06-.06a1.656 1.656 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3c-.53 0-1.04-.21-1.414-.586A1.995 1.995 0 011 12.08a2 2 0 012-2h.09A1.654 1.654 0 004.6 9a1.648 1.648 0 00-.33-1.82l-.06-.06a1.998 1.998 0 01-.434-2.181A1.989 1.989 0 014.86 3.856a2.005 2.005 0 011.531 0c.243.1.463.248.649.434l.06.06a1.656 1.656 0 001.82.33H9a1.65 1.65 0 001-1.51V3c0-.53.21-1.04.586-1.414A1.995 1.995 0 0112 1c.53 0 1.04.21 1.414.586C13.79 1.96 14 2.47 14 3v.09a1.65 1.65 0 001 1.51 1.648 1.648 0 001.82-.33l.06-.06a1.998 1.998 0 012.181-.434c.243.1.463.248.649.434s.334.406.434.65a2.005 2.005 0 010 1.531c-.1.243-.248.463-.434.649l-.06.06a1.656 1.656 0 00-.33 1.82V9a1.654 1.654 0 001.51 1H21a2 2 0 010 4h-.09a1.65 1.65 0 00-1.51 1v0z"
      ></path>
    </svg>
  );
};
