import React, { FC, memo } from 'react';
import { CertificationIcon } from '../../../icons';

const certificateList = [
  'ISO 14001 v 2015' ,
  'MAR Microsoft Authorized Refurbisher' ,
  'Blancco Gold Partner' ,
  'Médaille d\'argent ECOVADIS' ,
  'Adhérent éco-organisme ECOLOGIC' ,
];

export const UserProfileFooter:FC = memo(function UserProfileFooter () {
  return (
    <div className="UserMenuComponent__Footer">
      <span className="UserMenuComponent__Footer__LinkTitle">
        ATF est un site du groupe ATF
      </span>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.groupe-atf.com"
        className="UserMenuComponent__Footer__Link"
      >
        www.groupe-atf.com
      </a>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.atf-gaia.fr/fr/nos-certifications"
        className="UserMenuComponent__Footer__Certificates__Title"
      > Nos certifications</a>
      { certificateList.map((text) => (
        <span
          key={text}
          className="UserMenuComponent__Footer__Certificates__Item"
        >
          <CertificationIcon/>
          <span>{text}</span>
        </span>
      )) }
  </div>
  );
});
