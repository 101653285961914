import React, { FC, memo } from 'react';
import {
  CsvFileIcon,
  DownloadFileIcon,
  LoadingFileIcon,
  PdfFileIcon,
  ZipFileIcon
} from '../../icons';

export enum DOCUMENT_TYPE {
  PDF = 'PDF',
  CSV = 'CSV',
  XML = 'XML',
  ZIP = 'ZIP',
}

export type FileLinkProps = {
  name: string | JSX.Element;
  handleClick: () => void;
  type?: DOCUMENT_TYPE;
  link?: string;
  notAvailableText?: string;
}

const fileTypeHandler = {
  [DOCUMENT_TYPE.CSV]: <CsvFileIcon />,
  [DOCUMENT_TYPE.XML]: <CsvFileIcon />,
  [DOCUMENT_TYPE.PDF]: <PdfFileIcon />,
  [DOCUMENT_TYPE.ZIP]: <ZipFileIcon />,
};

export const FileLink:FC<FileLinkProps> = memo(function FileLink({
  name,
  link,
  type,
  handleClick,
  notAvailableText = 'Document non disponible',
}) {
  const isActive = link && type;
  let fileIcon = <LoadingFileIcon />;

  if(isActive && fileTypeHandler[type as DOCUMENT_TYPE]) {
    fileIcon = fileTypeHandler[type as DOCUMENT_TYPE];
  }

  const onClick = () => {
    if(isActive) {
      handleClick();
    }
  };

  return (
    <div className={`FileLinkComponent ${!link ? 'Inactive' : ''}`} onClick={onClick}>
      <div className="FileLinkComponent__Icon">{fileIcon}</div>
      <div className="FileLinkComponent__Data">
        <span className="FileLinkComponent__Title">{name}</span>
        {isActive && <span className="FileLinkComponent__Date"><DownloadFileIcon /></span>}
        {!isActive && <span className="FileLinkComponent__Indisponible">{notAvailableText}</span>}
      </div>
    </div>
  );
});
