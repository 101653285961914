import { SET_NOTIFICATIONS, SET_USER_MENU_ACTIVE } from '.';
import { NotificationDto, ObjectLiteral, SnackbarState, SNACKBAR_TYPE } from '../../../types';
import { SET_ERROR, CLEAR_ERROR, START_LOAD, STOP_LOAD, SET_SNACKBAR, CLEAR_LOAD, TOGGLE_NOTIFICATIONS } from './app.actions';

export type AppState = {
  error: string;
  loading: ObjectLiteral<number>;
  snackBar: SnackbarState;
  userMenu: boolean;
  headerSearch: boolean;
  notifications: {
    list: NotificationDto[];
    isOpen: boolean;
    unread: number;
  };
}

const initialState: AppState = {
  error: '',
  loading: {},
  userMenu: false,
  headerSearch: false,
  notifications: {
    isOpen: false,
    list: [],
    unread: 0,
  },
  snackBar: {
    isShown: false,
    text: '',
    type: SNACKBAR_TYPE.SUCCESS,
  }
};

export const appReduder = (
  storeState: AppState | undefined,
  action: ObjectLiteral
):AppState => {
  const state = storeState || initialState;
  const { type , payload } = action;

  const actionHandler: ObjectLiteral<() => AppState> = {
    [SET_ERROR]: () => ({ ...state, error: payload as string }),
    [CLEAR_ERROR]: () => ({ ...state, error: '' }),
    [START_LOAD]: () => {
      const { loading } = state;
      const currentCount = +!!loading[payload as string];

      return ({...state, loading: { ...state.loading, [payload as string]: currentCount + 1 } });
    },
    [STOP_LOAD]: () => {
      const { loading } = state;
      const newCount = loading[payload as string] ? loading[payload as string] - 1 : 0;

      return ({ ...state, loading: { ...loading, [payload as string]: newCount, } });
    },
    [SET_SNACKBAR]: () => ({ ...state, snackBar: { ...state.snackBar, ...payload as Partial<SnackbarState> } }),
    [CLEAR_LOAD]: () => ({ ...state, loading: {} }),
    [SET_USER_MENU_ACTIVE]: () => ({ ...state, userMenu: payload as boolean }),
    [TOGGLE_NOTIFICATIONS]: () => ({ ...state, notifications: { ...state.notifications, isOpen: payload as boolean }}),
    [SET_NOTIFICATIONS]: () => {
      const { data: list, unread } = payload as { data: NotificationDto[], unread: number };

      return ({ ...state, notifications: { ...state.notifications, list, unread }});
    }
  };

  if(!actionHandler[type as keyof ObjectLiteral]) {
    return state;
  }

  return actionHandler[type as keyof ObjectLiteral]();
};
