import React, { FC, useContext, memo } from 'react';
import { useHistory } from 'react-router';
import { confirmModalContext } from '../../../context';
import { useUserRole } from '../../../hooks';
import { PATHS } from '../../../router';
import { USER_ROLES } from '../../../types';


const getLinksByRole = (role: USER_ROLES) => {

  switch (role) {
    case USER_ROLES.LOGISTIQUE:
      return ({
        [PATHS.COMMANDS]: 'Mes commandes ',
      });
    case USER_ROLES.COMPTABILITE:
      return ({
        [PATHS.FINANCIAL_DASHBOARD]: 'Mon suivi financier',
      });
    case USER_ROLES.OPERATIONNEL:
      return ({
        [PATHS.COMMANDS]: 'Mes commandes ',
        [PATHS.CSR_PAGE]: 'Mon dashboard RSE',
      });
    case USER_ROLES.ADMIN:
      return ({
        [PATHS.COMMANDS]: 'Mes commandes ',
        [PATHS.FINANCIAL_DASHBOARD]: 'Mon suivi financier',
        [PATHS.CSR_PAGE]: 'Mon dashboard RSE',
      });
    default:
      return ({});
  }
};

export const HeaderLinks: FC = memo(function HeaderLinks() {
  const { location: { pathname } } = useHistory();
  const { navigateOnPath } = useContext(confirmModalContext);
  const { userRole } = useUserRole();

  return (
    <div className="HeaderComponent__Links">
      {Object.entries(getLinksByRole(userRole)).map(([link, name]) => {
        const isActive = pathname.includes(link);

        return (
          <button
            key={link}
            className={`Link ${isActive ? 'Active' : ''}`}
            onClick={() => navigateOnPath(link)}
          >
            {name}
          </button>
        );
      })}
    </div>
  );
});
