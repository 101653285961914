import React, { FC, useContext, useMemo, memo } from 'react';
import { confirmModalContext } from '../../../context';
import { useLogout } from '../../../hooks';
import { ChangePasswordIcon, LogOutIcon, MessagesIcon, SettiongsIcon } from '../../../icons';
import { PATHS } from '../../../router';

export const UserProfileMenuLinks:FC = memo(function UserProfileMenuLinks() {
  const { navigateOnPath } = useContext(confirmModalContext);
  const logout = useLogout();

  const menuButtons = useMemo(() => [
    {
      text: 'Mes informations',
      icon: <MessagesIcon />,
      onClick: () => navigateOnPath(PATHS.USER_INFO),
    },
    {
      text: 'Éditer mon mot de passe',
      icon: <ChangePasswordIcon/>,
      onClick: () => navigateOnPath(PATHS.CHANGE_PASSWORD),
    },
    {
      text: 'Préférences',
      icon: <SettiongsIcon />,
      onClick: () => navigateOnPath(PATHS.USER_PREFERENCES),
    },
    {
      text: 'Déconnexion',
      icon: <LogOutIcon />,
      onClick: () => logout(),
    }
  ], []);

  return (
    <ul className="UserMenuComponent__Actions">
    {menuButtons.map(({ text, icon, onClick }) => (
      <li key={text} className="UserMenuComponent__Action">
      <button className="UserMenuComponent__Button" onClick={onClick}>
        <span className="UserMenuComponent__Button__Icon">{icon}</span>
        <span className="UserMenuComponent__Button__Text">{text}</span>
      </button>
    </li>
    ))}
  </ul>
  );
});
