import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import {
  checkIfActualAccounts,
  getAccounts,
  getStorageSelectedAccounts,
  getUser,
  setStorageSelectedAccounts,
  userLogin,
  userLogout,
} from '../../../services';
import { ActionPayload, LOAD_ENDPOINTS, UserLogin } from '../../../types';
import { isLoginSuccess, isUserSuccess, LoginDTO, UserDto } from '../../../types/dto';
import { GroupeStructure, parseUserContact, parseAccounts } from '../../../utils';
import { startLoad, stopLoad } from '../app';
import {
  USER_LOG_IN,
  USER_LOGOUT,
  GET_USER,
  GET_ACCOUNTS,
  userSuccessLogin,
  userLoginError,
  handleUserLogout,
  handleUserGet,
  handleGetAccounts,
  changeSelectedAccounts
} from './user.actions';

export function* workerLogin (loginAction: ActionPayload<UserLogin>):Iterator<any> {
  const { payload } = loginAction;
  yield put(startLoad(LOAD_ENDPOINTS.USER_LOGIN));

  const userLoginResult = (yield call(userLogin, payload)) as unknown as LoginDTO;

  if (isLoginSuccess(userLoginResult)) {
    yield put(userSuccessLogin(userLoginResult.result.firstTime));
  } else {
    yield put(userLoginError());
  }

  yield put(stopLoad(LOAD_ENDPOINTS.USER_LOGIN));
}

export function* workerLogout(): Iterator<any> {
  try {
    yield put(handleUserLogout());
    yield call(userLogout);

    window.location.reload();
  } catch(e) {
    console.warn(e);
  }
}

export function* workerGetUserData({ payload }: ActionPayload<number[]>): Iterator<any> {
  try {
    yield put(startLoad(LOAD_ENDPOINTS.USER_ME));

    const userData = (yield call(getUser, { selectedAccounts: payload })) as unknown as UserDto;

    if (isUserSuccess(userData)) {
      const { data: user } = userData;
      user.support = parseUserContact(user.support);

      yield put(handleUserGet(userData.data));
    } else {
      yield workerLogout();
    }

    yield put(stopLoad(LOAD_ENDPOINTS.USER_ME));
  } catch(e) {
    console.error(e);
  }
}

export function* workerGetAccounts():Iterator<any> {
  try {
    yield put(startLoad(LOAD_ENDPOINTS.GET_ACCOUNTS));

    const accountsDate = (yield call(getAccounts)) as unknown as Record<string, any>;
    const parsedAccounts = parseAccounts(accountsDate.groupes || [] as unknown as GroupeStructure[]);
    const fetchedAccountIds = parsedAccounts.map(({ id }) => id);

    let selectedAccounts = getStorageSelectedAccounts();

    if (!checkIfActualAccounts(fetchedAccountIds)) {
      selectedAccounts = (parsedAccounts[0]?.id
        ? [parsedAccounts[0]?.id]
        : []
      ) as number[];
    }

    setStorageSelectedAccounts(selectedAccounts);

    yield put(handleGetAccounts(parsedAccounts));
    yield put(changeSelectedAccounts(selectedAccounts));
    yield put(stopLoad(LOAD_ENDPOINTS.GET_ACCOUNTS));
  } catch (e) {
    console.error(e);
  }
}

export function* watchLogin(): Iterator<ForkEffect> {
  try {
    yield takeEvery(USER_LOG_IN, workerLogin);
  } catch (e) {
    console.error(e);
  }
}

export function* watchLogout(): Iterator<ForkEffect> {
  try {
    yield takeEvery(USER_LOGOUT, workerLogout);
  } catch (e) {
    console.error(e);
  }
}

export function* watchGetUser(): Iterator<ForkEffect> {
  try {
    yield takeEvery(GET_USER, workerGetUserData);
  } catch (e) {
    console.error(e);
  }
}

export function* watchGetAccounts(): Iterator<ForkEffect> {
  try {
    yield takeEvery(GET_ACCOUNTS, workerGetAccounts);
  } catch (e) {
    console.error(e);
  }
}
