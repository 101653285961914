import { envConfig } from '../config';
import {
  GetOrderDevicesPagePayload,
  GetOrderListPayload,
  UserLogin,
  ObjectLiteral,
  ValidateNewUserPayload,
  SearchAuditDevicePayload,
  OrderCreateFilePayload,
  OrderCreatePayload,
  SearchOrderPayload,
  CSRDataPayload,
  CSRDetailsPayload,
  FinancialStatisticsPayload,
  ORDER_DOCUMENT_TYPE,
  FilteredOrdersPaginationPayload,
  GetNotificationPayload,
  OrdersErrorsPayload,
} from '../types';
import {
  CreatedOrderDto,
  OrderAuditDto,
  OrderAuditStats,
  OrderDocumentsStatusDto,
  OrderListSuccessDTO,
  UserData,
  UserDto,
  OrderSearchDto,
  FinantialStatisticsDTO,
  FilteredOrdersSuccessDTO,
  NotificationListSuccessDto,
  OrdersErrorsDTO,
} from '../types/dto';
import { LogoutDTO } from '../types/dto/Login.dto';
import { CSRDetailsResponse } from '../types/dto/Statistics.dto';
import { FETCH_STATUS, SuccessFetch } from '../types/FetchRequest.type';

type Fetch<T = ObjectLiteral, K = unknown> = (url: string, fetchConfig?: T) => Promise<K>;

export type IdWithAccounts = { id: number; selectedAccounts: number[] };

export const apiRoutes = {
  LOGIN: `${envConfig.apiUrl}/Users/login`,
  LOGOUT: `${envConfig.apiUrl}/Users/logout`,
  ME: `${envConfig.apiUrl}/Users/me`,
  VALIDATE_USER: `${envConfig.apiUrl}/users/create`,
  FORGOT_PASSWORD: `${envConfig.apiUrl}/Users/Forgot_password`,
  CHANGE_FORGOT_PASSWORD: `${envConfig.apiUrl}/Users/Validation_account`,
  USER_ACCOUNTS: `${envConfig.apiUrl}/Users/Account`,
  USER_EDIT: `${envConfig.apiUrl}/Users/user_edit`,
  ORDER_LIST: `${envConfig.apiUrl}/Orders/Orders`,
  ORDER: `${envConfig.apiUrl}/Orders/Order`,
  ORDER_DOCUMENTS_STATE: `${envConfig.apiUrl}/Orders/Order_Document_Availability`,
  ORDER_FETCH_DOCUMENT: `${envConfig.apiUrl}/Orders/Order_Document_Type`,
  ORDER_AUDIT_LIST: `${envConfig.apiUrl}/Orders/Order_Audit`,
  ORDER_AUDIT_STATS: `${envConfig.apiUrl}/Orders/Order_Audit_Stats`,
  ORDER_FETCH_DEVICE_DOCUMENT: `${envConfig.apiUrl}/Orders/Arrival`,
  ORDER_AUDIT_DEVICE_SEARCH: `${envConfig.apiUrl}/Orders/Audit_search`,
  CREATE_ORDER_DEVICES_TEMPLATE: `${envConfig.apiUrl}/Orders/Template`,
  GET_GDPR_TEMPLATE: `${envConfig.apiUrl}/Statistiques/Legals`,
  ORDER_SEARCH: `${envConfig.apiUrl}/Orders/search`,
  ORDER_CREATE: `${envConfig.apiUrl}/Orders/create`,
  UPLOAD_ORDER_FILE: `${envConfig.apiUrl}/Orders/Upload_order`,
  FINANCIAL_STATISTICS: `${envConfig.apiUrl}/Statistiques/Financial`,
  CSR_EXPORT: `${envConfig.apiUrl}/Statistiques/RSE_export`,
  CSR_STATISTICS: `${envConfig.apiUrl}/Statistiques/RSE`,
  CSR_DETAILS: `${envConfig.apiUrl}/Statistiques/RSE_detail`,
  CHANGE_PASSWORD: `${envConfig.apiUrl}/Users/update_password`,
  ORDER_OTHER_DOCUMENTS: `${envConfig.apiUrl}/Orders/documents`,
  ORDER_GET_OTHER_DOCUMENT: `${envConfig.apiUrl}/Orders/document`,
  DOETH_CREATE: `${envConfig.apiUrl}/Statistiques/DOETH`,
  DOETH_DOWNLOAD: `${envConfig.apiUrl}/Statistiques/DOETHFile`,
  CREATE_PASSWORD: `${envConfig.apiUrl}/Users/Create_password`,
  ERROR_REPORT: `${envConfig.apiUrl}/Statistiques/Mail_error`,
  ORDER_FILTER: `${envConfig.apiUrl}/Orders/Orders_filter`,
  UPDATE_USER_PREFERENCES: `${envConfig.apiUrl}/Users/mailNotifications`,
  NOTIFICATIONS_LIST: `${envConfig.apiUrl}/Notification/list`,
  NOTIFICATIONS_READ: `${envConfig.apiUrl}/Notification/read`,
  NOTIFICATIONS_DELETE: `${envConfig.apiUrl}/Notification/delete`,
  GET_SUPLIER_CLIENTS: `${envConfig.apiUrl}/Statistiques/DOETHAccount`,
  CHECK_ORDERS_ERROR: `${envConfig.apiUrl}/Orders/Order_error`,
};

const postFetchConfig = {
  method: 'POST',
  credentials: 'include',
};

const basicFetch: Fetch = (url, fetchConfig) =>
  fetch(url, fetchConfig || {})
    .then((res) => res.json())
    .then(({ result }) => result);

const post: (url: string, body: any) => Promise<any> = (url, body) =>
  basicFetch(url, {
    method: 'POST',
    body: JSON.stringify(Object.values(body)),
  });

const postWithAuth: (url: string, body?: any, contentType?: string) => Promise<any> = (url, body, contentType) => {
  const requestConfig: any = {
    ...postFetchConfig,
  };

  if (contentType) {
    requestConfig.headers['Content-Type'] = contentType;
  }

  if (body) {
    requestConfig.body = JSON.stringify(Object.values(body));
  }

  return basicFetch(url, requestConfig);
};

// USER ENDPOINTS

export const userLogin: (body: UserLogin) => Promise<any> = ({ email, password }) =>
  fetch(apiRoutes.LOGIN, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify([email, password]),
  }).then((response) => response.json());

export const userLogout: () => Promise<LogoutDTO> = () => postWithAuth(apiRoutes.LOGOUT, {});

export const getUser: (getUserPayload: { selectedAccounts: number[] }) => Promise<UserDto> = (getUserPayload) =>
  postWithAuth(apiRoutes.ME, getUserPayload);

export const editUser = (data: Partial<UserData>) => postWithAuth(apiRoutes.USER_EDIT, data);

export const validateNewUser = (validationData: ValidateNewUserPayload): Promise<any> =>
  post(apiRoutes.VALIDATE_USER, Object.values(validationData));

export const forgotPassword = (emailTocheck: { email: string }) => post(apiRoutes.FORGOT_PASSWORD, emailTocheck);

export const forgotChangePassword = (parswordChangePayload: { email: string; password: string; token: string }) =>
  post(apiRoutes.CHANGE_FORGOT_PASSWORD, parswordChangePayload);

export const getAccounts = () =>
  postWithAuth(apiRoutes.USER_ACCOUNTS).then(({ groupes }: { status: string; groupes: any }) => ({ groupes }));

export const changePassword = (payload: { newPassword: string; oldPassword: string }) =>
  postWithAuth(apiRoutes.CHANGE_PASSWORD, payload);

export const createPassword = (payload: { email: string }) => postWithAuth(apiRoutes.CREATE_PASSWORD, payload);

export const updateUserPreferences = (payload: { shouldSendNotifications: boolean }) =>
  postWithAuth(apiRoutes.UPDATE_USER_PREFERENCES, payload);

// ORDER ENDPOINTS

export const getOrders: (getOrderConfig: GetOrderListPayload) => Promise<OrderListSuccessDTO> = (paginationConfig) =>
  postWithAuth(apiRoutes.ORDER_LIST, paginationConfig);

export const getOrderById: (params: IdWithAccounts) => Promise<SuccessFetch<any>> = ({ id, selectedAccounts }) =>
  postWithAuth(apiRoutes.ORDER, { id, selectedAccounts });

export const getOrderAuditStats: (params: IdWithAccounts) => Promise<OrderAuditStats> = ({ id, selectedAccounts }) =>
  postWithAuth(apiRoutes.ORDER_AUDIT_STATS, { id, selectedAccounts });

export const getOrderAuditDevices: (auditConfig: GetOrderDevicesPagePayload) => Promise<OrderAuditDto> = (
  auditConfig
) => postWithAuth(apiRoutes.ORDER_AUDIT_LIST, auditConfig);

export const getOrderDocuments: (params: IdWithAccounts) => Promise<OrderDocumentsStatusDto> = ({
  id,
  selectedAccounts,
}) => postWithAuth(apiRoutes.ORDER_DOCUMENTS_STATE, { id, selectedAccounts });

export const fetchDocument: (link: string, payload: ObjectLiteral) => Promise<string> = (link, payload) =>
  postWithAuth(link, payload).then(({ data }: { data: string; status: string }) => data);

export const searchAuditDevice = (searchAuditPayload: SearchAuditDevicePayload): Promise<any> =>
  postWithAuth(apiRoutes.ORDER_AUDIT_DEVICE_SEARCH, searchAuditPayload);

export const fetchDevicesTemplate = (payload: {
  selectedAccounts: number[];
  type: ORDER_DOCUMENT_TYPE;
}): Promise<any> => postWithAuth(apiRoutes.CREATE_ORDER_DEVICES_TEMPLATE, payload);

export const searchOrder = (payload: SearchOrderPayload): Promise<OrderSearchDto> =>
  postWithAuth(apiRoutes.ORDER_SEARCH, payload);

export const createOrder = (orderData: { parsedOrder: OrderCreatePayload }): Promise<CreatedOrderDto> =>
  postWithAuth(apiRoutes.ORDER_CREATE, orderData);

export const uploadOrderFile = (payload: OrderCreateFilePayload): Promise<{ status: FETCH_STATUS.SUCCESS }> =>
  postWithAuth(apiRoutes.UPLOAD_ORDER_FILE, payload);

export const getOtherOrderDocumentState = (payload: { id: number; accounts: number[] }) =>
  postWithAuth(apiRoutes.ORDER_OTHER_DOCUMENTS, payload);

export const getOtherOrderDocument = (payload: { id: number; name: string; type: string }) =>
  postWithAuth(apiRoutes.ORDER_GET_OTHER_DOCUMENT, payload);

export const getFilteredOrders = (payload: FilteredOrdersPaginationPayload): Promise<FilteredOrdersSuccessDTO> =>
  postWithAuth(apiRoutes.ORDER_FILTER, payload);

export const getOrdersErrors = (payload: OrdersErrorsPayload): Promise<OrdersErrorsDTO> =>
  postWithAuth(apiRoutes.CHECK_ORDERS_ERROR, payload);

// FNANCIAL STATISTICS ENDPOINTS

export const getFinancialStatistics = (data: FinancialStatisticsPayload): Promise<FinantialStatisticsDTO> =>
  postWithAuth(apiRoutes.FINANCIAL_STATISTICS, data);

export const createDOETH = (payload: { year: number; supplier: number; client: number }) =>
  postWithAuth(apiRoutes.DOETH_CREATE, payload);

export const downloadDOETH = (payload: { year: number; supplier: number; client: number }) =>
  postWithAuth(apiRoutes.DOETH_DOWNLOAD, payload);

export const getSupplierClient = (payload: { suppliers: number[] }) =>
  postWithAuth(apiRoutes.GET_SUPLIER_CLIENTS, payload);

// CSR ENDPOINTS

export const exportCSR = (payload: CSRDataPayload) => postWithAuth(apiRoutes.CSR_EXPORT, payload);

export const getCSRStats = (payload: CSRDataPayload) => postWithAuth(apiRoutes.CSR_STATISTICS, payload);

export const getCSRDetails = (payload: CSRDetailsPayload): Promise<CSRDetailsResponse> =>
  postWithAuth(apiRoutes.CSR_DETAILS, payload);

// ERROR REPORT

export const reportError = (payload: Record<string, string>) => postWithAuth(apiRoutes.ERROR_REPORT, { payload });

// NOTIFICATION ENPOINTS

export const getNotificationsList = (payload: GetNotificationPayload): Promise<NotificationListSuccessDto> =>
  postWithAuth(apiRoutes.NOTIFICATIONS_LIST, payload);

export const readNotifications = (payload: {
  notifications: number[];
}): Promise<{ result: { status: FETCH_STATUS } }> => postWithAuth(apiRoutes.NOTIFICATIONS_READ, payload);

export const deleteNotifications = (payload: {
  notifications: number[];
}): Promise<{ result: { status: FETCH_STATUS } }> => postWithAuth(apiRoutes.NOTIFICATIONS_DELETE, payload);
