import React, { FC, useContext, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Dropdown, InputType } from '../../';
import { orderWizardContext, STEPS } from '../../../context';
import { useLogout } from '../../../hooks';
import { DownloadInfoIcon, LoadingFileIcon } from '../../../icons';
import { fetchDevicesTemplate } from '../../../services';
import { selectUserAccounts } from '../../../store/modules/user';
import { ORDER_DOCUMENT_TYPE } from '../../../types';
import { downloadFile } from '../../../utils';
import { CheckBox } from '../../Checkbox';
import { Loader } from '../../Loader';

export const FourthStep:FC = memo(function FourthStep () {
  const { order, setProperty } = useContext(orderWizardContext);
  const {
    [STEPS.STEP4]: {
      notPackaged,
      withPalet,
      paletNumber,
      hasTag,
      desiredErase,
      otherErase,
      comment,
    }
  } = order;
  const [loading, setLoading] = useState(false);
  const userAccounts = useSelector(selectUserAccounts);
  const logout = useLogout();

  const changeField = (field: string, value: boolean | number | string) => {
    setProperty(STEPS.STEP4, field, value as string);
  };

  const downloadTemplate = () => {
    setLoading(true);

    return fetchDevicesTemplate({ selectedAccounts: userAccounts, type: ORDER_DOCUMENT_TYPE.GUIDE })
    .then(({ data, metadata }) => {
      if(!data) {
        return logout();
      }

      downloadFile(data, metadata.name);
      setLoading(false);
    });
  };

  return (
    <div className="FourthStepComponent">
      {loading && <Loader />}
      <h3 className="FourthStepComponent__Title">Prestation</h3>
      <span className="FourthStepComponent__SubTitle">Vos besoins font notre performance !</span>

      <h6 className="FourthStepComponent__BlockTitle">Conditionnement</h6>
      <div className="FourthStepComponent__Row">
        <CheckBox
          checked={notPackaged}
          id="notPackaged"
          label="Le matériel n’est pas conditionné"
          onChange={(checked) => changeField('notPackaged', checked )}
        />
      </div>
      <div className="FourthStepComponent__Row">
        <CheckBox
          checked={withPalet}
          id="withPalet"
          label="Le matériel est palettisé"
          onChange={(checked) => changeField('withPalet', checked)}
        />
        {withPalet && (
          <div className="FourthStepComponent__Counter">
            <button onClick={() => paletNumber && changeField('paletNumber', paletNumber - 1)}>-</button>
            <span>{paletNumber}</span>
            <button onClick={() => changeField('paletNumber', paletNumber + 1)}>+</button>
          </div>
        )}
      </div>
      {withPalet && (
        <div className="FourthStepComponent__Row WithDocument">
          <button className="Document" onClick={() => downloadTemplate()}>
            <LoadingFileIcon size={16} color='#002855' strokeWidth={3} />
            Le matériel est palettisé
            <DownloadInfoIcon />
          </button>
        </div>
      )}
      <div className="FourthStepComponent__Row">
        <CheckBox
          checked={hasTag}
          id="hasTag"
          label="Je souhaite un relevé code parc (TAG)"
          onChange={(checked) => changeField('hasTag', checked)}
        />
      </div>

      <h6 className="FourthStepComponent__BlockTitle">Service</h6>
      <div className="FourthStepComponent__Content">
        <div className="FourthStepComponent__Column">
          <Dropdown
            label="Type d’effacement souhaité"
            value={desiredErase}
            items={[
              { id: 'Aucun', name: 'Aucun' },
              { id: 'Effacement 1 PASS Infosec', name: 'Effacement 1 PASS Infosec' },
              { id: 'Effacement 3 PASS DOD 5220', name: 'Effacement 3 PASS DOD 5220' },
              { id: 'Logiciel Client', name: 'Logiciel Client' },
            ]}
            onChange={(newEriseType) => changeField('desiredErase', newEriseType)}
          />
        </div>
        {desiredErase === 'Aucun' && (
          <div className="FourthStepComponent__Column">
            <Input
              id="otherErase"
              label="Précisez le type d’effacement"
              className="FourthStepComponent__OtherErase"
              value={otherErase}
              onChange={(newOtherErase) => changeField('otherErase', newOtherErase)}
            />
          </div>
        )}
      </div>
      <h6 className="FourthStepComponent__BlockTitle">Conditionnement</h6>
      <Input
        id="comment"
        className="FourthStepComponent__Textarea"
        label="Souhaitez-vous nous faire par d’un autre besoin particulier concernant le traitement de vos équipements"
        type={InputType.TEXTAREA}
        value={comment}
        onChange={(newComment) => changeField('comment', newComment)}
      />
    </div>
  );
});
