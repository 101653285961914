import { ObjectLiteral } from './ObjectLiteral.type';

export enum LOAD_ENDPOINTS {
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGOUT = 'USER_LOGOUT',
  USER_ME = 'USER_ME',
  GET_ORDERS = 'GET_ORDERS',
  GET_ORDER_BY_ID = 'GET_ORDER_BY_ID',
  GET_ORDER_AUDIT_DEVICES = 'GET_ORDER_AUDIT_DEVICES',
  GET_ORDER_AUDIT_STATS = 'GET_ORDER_AUDIT_STATS',
  GET_ACCOUNTS = 'GET_ACCOUNTS',
  CSR_REPORT = 'CSR_REPORT',
  CSR_DETAILS = 'CSR_DETAILS'
}

export enum FETCH_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
}

export type SuccessFetch<T> = {
  status: FETCH_STATUS.SUCCESS;
  data: T;
}

export interface ExpiredTokenResponse {
  Message: 'Unauthorized';
  code: 10012;
  Constant: 'R10012_Token_Expired';
}

export type SuccessFetchList<T> = {
  status: FETCH_STATUS.SUCCESS;
  data: {
    list: T[];
    page: number;
    perPage: number;
    lastPage: number;
    count: number;
  };
}

// Guards

export const isExpiredTokenResponse = (response: ObjectLiteral<any>): response is ExpiredTokenResponse => {
  return (
    response?.Message === 'Unauthorized'
    && response?.Constant === 'R10012_Token_Expired'
  );
};

export const isUnexpectedTokenResponse = (response: ObjectLiteral<any>): response is ExpiredTokenResponse => {
  return (
    response.status === FETCH_STATUS.ERROR
    && response.message === 'Unexpected'
  );
};
