import React, { FC } from 'react';

export const EditIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.333 2.667H2.667A1.333 1.333 0 001.334 4v9.333a1.333 1.333 0 001.333 1.333H12a1.333 1.333 0 001.333-1.333V8.666"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.333 1.667a1.411 1.411 0 012 0 1.416 1.416 0 010 2L8 10l-2.667.667L6 8l6.333-6.333z"
      ></path>
    </svg>
  );
};
